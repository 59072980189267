@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.storeContainer {
  background: var(--beige);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px 80px;
  position: relative;
  overflow: hidden;
  
  @media (--viewportSmall) {
    width: 100vw;
    display: inline-block;
    padding: 40px 15px;
  }
}

.sectionTitle {
  @apply --titleStyle1;
  text-align: center;
  margin: 20px auto 15px;
  max-width: 1200px;
}

.sectionSubTitle {
  @apply --bodyStyle1;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 1200px;
}

.commentContainer {
  display: inline-block;

  @media (--viewportSmall) {
    vertical-align: middle;
  }
}

.brandNodes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (--viewportSmall) {
    flex-wrap: wrap;
  }
}

.brandItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    width: calc(100vw/2.4);
  }
}

.brandImage {
  max-width: 300px;
  max-height: 300px;
  border: 1px solid var(--black);
  border-radius: 150px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 20px;

  @media (--viewportSmall) {
    width: calc(100vw/2.7);
  }
}

.brandTitle {
  @apply --titleStyle7;
  margin: 10px auto 0;
  text-align: center;
  max-width: 250px;
  height: 40px;

  @media (--viewportSmall) {
    margin-bottom: 15px;
  }
}

/*
.brandNameLink {
  color: var(--black);
}*/

.buttonContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  justify-content: center;
}

.leftButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 30px;
}

.leftButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.rightButton { 
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
}

.rightButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}