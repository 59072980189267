.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 220pt;
}

.formField{
  margin-top: 10pt;
  display: block;
  text-align: left;
  width: 220pt;
}

.formField label{
  color: #000000;
  font-family: Lato;
  font-size: 9pt;
  letter-spacing: 0;
}

.requiredMarker{
  color: #FF0000;
}

.inputLabelMargin {
  margin: 0;
}
