@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Topbar message section */

.topbarMessageContainer {
  width: 100%;
  text-align: center;
  background-color: var(--beige);
  padding: 15px;

  @media (--viewportSmall) {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.topbarMessage {
  @apply --titleStyle7;
  font-size: 12px;
  word-spacing: 2px;
  color: var(--beige);
  line-height: 0;

  @media (--viewportSmall) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: var(--beige);
    width: 90vw;
    text-align: center;
    line-height: 1.25;
    font-size: 10px;
  }
}

/* Blog article section */

.blogArticleContainer {
  background: var(--beige);
  background: linear-gradient(180deg, var(--beige) 0%, var(--white) 40%);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 10px 50px 0;

  @media (--viewportSmall) {
    padding: 20px 15px;
    width: 100vw;
  }
}

.blogArticleSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 95%;
  }
}

.backToBlogSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;

  @media (--viewportSmall) {
    width: 17px;
    height: 17px;
    margin-bottom: 0;
  }
}

.backToBlogText {
  @apply --titleStyle7;
  font-size: 14px;
  margin-left: 10px;

  @media (--viewportSmall) {
    font-size: 13px;
  }
}

.articleTitle {
  text-align: center;
  @apply --titleStyle1;
  margin: 20px auto 0;
  max-width: 1000px;

  @media (--viewportSmall) {
    max-width: 95vw;
    margin: 20px 0 0;
  }
}

.blogDate {
  @apply --titleStyle7;
  font-size: 13px;
  margin: 10px auto 20px;

  @media (--viewportSmall) {
    font-size: 12px;
  }
}

.articleThumbnailImage {
  @apply --darkBorder;
  max-width: 1000px;
  margin: 0 auto;

  @media (--viewportSmall) {
    max-width: 100%;
    margin: 0 auto;
  }
}

.blogAuthorSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.authorImage {
  max-width: 100px;
  border: 1px solid var(--black);
  border-radius: 100%;
  margin-top: -50px;

  @media (--viewportSmall) {
    max-width: 25%;
    margin-top: -40px;
  }
}

.blogAuthor {
  @apply --titleStyle7;
  font-size: 13px;
  margin-bottom: 0;

  @media (--viewportSmall) {
    font-size: 12px;
  }
}

.blogAuthorPosition {
  @apply --titleStyle7;
  font-size: 13px;
  margin-top: 0;

  @media (--viewportSmall) {
    font-size: 12px;
  }
}

.articleTextContainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

.imageCopyrightText {
  @apply --bodyStyle5;
  font-size: 12px;
  margin: 0 auto 10px;

  @media (--viewportSmall) {
    margin: 5px auto;
    text-align: center;
  }
}

.articleIntroParagraph {
  @apply --bodyStyle2;
  font-weight: var(--fontWeightSemiBold);
  margin: 20px auto;
  max-width: 800px;
}

.articleSubtitle {
  @apply --titleStyle2;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 0;
  max-width: 800px;
}

.articleParagraph {
  @apply --bodyStyle2;
  margin: 20px auto 10px;
  max-width: 800px;
}

.paragraphVisual {
  margin: 20px auto 10px;
  max-width: 800px;
  @apply --darkBorder;

  @media (--viewportSmall) {
    max-width: 100%;
  }
}

.articleImageCopyrightText {
  @apply --bodyStyle5;
  font-size: 12px;
  margin: 20px 0;

  @media (--viewportSmall) {
    max-width: 95%;
    margin: 5px 0;
  }
}

.linkFormat {
  @apply --bodyStyle2;
  color: var(--bluePlus35);
}

.linkFormat:hover {
  text-decoration: underline;
  color: var(--bluePlus35);
}

/* Call to action - Register */

.callToRegisterContainer {
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  margin-bottom: 50px;
  overflow: hidden;

  @media (--viewportSmall) {
    padding: 15px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: center;
  }
}

.callToRegisterSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  flex-wrap: wrap;
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    justify-content: center;
    align-items: center;
  }
}

.callToRegisterTitle {
  @apply --titleStyle1;
  margin: 50px auto 40px;
  text-align: center;

  @media (--viewportSmall) {
    margin: 40px auto 15px;
    text-align: center;
  }
}

.signUpSection {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.signUpButton {
  @apply --callToActionButtonStyles;
}

.moreInfo {
  @apply --bodyStyle3;
  text-align: center;
  margin: 25px 0 10px;
}

.loginLink {
  @apply --bodyStyle3;
  margin-left: 5px;
  text-decoration: underline;
}

.loginLink:hover {
  color: var(--grey);
}