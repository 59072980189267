@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  overflow: hidden;

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1280px;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
   }
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  margin: 0 20px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    margin: 20px 10px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--black);
  }
}

.step:last-child {
  @media (--viewportSmall) {
    border-bottom: hidden;
  }
}

.stepCallToRegister {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
  margin: 0 15px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    align-items: center;
    margin: 20px 10px 0;
    padding-bottom: 25px;
  }
}

.stepCallToRegisterTitle {
  @apply --titleStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 0 auto 30px;

  @media (--viewportSmall) {
    font-size: 24px;
    text-align: center;
    margin: 0 auto 20px;
  }
}

.buttonContainer {
  display: flex;
}

.signUpButton {
  @apply --primaryBlackbeanButtonStyles;
}

.stepIcon1, .stepIcon2, .stepIcon3 {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    height: 40px;
    width: 40px;
  }
}

.stepIcon1 {
  background-image: url('../../assets/002-products.svg');
  background-size: 48px 48px;

  @media (--viewportSmall) {
    background-size: 38px 38px;
  }
}

.stepIcon2 {
  background-image: url('../../assets/003-product.svg');
  background-size: 45px 45px;

  @media (--viewportSmall) {
    background-size: 35px 35px;
  }
}

.stepIcon3 {
  background-image: url('../../assets/001-boxes.svg');
  background-size: 50px 50px;

  @media (--viewportSmall) {
    background-size: 40px 40px;
  }
}

.stepTitle {
  @apply --bodyStyle1;
  font-weight: var(--fontWeightMedium);
  margin: 0 auto 0;
  text-align: center;
  max-width: 220px;

  @media (--viewportSmall) {
    font-size: 20px;
    max-width: 250px;
  }
}

.stepTitleLarge {
  @apply --bodyStyle1;
  font-weight: var(--fontWeightMedium);
  margin: 0 auto 0;
  text-align: center;
  max-width: 280px;

  @media (--viewportSmall) {
    font-size: 20px;
    max-width: 250px;
  }
}