@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  display: flex;
  height: 750px;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--black);
  
  @media (--viewportSmall) {
    width: 100vw;
    height: auto;
    border-top: none;
  }
}

.formContainer {
  background: url('../../assets/vegan-cheese-sausage-baked-goods.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  
  @media (--viewportSmall) {
    width: 100vw;
    background: none;
    height: auto;
  }
}

.formPanel {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 620px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFormBackground);
  border: 1px solid var(--black);
  margin: 30px auto 40px;
  
  @media (--viewportSmall) {
    width: 90vw;
    margin: 40px auto 80px;
    box-shadow: none;
    border: none;
    background-color: var(--beigeMinus70);
    padding: 0;
  }
}

.formTitle {
  @apply --titleStyle2;
  margin: 0 80px;
  text-align: center;

  @media (--viewportSmall) {
    margin: 0 10px;
  }
}

.formDescription {
  @apply --bodyStyle2;
  text-align: center;
  margin: 15px auto 5px;
  
  @media (--viewportSmall) {
    width: 80vw;
  }
}

.errorMessage {
  @apply --bodyStyle3;
  color: var(--red);
  display: block;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
  }
}