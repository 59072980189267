@import '../../styles/propertySets.css';

.pageContainer {
  min-height: 500pt;
  background-color:#FAF7F5;
}
.clientForm{ 
 width: 600pt;
 margin: 50pt auto;
}
.filed input{
  width: 100%;
  color: #000000;
  font-family: Lato;
  font-size: 13pt;
  background-color: #ffffff;
  border: 1px solid #E9EAEC;
  padding: 3pt 10pt;
}
.checkButton{
  background-color: #3F5CDB;
  padding: 3pt 10pt;
  text-align: center;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 10pt;
  line-height: 12pt;
  border-radius: 22pt;
  border: none;
  display: block;
  margin: 10pt auto;
}
.checkButton:hover{
  text-decoration: underline;
  cursor: pointer;
}
.invalidAccessKey{
  color: #ff0000;
  text-align: center;
  margin: 5pt;
  font-family: Lato;
  font-size: 13pt;
}