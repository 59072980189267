@import '../../styles/propertySets.css';

/* Order preview - Collapsed */

.transactionItem {
  @apply --darkBorder;
  background-color: var(--white);
  padding: 30px;
  margin-bottom: 30px;
  width: 1030px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 20px 10px;
  }
}

.showDetailsEnable {
  @apply --darkBorder;
  box-shadow: var(--boxShadowPopup);
}

.shortDataContainer {
  display: flex;
  justify-content: space-between;
  align-items: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Image Grid */

.orderPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  width: 120px;
  height: 120px;
  @apply --darkBorder;
  border-radius: 3px;

  @media (--viewportSmall) {
    margin-bottom: 15px;
  }
}

.orderPreviewItem {
  width: 50%;
  height: 50%;
}

.orderPreviewItemPlaceholder {
  width: 50%;
  height: 50%;
}

.orderPreviewItem:first-child,
.orderPreviewItem:nth-child(3),
.orderPreviewItemPlaceholder:first-child,
.orderPreviewItemPlaceholder:nth-child(3) {
  border-right: 1px solid var(--black);
}

.orderPreviewItem:first-child,
.orderPreviewItem:nth-child(2),
.orderPreviewItemPlaceholder:first-child,
.orderPreviewItemPlaceholder:nth-child(2) {
  border-bottom: 1px solid var(--black);
}

.transactionLogo {
  @apply --darkBorder;
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  font-size: 0;
  margin-right: 3px;

  @media (--viewportSmall) {
    margin: 0 0 20px;
  }
}

/* Function: Order progress bar */

.transitionItem {
  flex: 1;
  position: relative;
  text-align: center;
}

.transitionItem .confirmButton,
.transitionItem .transitionButton,
.transitionItem .rejectButton {
  display: none;
}

.transitionItemConfirmed .stepIcon {
  background-color: var(--green);
  background-image: url('../../assets/check-000000.svg');
  background-position: center center;
  background-size: 70% 70%;
}

.transitionItemConfirmed .stepName,
.transitionItemShipped .stepName,
.transitionItemDelivered .stepName,
.transitionItemPaid .stepName {
  @apply --titleStyle7;
  font-size: 13px;
}

.transitionItemPending .stepIcon {
  background-color: var(--pending);
}

.transitionItemPending .confirmButton,
.transitionItemPending .rejectButton {
  display: block;
}

.transitionItemPending .confirmButton {

  @media (--viewportSmall) {
    position: absolute;
    left: 0;
    width: 140px;
  }
}

.transitionItemPending .rejectButton {

  @media (--viewportSmall) {
    position: absolute;
    right: 0;
    margin-right: -300%;
    width: 140px;
  }
}

.transitionItemShipped .stepIcon {
  background-color: var(--green);
  border: 1px solid var(--black);
  border-radius: 100%;
  background-image: url('../../assets/check-000000.svg');
  background-position: center center;
  background-size: 70% 70%;
}

.transitionItemShippedActive .transitionButton {
  display: block;
  margin: 0 auto 20px;
  width: 180px;

  @media (--viewportSmall) {
    position: absolute;
    left: 50%;
    bottom: -40px;
    width: 140px;
    margin: 0 0 0 -70px;
  }
}

.transitionItemDelivered .stepIcon {
  background-color: var(--green);
  background-image: url('../../assets/check-000000.svg');
  background-position: center center;
  background-size: 70% 70%;
}

.transitionItemDeliveredActive .transitionButton {
  display: block;
  margin: 0 auto;
  width: 180px;

  @media (--viewportSmall) {
    position: absolute;
    left: 50%;
    bottom: -40px;
    width: 140px;
    margin: 0 0 0 -70px;
  }
}

.transitionItemPaid .stepIcon {
  background-color: var(--green);
  background-image: url('../../assets/check-000000.svg');
  background-position: center center;
  background-size: 70% 70%;
}

.transitionButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0 auto;
}

.disableTransitionButton {
  background-color: var(--lightGrey);
  color: var(--darkGrey);
  cursor: default;
  margin: 0 auto;
  width: 180px;
}

.disableTransitionButton:hover {
  text-decoration: none;
  background-color: var(--lightGrey);
  color: var(--darkGrey);
  cursor: default;
}

/* Order preview - Collapsed */

.orderShortInfo {
  flex: 1;
  margin-left: 20px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.dataRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    flex-direction: column;
    margin-bottom: 5px;
  }
}

.orderDate {
  @apply --titleStyle7;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.orderNumber {
  @apply --bodyStyle3;

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.orderVolume {
  @apply --bodyStyle3;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.buyerStoreName {
  @apply --titleStyle7;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.buyerStoreName:hover {
  color: var(--darkGrey);
}

.orderTotal {
  @apply --titleStyle7;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.stateContainer {

  @media (--viewportSmall) {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

 /* Function: Order status */

 .transactionItemState {
  display: flex;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
  }
}

 .state {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  color: var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  height: 30px;
  background-color: var(--beige);
  border: 1px solid var(--black);
  min-width: 140px;
  display: inline-block;
}

.pending, .expired, .canceled, .rejected {
  color: var(--darkGrey);
  background-color: var(--lightGrey);
}

.confirmed, .shipped, .delivered, .paid {
  background-color: var(--beige);
}

/* End of Function: Order status */

.showDetailsButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    margin: 10px auto 0;
  }
}

.expireTime {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: var(--orange);
  display: inline-block;
  margin-left: 10px;
  max-width: 250px;
  font-style: italic;

  @media (--viewportSmall) {
    margin: 20px auto 0;
    width: 100%;
    text-align: center;
  }
}

.expireTimeDE {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: var(--orange);
  display: inline-block;
  margin-left: 10px;
  max-width: 305px;
  font-style: italic;

  @media (--viewportSmall) {
    margin: 20px auto 0;
    width: 100%;
    text-align: center;
  }
}

/* Order details - Expanded */

.orderDetailsContainer {
  border-top: 1px solid var(--grey);
  margin-top: 30px;
}

/* Delivery details & Order summary section */

.orderDetailsRow {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.previewItemTitle {
  @apply --titleStyle7;
  margin: 0 0 20px;
}

.previewItemValue {
  @apply --bodyStyle3;
  margin: 0 50px 0 0;
  width: 250px;
  overflow-wrap: anywhere;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.sendCompanyButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 30px auto;
  display: inline-block;

  @media (--viewportSmall) {
    display: block;
    width: 250px;
  }
}

/* Order summary */

.orderSummary {
  min-width: 370px;

  @media (--viewportSmall) {
   min-width: unset;
   width: 100%;
  }
}

.orderSummaryTitle {
  @apply --titleStyle7;
  margin: 0 0 20px;
}

.priceItemRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceLabel {
  @apply --bodyStyle3;
}

.priceValue {
  @apply --bodyStyle3;
}

.detailsSeparatorBlack {
  box-sizing: border-box;
  border-top: 1px solid var(--grey);
  margin: 20px 0 0;
}

.priceSummaryItem {
  display: flex;
  justify-content: space-between;
}

.priceSummaryLabel, .priceSummaryValue {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
}

/* Order Progress bar section */

.orderProgressBarContainer {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--grey);

  @media (--viewportSmall) {
    position: relative;
    padding-bottom: 70px;
    margin: 50px 0 40px;
  }
}

.stepName {
  @apply --titleStyle7;
  font-size: 13px;
  color: var(--grey);
}

.stepIcon {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: var(--lightGrey);
  border: 1px solid var(--black);
  border-radius: 100%;
  display: inline-block;
  z-index: 5;
  position: relative;
}

.progressAxis {
  font-size: 0;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  right: 50%;
  top: 80px;
  z-index: 1;

  @media (--viewportSmall) {
    top: 79px;
  }
 }

.confirmButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0 auto 20px;
  width: 180px;
}

.rejectButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 auto 10px;
  width: 180px;
}

/* Modal windows */

.modalWindow {
  @apply --modalTransparentBackground;
}

.modalWindowContent {
  @apply --modalWhiteBackgroundSmall;

  @media (--viewportSmall) {
    width: 100vw;
  }
}

.closeButton {
  @apply --closeButton;
}

.modalTitle {
  @apply --modalTitle;
}

.modalSubTitle {
  @apply --modalSubtitle;
}

.modalButtons {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.modalPrimaryButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0;
}

.modalSecondaryButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 20px 0 0;

  @media (--viewportSmall) {
    margin: 0 0 20px;
  }
}
/* END OF: Modal windows */

.paidExpiration {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: var(--orange);
  font-style: italic;
  width: 170px;
  margin: 0 auto;
  text-align: center;

  @media (--viewportSmall) {
    position: absolute;
    right: 0;
    bottom: -40px;
  }
}

/* Product section */

.detailsBlockTitle {
  @apply --titleStyle7;
  text-align: left;
  margin: 0 0 20px;
}

.productItem {
  display: flex;
  margin: 0 auto 30px;
  align-items: center;

  @media (--viewportSmall) {
    margin: 0 auto 40px;
    align-items: flex-start;
  }
 }

.productItemLogo {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  margin: 0 20px 0 0;
  @apply --darkBorder;

  @media (--viewportSmall) {
    width: 50px;
    height: 50px;
  }
}
 
.productItemDetails {
  flex: 1;
}
 
.productItemTitle {
  @apply --titleStyle7;
  font-size: 14px;
  text-align: left;
  margin: 0 0 10px;
}

.productItemTitle:hover {
  color: var(--darkGrey);
}
 
.productItemField {
  display: flex;
}
 
.fieldLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  text-align: left;
  margin-right: 10px;
  width: 100px;

  @media (--viewportSmall) {
    width: 40%;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
 
.fieldValue {
  @apply --bodyStyle3;
  text-align: left;

  @media (--viewportSmall) {
    width: 60%;
    margin-bottom: 5px;
  }
}
 
.productItemPriceRow {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}
 
.totalPrice {
  display: flex;
}
 
.totalPriceLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  text-align: right;
  margin-right: 5px;
  
  @media (--viewportSmall) {
    margin-right: 5px;
    width: 40%;
    text-align: left;
  }
}
 
.totalPriceValue {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  text-align: left;

  @media (--viewportSmall) {
    width: 60%;
  }
}

/* Invoice section - Currently not used */

.downloadInvoiceContainer {
}

.downloadInvoiceTitle {
}

.downloadInvoiceButtonsContainer {
}

.downloadInvoiceButton {
}