@import '../../styles/propertySets.css';

/* Order preview - Collapsed */

.transactionItem {
  @apply --darkBorder;
  background-color: var(--white);
  padding: 30px;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 20px 10px;
  }
}

.showDetailsEnable {
  @apply --darkBorder;
  box-shadow: var(--boxShadowPopup);
}

.shortDataContainer {
  display: flex;
  justify-content: space-between;
  align-items: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.orderLogo {
  width: 120px;
  height: 120px;
  @apply --darkBorder;
  border-radius: 3px;
}

/* Product Image Grid - DO NOT DELETE! CSS classes are used in JS, despite not having exact class names displayed in JS, (line 131 in JS) */

.singleImageProduct {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  border-radius: 3px;
}

.imagePart0, .imagePart1,
.imagePart2, .imagePart3 {
  width: 50%;
  height: 50%;
  display: inline-block;
  background-color: var(--white);
  background-position: center center;
  background-size: cover;
}

.imagePart0, .imagePart2 {
  border-right: 1px solid var(--black);
}

.imagePart0, .imagePart1 {
  border-bottom: 1px solid var(--black);
}

.imagePart0 {
  border-top-left-radius: 3px;
}

.imagePart1 {
  border-top-right-radius: 3px;
}

.imagePart2 {
  border-bottom-left-radius: 3px;
}

.imagePart3 {
  border-bottom-right-radius: 3px;
}

/* Order preview - Collapsed - text info */

.shortTotalInfo {
  display: flex;
  justify-content: left;
  flex-direction: column;
  flex: 1;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.orderInfoContainer {
  display: flex;
  justify-content: left;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.orderShortInfo {
  margin-left: 20px;

  @media (--viewportSmall) {
    width: 100%;
    margin: 10px auto 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.orderDate {
  @apply --titleStyle7;
  margin-bottom: 10px;
}

.orderTotal {
  @apply --bodyStyle3;
  margin-bottom: 10px;
}

.supplierNameAndStatusContainer {
  flex: 1;
}

.supplierNameAndStatusRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.supplierBrandName {
  @apply --titleStyle7;
  margin-right: 20px;
}

.supplierBrandName:hover {
  color: var(--darkGrey);
}

.shortBrandInfoStatus {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  color: var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  height: 30px;
  background-color: var(--beige);
  border: 1px solid var(--black);
  min-width: 140px;
  display: inline-block;
  margin-left: 10px;
}

.brandCanceledStatus {
  color: var(--darkGrey);
  background-color: var(--lightGrey);
}

.detailsButtonContainer {
  display: flex;
  margin: 15px 0 0 20px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.showDetailsButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    margin: 10px auto 0;
  }
}

/* Order details - Expanded */

.orderDetailsContainer {
  margin-top: 30px;
}

/* Delivery details & Order summary section */

.orderDetailsRow {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  padding: 30px 0 0;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.previewItemData {
  margin-right: 50px;
  display: flex;
}

.previewItemFirstColumn {
  flex: 1;
}

.previewItemTitle {
  @apply --titleStyle7;
  margin: 0 0 20px;
}

.previewItemValue {
  @apply --bodyStyle3;
  margin: 0 50px 0 0;
  width: 250px;
  overflow-wrap: anywhere;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.previewItemValue:last-child {
  margin: 0 0 30px;
  width: 200px;
}

.cardIcon {
  width: 40px;
  height: 30px;
  margin-right: 10px;
}

/* Order summary */

.orderSummary {
  min-width: 370px;

  @media (--viewportSmall) {
    min-width: unset;
    width: 100%;
  }
}

.orderSummary h1 {
  @apply --titleStyle7;
  margin: 0 0 20px;
}

.brandPriceBlock {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--grey);
}

.cartProperty {
  display: flex;
  justify-content: space-between;
}

.propertyLabel {
  @apply --bodyStyle3;
  margin-bottom: 10px;
}

.propertyValue {
  @apply --bodyStyle3;
}

.cartPropertyTotal {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 10px;
  border-top: 1px solid var(--grey);
}

.propertyLabelTotal, .propertyValueTotal {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
}

/* Brands & Products section */

.brands {
  flex: 1;
}

.brandItem {
  margin: 0 auto 30px;
}

.brandItem:last-child {
  margin-bottom: 0;
}

.brandItemHeader {
  background-color: var(--beigeMinus50);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.brandHeaderMainColumn {
  flex: 1;
}

.brandCancelledLabel {
  @apply --titleStyle7;
  color: var(--orange);
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 5px;
}

.brandTitle {
  @apply --titleStyle7;
  text-align: left;
  margin: 0 0 5px;
}

.brandSubTitle {
  @apply --bodyStyle3;
  text-align: left;
  margin: 0;
}

.shopBrandButton {
  @apply --primaryBlackbeanButtonStyles;
  display: inline-block;
  margin: 0 auto;

  @media (--viewportSmall) {
    margin: 20px auto 0;
  }
}

.productContainer {
  padding: 20px 0;
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.productItemsContainer {
  border-right: 1px solid var(--grey);
  flex: 2;

  @media (--viewportSmall) {
    margin-bottom: 40px;
    border-right: none;
  }
}

.productItem {
  flex: 2;
  display: flex;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 10px;
  padding-bottom: 10px;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.productItem:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;

  @media (--viewportSmall) {
    border-bottom: 1px solid var(--grey);
    padding-bottom: 10px;
  }
}

.productLogo {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  margin: 0 20px 0 0;
  @apply --darkBorder;

  @media (--viewportSmall) {
    width: 60px;
    height: 60px;
  }
}

.productDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: space-between;
  justify-content: center;
}

.productNameRow {
}

.productName {
  @apply --titleStyle7;
  font-size: 14px;
  margin: 0 0 5px;
  max-width: 450px;
  word-break: normal;
}

.productIDRow {
  @apply --bodyStyle4;
  margin-bottom: 20px;
}

.productQuantityAndPriceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.productPriceRow {
  display: flex;
  justify-content: space-between;
  @apply --bodyStyle4;
}

.productPrice {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightSemiBold);
  margin-right: 30px;

  @media (--viewportSmall) {
    margin-right: 0;
  }
}

.shippingFeeContainer {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;

  @media (--viewportSmall) {
    padding: 0;
  }
}

.brandShippingFee {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 10px;
  max-width: 250px;
}

.brandDeliveryTime {
  @apply --bodyStyle4;
  max-width: 250px;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}