@import '../../styles/propertySets.css';

.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @apply --darkBorder;
  padding: 50px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
  }
}

.title {
  @apply --titleStyle3;
  margin: 0 0 20px;
}

.descriptionText {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  white-space: pre-line;
  text-overflow: ellipsis;
}

.mainDescription, 
.mailDescription {
  margin: 0;
}

.termsDescription {
  margin: 20px 0 30px;
}

.inlineLink {
  text-decoration: underline;
}

.inlineLink:hover {
  color: var(--darkGrey);
}

.deleteButton {
  @apply --secondaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    display: block;
    margin: 0 auto;
  }
}