@import '../../styles/propertySets.css';

.payoutAndShippingPageContainer {
}

/* Payout details section */

.payoutDetailsSection {
  @apply --darkBorder;
  padding: 50px;
  margin-bottom: 30px;
  min-height: 425px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--black);
  }
}

.payoutDetailsTitle {
  @apply --titleStyle3;
  margin: 0;
}

.loadingText {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Shipping details section */

.shippingDetailsSection {
  @apply --darkBorder;
  padding: 50px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
  }
}

.shippingDetailsTitle {
  @apply --titleStyle3;
  font-weight: var(--fontWeightBold);
  margin: 0;
}

/* Styles for CountryShippingSection.js file that also calls this CSS style sheet */

.sectionBackground {
}

.sectionMargin {
}

.title {
  @apply --titleStyle3;
  margin: 0;
}

.generalError {
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    display: block;
    margin: 0 auto;
  }
}