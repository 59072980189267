@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 50px 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.newBrandsPageContent {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: center;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 90vw;
  }
}

.pageTitle {
  @apply --titleStyle3;
  margin: 0 0 20px;
}

.newBrandsTableContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.newBrandsTable {
  width: 100%;
}

.newBrandsTable th {
  @apply --titleStyle7;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  background-color: var(--beigeMinus50);
}

.newBrandsTable tbody tr td {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  text-align: center;
  overflow-wrap: break-word;
}

.newBrandsTable tbody tr {
  border-bottom: 1px solid var(--beigeMinus50);
}

.newBrandsTable tbody tr:last-child {
  border-bottom: none;
}

.buttonStyleUp,
.buttonStyleDown,
.buttonStyleAdd,
.buttonStyleSave {
  @apply --primaryBlackbeanButtonStyles;
}

.buttonStyleUp,
.buttonStyleAdd {
  margin-right: 15px;
}

.buttonStyleAdd,
.buttonStyleSave {
  margin-top: 30px;
}

.brandPreview,
.categoryName,
.buttonsUpDown,
.brandColor,
.tickbox,
.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoBrand {
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-left: 20px;
  border: 1px solid var(--beigeMinus50);
}

.brandInfo {
  margin: 5px 20px;
}

.brandTitle {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
}

.brandCountry {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  color: var(--darkGrey);
  text-align: left;
}

.brandSelect,
.brandSelect:hover,
.brandSelect:focus {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  height: 40px;
  border-radius: 3px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  padding-left: 15px;
  width: 220px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.textInput,
.textInput:hover,
.textInput:focus {
  @apply --input;
  width: 220px;
  margin-left: 20px;
  margin-right: 20px;
}

.textInputColor,
.textInputColor:hover,
.textInputColor:focus {
  @apply --input;
  width: 150px;
  margin: 0 20px;
}

.tickbox input {
  height: 20px;
  width: 20px;
  margin: 0 30px;
}

.deleteButtonIcon {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: var(--black);
  background-position: center center;
  background-image: url('../../assets/trash-ffffff.svg');
  background-size: 16px 16px;
  font-size: 0;
  border: none;
  cursor: pointer;
  margin: 0 30px;
}