@import '../../styles/propertySets.css';

.formFields {
  margin: 10px auto 0;
  display: flex;
  flex-direction: column;
  width: 360px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formRow {
  text-align: center;
}

.formField {
  width: 360px;
  display: inline-block;
  text-align: left;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
}

.bottomWrapper {
  text-align: center;
  margin: 40px auto 0;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
  display: inline-block;
}

.submitButton:disabled {
  opacity: 0.8;
}

.requiredMarker {
  color: var(--red);
}

.errorMessage {
  @apply --bodyStyle3;
  color: var(--red);
  margin: 0 0 8px;
  padding-left: 7px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}