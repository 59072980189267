@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.buyerForm {
    @apply --bodyStyle3;

    @media (--viewportSmall) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.buyerForm button {
    @apply --primaryBlackbeanButtonStyles;
    margin: 20px auto 0;

    @media (--viewportSmall) {
        margin: 20px auto 40px;
        text-align: center;
    }
}

.buyerForm input {
    border: 1px solid var(--black);
    border-radius: 3px;
    margin: 20px auto 10px;
    padding-left: 15px;
    height: 40px;
    max-width: 500px;

    @media (--viewportSmall) {
        width: 80vw;
    }
}

.buyerForm p {
    @apply --bodyStyle3;
    color: var(--black) !important;

    @media (--viewportSmall) {
        margin: 0 auto 40px;
    }
}