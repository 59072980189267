@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.darkSkin {
  background-color: #000000;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  @apply --marketplaceLinkStyles;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  /* Font */
  @apply --bodyStyle3;

  color: #000000;
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000000;
  }

  @media (--viewportMedium) {
    border-bottom-width: 4px;
    padding: 16px 10px 14px 10px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: #000000;
  color: #000000;
}

.disabled {
  pointer-events: none;
  color: #b2b2b2;
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: #b2b2b2;

  &:hover {
    color: #ffffff;
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: #ffffff;
  color: #ffffff;
}

.disabledDarkSkin {
  color: #000000;
  opacity: 0.3;
}
