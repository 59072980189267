@import '../../styles/propertySets.css';

.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid #e7e7e7;
}

.filterLabel,
.filterLabelSelected {
  @apply --bodyStyle3;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
}

.filterLabel {
  color: #000000;
}

.filterLabelSelected {
  color: #F9F1A1;
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.formWrapper {
  padding-left: 20px;
}

.clearButton {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: #b2b2b2;

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: #000000;
  }
}
