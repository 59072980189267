@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  & .scrollLayer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-overflow-scrolling: touch;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
  }

  & .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    max-width: 80%;
    width: 100%;
    background-color: var(--white);
    border-right: 1px solid var(--black);
    min-height: 100vh;
    height: 100%;
    overflow: auto;

    @media (--viewportSmall) {
      max-height: unset;
      height: unset;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.isClosed {
  display: none;
}

.close {
  @apply --closeButton;
  max-height: 30px;
  max-width: 30px;
}