@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.categoryContainer {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0 60px;
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 50px 15px 30px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.categorySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    width: 93vw;
    max-width: 95vw;
  }
}

.categoryTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media (--viewportSmall) {
    width: 95vw;
    max-width: 90vw;
  }
}

.categoryTextContainerTitle {
  @apply --titleStyle1;
  max-width: 1000px;
  text-align: center;
  margin: 0 auto 15px;

  @media (--viewportSmall) {
    max-width: 95vw;
  }
}

.mobileLineBreak {
  display: none;

  @media (--viewportSmall) {
    display: block;
  }
}

.categoryTextContainerSubtitle {
  @apply --bodyStyle1;
  max-width: 1100px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0;

  @media (--viewportSmall) {
    width: 95vw;
  }
}

.categoryImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportSmall) {
    align-items: center;
  }
}

.categoryItem {
  display: inline-block;
  width: 380px;
  height: 380px;
  margin-bottom: 70px;

  @media (--viewportSmall) {
    max-width: 200px;
    max-height: 200px;
    width: calc(100vw/2.3);
    height: calc(100vw/2.3);
  }
}

.categoryLink h3 {
  display: block;
  @apply --titleStyle7;
  text-align: center;
  margin: 15px auto 0;

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.categoryLink h3:hover {
  color: var(--darkGrey);
}

.categoryImage1, .categoryImage2, .categoryImage3,
.categoryImage4, .categoryImage5, .categoryImage6 {
  max-width: 380px;
  max-height: 380px;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    max-width: 200px;
    max-height: 200px;
    width: calc(100vw/2.3);
    height: calc(100vw/2.3);
   }
}

.categoryImage1, .categoryImage5 {
  border-radius: 50px;
}

.categoryImage2, .categoryImage6 {
  border-radius: 150px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.categoryImage3, .categoryImage4 {
  border-radius: 0;
}

/* MOBILE VIEW */

.categoryImage1, .categoryImage4 {

  @media (--viewportSmall) {
  border-radius: 50px;
  }
}

.categoryImage2, .categoryImage5 {

  @media (--viewportSmall) {
    border-radius: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.categoryImage3, .categoryImage6 {

  @media (--viewportSmall) {
    border-radius: 0;
  }
}