@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.profileCompanyContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

/* Cover section */

.coverContainer { 
  width: 100%;
  padding: 30px 0 0 0;
  width: 1280px;

  @media (--viewportSmall) {
    padding: 0;
  }
}

.coverPhotoContainer {
  position: relative;
  height: 270px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 5px;

  @media (--viewportSmall) {
    height: 190px;
  }
}

.editingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  padding: 0 50px;
  justify-content: flex-end;
}

.editCoverPanel {
  width: 40px;
  margin-top: -100px;

  @media (--viewportSmall) {;
    margin-top: -285px;
  }
}

.coverMenuLabel,
.coverMenuLabel:hover,
.coverMenuLabel:focus {
  border: none;
  box-shadow: none;
}

.profileMenuIsOpen {
}

.editCoverLink {
}

.updateImageButton {
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 18px 18px;
  background-color: var(--white);
  border: 1px solid var(--black);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
}

.updateImageButtonPlain {
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 18px 18px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
  margin: 0 auto;
}

.updateImageButtonPlain.hasLogo {
  background-position: center center;
  background-image: url('../../assets/pencil-ffffff.svg');
  background-size: 18px 18px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
  margin: 0 auto;
}

.profileMenuContent {
  width: 200px;
  margin-top: -15px;
  margin-right: 0;
  padding: 20px 25px;
  background-color: var(--white);
  border-radius: 5px;

  @media (--viewportSmall) {;
    width: 90vw;
    margin-top: -115px;
    text-align: right;
  }
}

.menuItemFirst {
  @apply --bodyStyle3;
  position: relative;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  border: none;
  cursor: pointer;

  @media (--viewportSmall) {
    text-align: right;
  }
}

.menuItemFirst:hover {
  color: var(--darkGrey);
}

.menuItemFirstHelperText {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  padding: 5px 0 15px;
  cursor: default;
}

.menuItemSecond {
  @apply --bodyStyle3;
  position: relative;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  border: none;
  cursor: pointer;
}

.menuItemSecond:hover {
  color: var(--darkGrey);
}

/* Company key data section */

.profileCompanySection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
  flex-wrap: wrap;
  width: 90vw;
  }
}

.companyInfoPanel {
  display: flex;
  padding: 0 50px 30px;
  min-height: 350px;

  @media (--viewportSmall) {
    flex-direction: column;
    width: 100%;
    padding: 0 0 10px;
  }
}

.companyInfoFirstColumn {
  position: relative;
  width: 50%;
  margin: 0;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.companyLogo {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--black);
  border-radius: 100%;
  left: 0;
  top: -110px;

  @media (--viewportSmall) {
    position: static;
    margin: -110px auto 0;
  }
}

.companyLogo:hover .editCompanyLogoPanel {
  display: block;
}

.editCompanyLogoPanel {
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  padding-top: 55px;
}

.editCompanyLogoPanel.hasLogo:hover {
  background-color: var(--transparentLogoBg);
}

.editCompanyLogoPanel a {
  display: block;
  width: 150px;
  margin: 0 auto;
}

.editCompanyLogoPanel .updateImageButton {
  display: block;
  margin: 0 auto;
}

.editCompanyLogoPanel.hasLogo {
  display: none;
}

.editCompanyLogoLink {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  cursor: pointer;
}

.editCompanyLogoLink:hover {
  color: var(--grey);
}

.editCompanyLogoLink:hover .hasLogo {
  color: var(--grey);
}

.editCompanyLogoLink.hasLogo {
  color: var(--white);
}

.companyName {
  @apply --titleStyle3;
  font-size: 28px;
  margin: 25px 25px 5px 220px;
  word-wrap: normal;

  @media (--viewportSmall) {
    text-align: center;
    margin: 15px auto 5px;
  }
}

.companyLocation {
  @apply --bodyStyle2;
  margin: 0 25px 50px 220px;

  @media (--viewportSmall) {
    text-align: center;
    margin: 0 auto 30px;
  }
}

.companyPropertyRowContainer {
}

.companyPropertyRow {
  margin-top: 0;
  margin-bottom: 18px;
}

.companyFieldLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
 
.companyFieldValue {
  @apply --bodyStyle3;
  display: inline-block;
  vertical-align: top;
}

.companyFieldValueWebsite {
  @apply --bodyStyle3;
  display: inline-block;
  vertical-align: top;
}

.companyFieldValueWebsite:hover {
  color: var(--darkGrey);
}

.companyInfoSecondColumn {
  width: 50%;
  margin: 0;

  @media (--viewportSmall) {
    width: 100%;
    margin-top: 32px;
  }
}

.editCompanyInfo {
  margin: 25px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (--viewportSmall) {
    margin: 0;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.editCompanyLink {
  @apply --secondaryBlackbeanButtonStyles;
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    margin: 0 auto;
  }
}

.editCompanyButton {
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 18px 18px;
  background-color: var(--white);
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 0;
  margin-right: 5px;
  cursor: pointer;

  @media (--viewportSmall) {
    display: none;
  }
}

.companyDescriptionLabel {
  @apply --titleStyle7;
  margin-top: 74px;
  margin-bottom: 5px;

  @media (--viewportSmall) {
    margin-top: 50px;
  }
}

.companyDescriptionValue {
  @apply --bodyStyle3;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.emptyDescription {
  @apply --bodyStyle3;
  color: var(--darkGrey);
}

.companyImportantsLabel {
  @apply --titleStyle7;
  margin-top: 30px;
  margin-bottom: 5px;
}

.companyImportants {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;

  @media (--viewportSmall) {
    justify-content: center;
  }
}

.companyImportantItem {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  background-color: var(--beigeMinus50);
  border: 1px solid var(--black);
  color: var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  height: 30px;
  margin: 0 10px 10px 0;

  @media (--viewportSmall) {
    margin: 0 5px 10px 5px;
  }
}

/* Image section */

.imagesContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 20px;
    padding: 0 50px;
  }
}

.changeImageButtonIcon {
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 18px 18px;
  background-color: var(--white);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
}

.addImageButtonIcon {
  background-position: center center;
  background-image: url('../../assets/plus-000000.svg');
  background-size: 30px 30px;
  background-color: var(--white);
  border: 1px solid var(--black);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
  margin: 8px auto;
}

.imageItem {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 5px;
}

.mainShopImage {
  width: calc(50% - 15px);
  height: 383px;

  @media (--viewportSmall) {
    width: 100%;
    height: 350px;
  }
}

.secondShopImage {
  width: calc(25% - 15px);
  height: 383px;

  @media (--viewportSmall) {
    width: 100%;
    height: 350px;
    margin-top: 30px;
  }
}

.addImageButton {
  display: inline-block;
  margin-top: 145px;
  @apply --bodyStyle4;
  color: var(--darkGrey);
  cursor: pointer;

  @media (--viewportSmall) {
    margin-top: 112px;
  }
}

.addImageButton:hover {
  color: var(--grey);
}

.addImageButtonLabel {
}

.editImageMenu {
  position: absolute;
  top: 0;
  right: 10px;
}

.imageMenuContent {
  width: 200px;
  margin-top: 10px;
  margin-right: 8px;
  padding: 20px 25px;
  background-color: var(--white);
  border-radius: 5px;
  text-align: left;

  @media (--viewportSmall) {;
    width: 90vw;
    text-align: right;
  }
}

/* Edit Company Details Modal windows */

.previewDialogContainer {
  @apply --modalTransparentBackground;
  display: flex;
  justify-content: center;
}

.previewDialog {
  @apply --modalWhiteBackground;

  @media (--viewportSmall) {
    overflow-x: hidden;
    max-height: 95vh;
  }
}

.dialogHeader {
}

.closeButton {
  @apply --closeButton;
}

.previewDialogTitle {
  @apply --modalTitle;
  max-width: 400px;
}

.previewDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportSmall) {
    align-items: center;
  }
}

.previewDialogContent p {
  @apply --bodyStyle3;
  text-align: center;
}