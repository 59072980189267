@import '../../styles/propertySets.css';
.pageHeader{
  margin: 0pt;
  color: #000000;
  font-family: Raleway;
  font-size: 16pt;
  line-height: 14pt;
  font-weight: 500;
  margin-bottom: 22pt;
}

.emptyDataContainer{
  text-align: center;
  padding-top: 70pt;
  padding-bottom: 210pt;
 }
 .emptyDataImage{
   max-height: 57pt;
   max-width: 57pt;
   height: auto;
   width: auto;
   display: block;
   margin:0px auto;
 }
 .emptyDataTitle{
   color: #000000;
   font-family: Raleway;
   font-size: 14pt;
   line-height: 14pt;
   margin: 0pt;
   margin-top:7pt;
   font-weight: bold;
   text-align: center;
 }
 .emptyDataSubTitle{
   color: #000000;
   font-family: Lato;
   font-size: 11pt;
   line-height: 12pt;
   font-weight: normal;
   margin: 0pt;
   margin-top:7pt;
   text-align: center;
 }