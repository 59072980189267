@import '../../styles/propertySets.css';

.pageContent {
  min-height: 420px;
}

.pageHeaderText {
  @apply --titleStyle3;
  margin: 0 0 20px;
}

.requestsTable {
  width: 100%;
}

.requestsTable th {
  @apply --titleStyle6;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  padding: 20px 10px;
  background-color: var(--beigeMinus50);
}

.requestsTable tbody tr td {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  text-align: center;
  overflow-wrap: break-word;
  padding: 20px 10px;
}

.requestsTable tbody tr {
  border-bottom: 1px solid var(--beigeMinus50);
}

.requestsTable tbody tr:last-child {
  border-bottom: none;
}

.requestButton {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  color: var(--bluePlus35);
  text-align: center;
  text-decoration: underline;
}

.requestButton:hover {
  color: var(--blue);
}

.previewDialogContainer {
  @apply --modalTransparentBackground;
}

.previewDialog {
  @apply --modalWhiteBackground;
}

.previewSmallDialog {
  @apply --modalWhiteBackgroundSmall;
}

.previewDialogTitle {
  @apply --modalTitle;
}

.previewDialogContent {
  @apply --modalSubtitle;
}

.previewDialogButtons {
  text-align: center;
  margin-top: 30px;
}

.previewDialogGroups {
  display: flex;
}

.propertiesColumn {
  flex: 1;
  margin: 0 20px;
}

.propertyField {
}

.propertyField h4 {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightBold);
  text-align: left;
  margin: 0 0 8px;
}

.propertyField h5 {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  text-align: left;
  margin: 8px 0 20px;
}

.previewDialogCancelButton {
  @apply --closeButton;
}

.previewDialogConfirmButton {
  @apply --primaryBlackbeanButtonStyles;
}

.rejectCommentTextarea {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  box-sizing: border-box;
  height: 160px;
  width: 100%;
  border: none;
  background-color: var(--beigeMinus50);
  padding: 10px;
}

.rejectCommentLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  text-align: left;
  margin: 0;
  margin: 20px 0 8px;
}