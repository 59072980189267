@import '../../styles/propertySets.css';


.root {
  background-color: var(--beigePlus90);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 60px 50px;
  border-bottom: 1px solid var(--black);
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px 40px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.growWholesaleBusinessSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px 0 20px;
  }
}

.sectionTitle { 
  @apply --titleStyle1;
  color: var(--beige);
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin: 20px 0;
  }
}

.inlineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
  }
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.blockNumber {
  @apply --titleStyle7;
  font-weight: 200;
  font-size: 75px;
  color: var(--beige);
  margin-top: 10px;

  @media (--viewportSmall) {
    font-size: 80px;
    width: 18%;
  }
}

.blockText {
  @apply --bodyStyle1;
  color: var(--beige);
  max-width: 300px;
  text-align: left;
  margin-left: 10px;
  
  @media (--viewportSmall) {
    width: 82%;
  }
}

.signUpSection {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.signUpButton {
  @apply --callToActionButtonStyles;
}

.moreInfo {
  @apply --bodyStyle3;
  color: var(--beige);
  text-align: center;
  margin: 25px 0 10px;
}

.loginLink {
  @apply --bodyStyle3;
  color: var(--beige);
  margin-left: 5px;
  text-decoration: underline;
}

.loginLink:hover {
  color: var(--beigePlus40);
}