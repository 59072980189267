@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.backgroundContainer {
  background-color: var(--beigeMinus70);
  border-top: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    width: 100vw;
    border-top: none;
  }
}

.mainContainer { 
  background-color: var(--beigeMinus70);
  height: 660px;
  width: 100%;
  max-width: 1280px;
  text-align: center;
  @apply --titleStyle1;
  padding-top: 220px;

  @media (--viewportSmall) {
    width: 100vw;
    padding-top: 150px;
    height: 450px;
  }
}

.mainContainerText {
  width: 450px;
  margin: 0 auto 10px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.loginButton {
  @apply --secondaryBlackbeanButtonStyles;
}