@import '../../styles/marketplaceDefaults.css';
@import '../../styles/propertySets.css';

.root {
  width: 100%;
  position: sticky;
  z-index: var(--zIndexTopbar);
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 100vw;
   }
}
