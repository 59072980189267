@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Main page section */

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 50px 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.productListingsSection {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  min-height: 460px;

  @media (--viewportSmall) {
    width: 90vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Tab section */

.tabs {
  @apply --titleStyle5;
  width: 180px;
  margin: 0 0 50px 0;

  @media (--viewportSmall) {
    margin: 15px 0 0 0;
    width: 100%;
    text-align: center;
  }
}

.tabs .NamedLink_active {
  font-weight: var(--fontWeightSemiBold);
  background-image: none;
}

.tabs .tab {
  margin-bottom: 25px;

  @media (--viewportSmall) {
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
  }
}

/* Notification bar */

.notificationContainer {
  position: relative;
  background-size: cover;
  background-position: center center;
  min-width: 1280px;
  width: 100%;
  height: 50px;

  @media (--viewportSmall) {
    min-width: unset;
    height: auto;
  }
}

.notificationMessageWrapper {
  margin: 10px auto;
  border-radius: 3px;
  background-color: var(--green);
  text-align: center;
  width: 300px;
}

.notificationMessageWrapper p {
  padding: 5px 15px;
  @apply --bodyStyle3;
  color: var(--white);
  text-align: center;
}

/* Continue: Main page section */

.pageContent {
  @apply --darkBorder;
  margin: 0 0 50px;
  padding: 30px;
  flex: 1;
  display: flex;

  @media (--viewportSmall) {
    margin: 0;
    width: 100%;
    padding: 10px 0 30px;
    border: none;
  }
}

.productTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.productTable th, 
.filter {
  @apply --titleStyle7;
  font-size: 14px;
  line-height: 2;
  text-align: center;
  padding: 30px 10px;
  background-color: var(--beigeMinus50);
}

.productTable a:hover, 
.filter:hover {
  color: var(--darkGrey);
}

.productTable th:first-child,
.productTable td:first-child {
  width: 70px;
}

.productTable th:nth-child(2),
.productTable td:nth-child(2) {
  width: 200px;
}

.productTable th:nth-child(3),
.productTable td:nth-child(3),
.productTable th:nth-child(4),
.productTable td:nth-child(4),
.productTable th:nth-child(5),
.productTable td:nth-child(5),
.productTable th:nth-child(6),
.productTable td:nth-child(6),
.productTable th:nth-child(7),
.productTable td:nth-child(7) {
  width: 155px;
}

.productTable td:nth-child(5) {
  padding: 0 20px;
}

.productTable th:nth-child(3),
.productTable td:nth-child(3),
.productTable th:nth-child(4),
.productTable td:nth-child(4),
.productTable th:nth-child(5),
.productTable td:nth-child(5),
.productTable th:first-child,
.productTable th:nth-child(7) {

  @media (--viewportSmall) {
    display: none;
  }
}

.productTable tbody tr td {
  @apply --bodyStyle3;
  text-align: center;
  overflow-wrap: break-word;

  @media (--viewportSmall) {
    text-align: left;
    padding: 3px;
    font-size: 14px;
  }
}

.productTable tbody tr {
}

.productTable tbody tr:last-child {
  border-bottom: none;
}

.productTable .imageColumn {
  width: 60px;
  height: 60px;
}

.sortIcon {
  display: inline-block;
  width: 14px;
  height: 18px;
  background-image: url('../../assets/chevron-expand-000000.svg');
  background-size: 18px 18px;
  background-position: center center;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
}

/* "Status" filter */

.filterIcon {
  display: inline-block;
  width: 14px;
  height: 18px;
  background-image: url('../../assets/chevron-down-000000.svg');
  background-size: 14px 14px;
  background-position: center center;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
}

.filterContent {
  width: 150px;
  margin-top: 5px;
  margin-right: 8px;
  padding: 20px 25px;
  background-color: var(--white);
  border-radius: 5px;
}

.filterStateItem {
  @apply --bodyStyle3;
  text-transform: none;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  cursor: pointer;

  @media (--viewportSmall) {
    text-align: right;
  }
}

.filterStateItem:hover {
  color: var(--darkGrey);
 }

.productImage {
  width: 60px;
  height: 60px;
  background-size: cover;
  border: 1px solid var(--black);
  border-radius: 5px;
  background-position: center center;
  margin: 5px auto;
  font-size: 0;

  @media (--viewportSmall) {
    width: 50px;
    height: 50px;  
  }
}

.defaultImage {
  width: 60px;
  height: 60px;
  padding: 10px;
  border: 1px solid var(--black);
  border-radius: 5px;

  @media (--viewportSmall) {
    width: 50px;
    height: 50px;  
  }
}

.state {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  color: var(--black);
  border: 1px solid var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  height: 30px;
  margin: auto 10px;

  @media (--viewportSmall) {
    font-size: 9px;
    padding: 9px 5px;
  }
}

.draft {
  background-color: var(--draft);
}

.pending {
  background-color: var(--pending);
}

.published {
  background-color: var(--approved);
}

.outOfStock {
  background-color: var(--outOfStock);
}

.manageListingMenuLabel {
  @apply --secondaryBlackbeanButtonStyles;
  margin-left: 10px;

  @media (--viewportSmall) {
    background-position: center center;
    background-image: url('../../assets/pencil-000000.svg');
    background-size: 18px 18px;
    background-color: var(--white);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
}

.manageListingMenuIsOpen {
}

.manageListingMenuContent {
  width: 200px;
  margin-top: 15px;
  margin-right: 0;
  padding: 20px 25px;
  background-color: var(--white);
  border-radius: 5px;
}

.menuItem {
  @apply --bodyStyle3;
  margin: 0;
  padding: 8px 0;
  text-align: left;
  cursor: pointer;
  position: relative;

  @media (--viewportSmall) {
    text-align: right;
  }
}

.menuItem:hover {
  text-decoration: none;
  color: var(--darkGrey);
}

.menuItem.disabled {
  color: var(--grey);
  pointer-events: none;
}

/* Confirmation modal window */

.confirmDialogContainer {
  @apply --modalTransparentBackground;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.confirmDialog {
  @apply --modalWhiteBackgroundSmall;
}

.closeXButtonWrapper {
  @apply --closeButton;
}

.confirmDialogTitle {
  @apply --modalTitle;
}

.confirmDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @apply --bodyStyle3;
  text-align: center;
}

.confirmDialogButtons {
  display: flex;
  justify-content: center;
  margin: 40px auto 20px;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
    margin: 30px auto 20px;
  }
}

.confirmDialogCancelButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin-right: 20px;

  @media (--viewportSmall) {
    margin: 20px 0 0;
  }
}

.confirmDialogConfirmButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    order: -1;
  }
}

/* Set up payout modal window */

.previewDialogContainer {
  @apply --modalTransparentBackground;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.previewSmallDialog {
  @apply --modalWhiteBackground;
}

.dialogHeader {
}

.closeButton {
  @apply --closeButton;
}

.previewDialogTitle {
  @apply --modalTitle;
  max-width: 400px;
}

.previewDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.previewDialogContent p {
  @apply --bodyStyle3;
  text-align: center;
}

.setupPayoutButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 30px auto 20px;
}

.setupPayoutGoBackButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 auto 20px;
}

/* Empty state */

.emptyDataContainer {
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
}
 
.emptyDataContainer > img {
  height: 35px;
  width: 35px;
  margin-bottom: 15px;
}
 
.emptyDataTitle {
  @apply --bodyStyle1;
  color: var(--darkGrey);
  margin-bottom: 10px;
  margin-top: 0;
}

.emptyDataSubTitle {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin-top: 0;
  margin-bottom: 25px;
}

.addProductButton {
  @apply --primaryBlackbeanButtonStyles;
}

.addProductButton img {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.addProductIconWhite {
  width: 30px;
  height: 30px;
}