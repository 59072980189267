@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.pageContainer {
  background: var(--white);
  background: linear-gradient(0deg, var(--white) 0%, var(--beigeMinus70) 35%, var(--beigeMinus50) 100%);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  min-height: 650px;
  justify-content: center;
  padding: 50px 50px 80px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 20px 15px 40px;
    width: 100vw;
    margin-top: 0;
  }
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 95%;
  }
}

.pageText {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
}

.pageTitle {
  @apply --titleStyle1;
  margin: 0 10px 20px 0;
  max-width: 650px;
  text-align: center;

  @media (--viewportSmall) {
    max-width: 95%;
    margin: 20px 0 15px;
    text-align: left;
  }
}

.pageSubtitle {
  @apply --bodyStyle2;
  margin: 10px 0;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

.pageSubtitle a {
  text-decoration: underline;
}

.facts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 50px auto;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
    max-width: 95%;
    margin: 10px auto;
  }
}

.singleFactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    margin: 20px 10px 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--black);
  }
}

.singleFactSection:last-child {
  @media (--viewportSmall) {
    border-bottom: hidden;
    padding-bottom: 10px;
  }
}

.factSectionTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  text-align: left;
  margin: 10px 0;

  @media (--viewportSmall) {
    margin: 0 0 10px;
    text-align: center;
  }
}

.factImage {
  max-width: 280px;
  border: 1px solid var(--black);
  border-radius: 150px;
  margin: 10px auto;

  @media (--viewportSmall) {
    width: calc(100vw/1.8);
    flex-basis: 100%;
  }
}

.factHighlight {
  @apply --titleStyle1;
  font-weight: var(--fontWeightBold);
  margin: 5px 0;
}

.factText {
  @apply --bodyStyle2;
  font-weight: var(--fontWeightMedium);
  margin: 0 auto;
  text-align: center;

  @media (--viewportSmall) {
    font-size: 18px;
  }
}

.factSource {
  @apply --bodyStyle5;
  margin: 20px auto 0;
  text-align: center;
}