@import '../../styles/propertySets.css';

/* NOT USED 
Styles for "Payment method" section of Buyer's Payment details page" */

.pageContainer {
  margin-bottom: 20pt;
}

.pageMode {
  padding: 50px;
  background-color: #ffffff;
  border: 1px solid #E9EAEC;
}

.companyPageInfo {
  color: #000000;
  font-family: Raleway, sans-serif;
  font-size: 14pt;
  font-weight: bold;
  margin: 0;
}

.addCardFormContainer {
  max-width: 600px;
  padding: 15px 0;
}

.cardInfo h4 {
  color: #000000;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin-top: 25pt;
  margin-bottom: 5pt;
  font-weight: bold;
}

.cardDetails {
  padding: 3pt 7pt;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  border: 1px solid #F0EBE4;
  width: 270pt;
  margin-bottom: 7pt;
  color: #A3A09B;
  font-family: Lato, sans-serif;
  font-size: 13pt;
}

.cardNumber {
  flex: 1;
  margin-left: 7pt;
}

.cardButtons {
  margin-top: 20pt;
  display: flex;
  justify-content: left;
}

.changeCardButton {
  display: inline-block;
  background-color: #3F5CDB;
  border: none;
  padding: 7pt 18pt;
  color: #FFFFFF;
  font-family: Lato, sans-serif;
  font-size: 11pt;
  line-height: 12pt;
  text-align: center;
  border-radius: 15pt;
  margin-left: 5pt;
  cursor: pointer;
}

.changeCardComponentModeButton {
  display: inline-block;
  background-color: #ffffff;
  padding: 7pt 18pt;
  color: #000000;
  border: 2px solid #3F5CDB;
  font-family: Lato, sans-serif;
  font-size: 11pt;
  line-height: 12pt;
  text-align: center;
  border-radius: 15pt;
  cursor: pointer;
}

.removeCardButton {
  display: inline-block;
  background-color: #ffffff;
  padding: 7pt 18pt;
  color: #000000;
  border: 2px solid #3F5CDB;
  font-family: Lato, sans-serif;
  font-size: 11pt;
  line-height: 12pt;
  text-align: center;
  border-radius: 15pt;
  cursor: pointer;
}

.removeCardButton:disabled {
  background-color: transparent;
  color: #A3A09B;
  border: 2px solid #A3A09B;
  cursor: default;
}

.previewDialogContainer {
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000034;
  text-align: center;
  overflow: auto;
}

.previewDialog {
  margin: 80pt auto;
  background-color: #F9F4EC;
  width: 610pt;
  display: inline-block;
  padding: 45pt;
  position: relative;
}

.previewSmallDialog {
  margin: 80pt auto;
  background-color: #F9F4EC;
  width: 410pt;
  display: inline-block;
  padding: 45pt;
}

.previewDialogTitle {
  color: #000000;
  font-family: Raleway, sans-serif;
  font-size: 14pt;
  font-weight: 500;
  text-align: center;
  line-height: 14pt;
  margin: 0 0 17pt;
}

.previewDialogContent {
  color: #000000;
  font-family: Lato, sans-serif;
  font-size: 11pt;
  line-height: 12pt;
  margin: 0;
  text-align: center;
}

.previewDialogButtons {
  text-align: center;
  margin-top: 20pt;
}

.previewDialogCancelButton {
  display: inline-block;
  box-sizing: border-box;
  border: 2pt solid #3F5CDB;
  background-color: #ffffff;
  color: #000000;
  font-family: Lato, sans-serif;
  font-size: 9pt;
  text-align: center;
  line-height: 13pt;
  border-radius: 22pt;
  margin-top: 7pt;
  padding: 4px 11px;
  cursor: pointer;
  margin-right: 17pt;
}

.previewDialogConfirmButton {
  display: inline-block;
  box-sizing: border-box;
  background-color: #3F5CDB;
  color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 9pt;
  text-align: center;
  line-height: 13pt;
  border: none;
  border-radius: 22pt;
  margin-top: 7pt;
  padding: 4px 11px;
  cursor: pointer;
}

.previewDialogConfirmButton:hover,
.previewDialogCancelButton:hover {
  text-decoration: underline;
}

.closeButton {
  box-sizing: border-box;
  background-color: #F9F4EC;
  color: #000000;
  height: 25px;
  width: 25px;
  position: absolute;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background-image: url('../../assets/x-000000.svg');
  background-size: 22px 22px;
  background-position: center center;
  top: 15px;
  right: 15px;
}

.cardIcons {
  background: url('../../assets/visa-master-american-express.png') center center no-repeat;
  background-size: cover;
  height: 25px;
  width: 145px;
  margin: 0 auto 20px;
}