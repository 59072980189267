@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Topbar message section */

.topbarMessageContainer {
  width: 100%;
  text-align: center;
  background-color: var(--beige);
  padding: 15px;

  @media (--viewportSmall) {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.topbarMessage {
  @apply --titleStyle7;
  font-size: 12px;
  word-spacing: 2px;
  color: var(--beige);
  line-height: 0;

  @media (--viewportSmall) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: var(--beige);
    width: 90vw;
    text-align: center;
    line-height: 1.25;
    font-size: 10px;
  }
}

/* Hero section */

.pageHeaderContainer {
  background-color: var(--beige);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: space-between;
  padding: 0 50px 50px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);
  
  @media (--viewportSmall) {
    padding: 0 15px 0px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.logoIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  
  @media (--viewportSmall) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 15px;
  }
}

.logo {
  height: 40px;
  margin-top: 22px;
  width: auto;
  max-width: 160px;
  object-fit: contain;
  object-position: left center;
}

.pageHeaderSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: 10px auto 30px;
    
  @media (--viewportSmall) {
    flex-wrap: wrap;
    max-width: 90vw;
    margin: 20px auto 30px;
  }
}

.sectionMainTitle {
  display: flex;
  @apply --titleStyle1;
  color: var(--black);
  text-align: center;
  margin: 10px auto;
  max-width: 1000px;

  @media (--viewportSmall) {
    margin: 0 auto;
  }
}

.joinUsButtonContainer {
  text-align: center;
  margin: 20px auto 10px;
}

.joinUsButton {
  display: inline-block;
  @apply --callToActionButtonStyles;
}

.joinUsText {
  @apply --bodyStyle3;
  color: var(--beigePlus90);
  text-align: center;
  margin-top: 20px;
}

.joinUsLink {
  @apply --bodyStyle3;
  color: var(--beigePlus90);
  margin-left: 5px;
  text-decoration: underline;
}

/* ABOUT US - What we're building, Mission, Vision */

.aboutUsContainer {
  border-bottom: 1px solid var(--black);
  background-color: var(--beigePlus30);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    width: 100vw;
    justify-content: center;
  }
}

.aboutUsContainerOne {
  background-image: url('../../assets/woman-browsing-vegshelf-marketplace.png');
  background-size: cover;
  width: 50%;
  height: 450px;
  border-right: 1px solid var(--black);
  background-position: center center;

  @media (--viewportSmall) {
    width: 100%;
    border-bottom: 1px solid var(--black);
    border-right: none;
    max-height: 300px;
  }
}

.aboutUsContainerTwo {

  @media (--viewportSmall) {
    margin: 0;
  }
}

.aboutUsSectionText {
  display: flex;
  flex-direction: column;
  padding: 50px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    padding: 0 15px 0px;
  }
}

.aboutUsSectionTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  margin-bottom: 10px;
  max-width: 600px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 30px auto 10px;
    text-align: center;
  }
}

.aboutUsSectionSubtitle {
  @apply --bodyStyle2;
  color: var(--beigePlus90);
  text-align: left;
  margin: 10px 0 40px;
  max-width: 600px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 10px auto;
    text-align: center;
  }
}

.aboutUsSectionSubtitle:last-child {
  margin-bottom: 0;

  @media (--viewportSmall) {
    margin-bottom: 30px;
  }
}

/* Investors & Supporters section */

.investorsContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 50px 50px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
  }
}

.investorsTitle {
  @apply --titleStyle1;
  color: var(--beigePlus90);
  text-align: center;
  margin: 10px auto;
  max-width: 1000px;
}

.investors {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1280px;
  flex-wrap: wrap;
}

.singleInvestor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 290px;
  margin: 0 20px;

  @media (--viewportSmall) {
    flex-basis: 30%;
    margin: 0 10px;
  }
}

.investorLogo1, .investorLogo2, .investorLogo3, 
.investorLogo4, .investorLogo5, .investorLogo6, .investorLogo7 {
  max-height: 130px;
  max-width: 130px;
}

/* Founders */

.foundersContainer {
  background: var(--white);
  background: linear-gradient(180deg, var(--white) 0%, var(--beigeMinus70) 35%, var(--beigeMinus50) 100%);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 40px 15px;
    width: 100vw;
  }
}

.foundersSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 95%;
  }
}

.foundersTextandImageSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
}

.foundersText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 55%;
  margin-right: 30px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
    max-width: 100%;
    margin-right: 0;
    text-align: center;
  }
}

.foundersTitle {
  @apply --titleStyle1;
  margin-top: 0;
  margin-bottom: 20px;
}

.foundersSubtitle {
  @apply --bodyStyle2;
  margin-top: 0;
  margin-bottom: 0;
}

.foundersImage {
  max-width: 450px;
  max-height: 450px;
  border: 1px solid var(--black);
  border-radius: 150px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0;

  @media (--viewportSmall) {
    width: 95%;
    margin: 0 auto;
  }
}

/* EACH FOUNDER SECTION */

.eachFounderContainer {
  background: var(--white);
  background: linear-gradient(0deg, var(--white) 0%, var(--beigeMinus70) 35%, var(--beigeMinus50) 100%);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 25px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.eachFounderSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 95%;
  }
}

.baibaSection, .marcisSection {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-bottom: 15px;

  @media (--viewportSmall) {
    max-width: 100%;
  }
}

.baibaImage, .marcisImage {
  max-width: 150px;
  max-height: 150px;
  border: 1px solid var(--black);
  border-radius: 100%;
  margin: 0 auto;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.founderTexts {
  margin: 10px 50px;

  @media (--viewportSmall) {
    margin: 10px 0;
  }
}

.founderTitle {
  @apply --titleStyle7;
  margin-bottom: 0;
  text-align: center;
  margin: 0;
}

.founderPosition {
  @apply --titleStyle7;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
  margin: 0;
}

.founderSubtitle {
  @apply --bodyStyle2;
}

.founderQuote {
  @apply --titleStyle3;
  font-size: 20px;
  margin: 40px 0 0;

  @media (--viewportSmall) {
    font-size: 16px;
    margin: 20px 0 0;
  }
}

.linkedinLogoButton {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px auto;
}

.linkedinLogo {
  background-image: url('../../assets/linkedin-000000.svg');
  height: 30px;
  width: 30px;
  background-size: 24px 24px;
  background-position: center center;
  display: inline-block;
}

/* Media & Awards section */

.mediaAwardsContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 50px 50px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
   }
}

.mediaAwardsTitle {
  @apply --titleStyle1;
  color: var(--beigePlus90);
  text-align: center;
  margin: 10px auto;
  max-width: 1000px;
}

.mediaAwards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1280px;
  flex-wrap: wrap;
}

.singleMediaAward {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 290px;
  margin: 0 20px 20px;

  @media (--viewportSmall) {
    flex-basis: 30%;
    margin: 20px 10px 0;
    padding-bottom: 15px;
  }
}

.mediaAwardLogo1, .mediaAwardLogo2, .mediaAwardLogo3, 
.mediaAwardLogo4 {
  max-height: 130px;
  max-width: 130px;

  @media (--viewportSmall) {
    max-height: 100px;
    max-width: 100px;
  }
}