@import '../../styles/propertySets.css';

.root {
  @apply --bodyStyle4;
}

.bottomWrapper {
  text-align: center;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.bottomWrapperText {
  width: 100%;
  margin-top: 10px;
}

.modalHelperLink {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-decoration: underline;
}

.modalHelperText {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  width: 300px;
  text-align: left;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 30px auto 0;
}

.formFields {
  margin-top: 0;
}

.formRow {
  text-align: center;
}

.formField {
  max-width: 300px;
  text-align: left;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
    margin: 0;
  }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  display: block;
  position: relative;
  margin-bottom: 7px;
}

.requiredMarker {
  color: var(--red);
}

.errorMessage {
  @apply --bodyStyle3;
  color: var(--red);
  position: absolute;
  width: 350px;
  padding-left: 7px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.errorTextBlock {
  @apply --bodyStyle3;
  color: var(--red);
  max-width: 350px;
  margin: 5px auto;

  @media (--viewportSmall) {
    width: 80vw;
    margin: 0;
  }
}

.fieldInputContainer {
  
  @media (--viewportSmall) {
    width: 80vw;
  }
}