@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .required {
    stroke: #000000;
  }

  &:checked + label .notChecked {
    stroke: none;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: #000000;
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkedStyle {
  stroke: #F9F1A1;
  fill: #F9F1A1;
}

.checked {
  display: none;
}

.notChecked {
  stroke: #b2b2b2;
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: #ffaa00;
  &:hover {
    stroke: pink;
  }
}

.text {
  @apply --bodyStyle3;
  color: #000000;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
