@import '../../styles/propertySets.css';

.root {
}

.verificiationBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  margin-bottom: 40px;
  border-radius: 3px;
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.verificationBoxTitle {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
}

.verificationBoxText {
  margin-top: 2px;
  @apply --bodyStyle4;
  font-weight: var(--fontWeightRegular);
}

.marginLeft {
  margin-left: 5px;
}

/* Verification required box */
.verficiationNeededBox {
  border: 1px solid var(--orange);
  background: var(--lightOrange);
}

.getVerifiedButton {
  @apply --attentionOrangeButtonStyles;
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--white);
  stroke-width: 3px;
}

/* Verification error box */
.verficiationErrorBox {
  border: 1px solid var(--red);
  background: var(--lightRed);
}

/* Verification success box */
.verficiationSuccessBox {
  display: flex;
  align-items: center;
  padding: 0;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.verficiationSuccessBox .verificationBoxTitle {
  color: var(--green);
}

.successIconBk {
  fill: transparent;
  stroke: var(--white);
}

.verificationBoxSuccessTextWrapper {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  color: var(--green);
  margin-bottom: 0;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.editVerificationButton {
  display: flex;
  align-items: center;
  @apply --bodyStyle3;
  margin-left: 20px;

  @media (--viewportSmall) {
    margin: 0;
    padding: 0;
  }
}

.editVerificationButton:hover {
  color: var(--grey);
}

.editVerificationButtonIcon {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  background-color: var(--white);
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 14px 14px;
  font-size: 0;
  margin-right: 2px;
}

.icon {
  margin-right: 10px;
}

.unsetBtnStyles button {
}
