@import '../../styles/propertySets.css';

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: #ffffff;

  /* shadows */
  box-shadow: var(--boxShadow);
}

.home {
  display: flex;
  padding: calc((88pt - 26px) / 2) 24px;
}

.logoMobile {
  display: block;
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: #e7e7e7; /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

}

.avatarMobile {
}

.heading {
  margin-top: 29px;
  margin-bottom: 34px;
  padding: 0 24px;
}

.title {
  /* Font */
  @apply --bodyStyle3;
  color: #000000;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.author {
  width: 100%;
  @apply --bodyStyle3;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;
}

.priceBreakdownTitle {
  /* Font */
  color: #b2b2b2;

  margin-top: 0;
  margin-bottom: 14px;
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 35px;
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;
}

.orderError {
  color: #F9652A;
}

.notFoundError {
  color: #ffaa00;
}

.speculateError {
  color: #F9652A;
  margin: 19px 0 1px 0;
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: #e7e7e7; /* Loading BG color */
}

.detailsHeadings {
  display: none;
}

.detailsTitle {
  margin-bottom: 10px;
}

.detailsSubtitle {
  @apply --bodyStyle3;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;
}

.bookingBreakdownTitle {
  /* Font */
  color: #b2b2b2;

  /* Layout spacing */
  margin: 5px 24px 25px 24px;
}

.bookingBreakdown {
}