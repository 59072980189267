@import '../../styles/propertySets.css';

.root {
}

.input {
  @apply --input;
}

.input:read-only,
.input:read-only:hover,
.input:read-only:focus {
  @apply --inputReadOnly;
  cursor: default;
  pointer-events: none;
}

.inputSuccess {
  @apply --inputSuccess;
}

.inputError {
  @apply --inputError;
}

.validatorMessage {
  display: none;
}
