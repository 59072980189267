@import '../../styles/propertySets.css';

.root {
    overflow-x: hidden;

    @media (--viewportSmall) {
        width: 100vw;
        max-width: 767px;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/* Featured This Week section */

.topProductsContainer {
    background-color: var(--beigeMinus70);
    border-bottom: 1px solid var(--black);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 330px;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 20px 50px;
    margin-top: 160px;

    @media (--viewportSmall) {
        padding: 30px 15px;
        height: auto; 
        flex-wrap: wrap;
        margin-top: 0;
    }
}

.topProducts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1380px;

    @media (--viewportSmall) {
        width: 88vw;
        flex-direction: column;
    }
}

.topProductInfo {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    margin-right: 50px;
    justify-content: center;
    align-items: flex-start;

    @media (--viewportSmall) {
        margin: 0 auto 15px;
        align-items: center;
    }
}

.featuredThisWeekLabel {
    @apply --titleStyle2;
    font-size: 38px;
    color: var(--beigePlus80);
    margin: 0;

    @media (--viewportSmall) {
        font-size: 24px;     
    }
}

.featuredThisWeekAllCategoriesLabel {
    @apply --titleStyle2;
    font-size: 38px;
    color: var(--beigePlus80);
}

.inCategoryLabel {
    @apply --titleStyle2;
    font-size: 38px;
    color: var(--beigePlus80);
    font-style: italic;
    margin: 0;

    @media (--viewportSmall) {
        font-size: 24px;
    }
}

.topProductContainer {
    display: flex;
    overflow-x: auto;
    justify-content: space-between;
    flex: 1;
}

.topProductItem {
    margin: 10px;

    @media (--viewportSmall) {
        margin: 0 10px;
    }
}

.topProductImage {
    height: 230px;
    width: 230px;
    border: 1px solid var(--black);
    border-radius: 100%;
    background-size: cover;
    background-position: center center;

    @media (--viewportSmall) {
        height: 40vw;
        width: 40vw;
        max-height: 230px;
        max-width: 230px;
    }
}

.topProductTitle {
    @apply --titleStyle6;
    font-weight: var(--fontWeightMedium);
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    cursor: pointer;
    margin: 10px auto 5px;
}

.topProductCountry {
    @apply --titleStyle7;
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    overflow-wrap: break-word;
    hyphens: auto;
    margin: 0;

    @media (--viewportSmall) {
        margin-bottom: 20px;
    }
}

.swiperButtonContainer {
    display: none;

    @media (--viewportSmall) {
        display: flex;
        flex-direction: row;
        padding: 30px 0 0;
        justify-content: center;
    }
}

.leftButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 30px;
}

.leftButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.rightButton { 
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
}

.rightButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

/* Product Grid View */

.pageContentContainer {
    background-color: var(--white);
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;

    @media (--viewportSmall) {
        padding: 30px 15px 0;
        width: 100vw;
        min-height: 500px;
        align-items: space-between;
    }
}

.pageContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1380px;

    @media (--viewportSmall) {
        flex-wrap: wrap;
        width: 90vw;
  }
}

/* Filter section */

.productFilterSection {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: space-between;

    @media (--viewportSmall) {
        display: none;
    }
}

.filterMobileMenu {
    display: none;

    @media (--viewportSmall) {
        display: inline-block;
        border-width: 0;
        margin: 10px auto 0;
    }
}

.productFilterSectionMobile {
    display: none;

    @media (--viewportSmall) {
      max-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      margin: 0 0 20px;
    }
}

.filtersButtonText { 
    display: none;
  
    @media (--viewportSmall) {
        display: inline-block;
        @apply --secondaryBlackbeanButtonStyles;
    }
}

.mobileFilterModalContainer {
    @apply --modalTransparentBackground;
    display: flex;
    justify-content: center;
}

.mobileFilterModalWindow {
    @apply --modalWhiteBackground;

    @media (--viewportSmall) {
        overflow-x: hidden;
        height: 95vh;
    }
}

.closeButton {
    @apply --closeButton;
}

.arrow {
    display: inline-block;  
    width: 14px;
    height: 10px;
    background-image: url('../../assets/chevron-down-000000.svg');
    background-size: 14px 14px;
    cursor: pointer;
    margin-right: 11px;
}

.up {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    background-position: center bottom;
}

.down {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    background-position: center top;
}

.filterFieldItem {
}

.filterName {
    @apply --titleStyle7;
    font-size: 14px;
    margin: 20px auto 10px;
}

.filterOption {
    margin-bottom: 10px;
    display: flex;

    @media (--viewportSmall) {
        align-items: center;
    }
}

.filterOption label {
   @apply --bodyStyle4;
   cursor: pointer;
   width: 150px;
   align-items: center;
   margin-bottom: 0;

   @media (--viewportSmall) {
    width: auto;
   }
}

.filterOption input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
}

.filterOption input:checked~Label {
    font-weight: var(--fontWeightSemiBold);
}

.seeMoreButton {
    @apply --titleStyle7;
    font-size: 10px;
    color: var(--darkGrey);
    display: block;
    margin-bottom: 10px;
}

/* Product Grid Section */

.productsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    width: 100%;
    position: relative;

    @media (--viewportSmall) {
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.productsHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (--viewportSmall) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
}

.productsHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--viewportSmall) {
        flex-direction: column;
    }
}

.productsHeader {
    display: flex;
    flex-direction: column;
    max-width: 480px;

    @media (--viewportSmall) {
        align-items: center;
    }
}

.containerTitle {
    @apply --titleStyle2;
    margin-bottom: 0;
    margin-top: 5px;
}

.categoryDescription {
    @apply --bodyStyle4;
}

.containerSubTitle {
    @apply --titleStyle7;
    font-size: 13px;

    @media (--viewportSmall) {
        @apply --titleStyle8;
        word-spacing: 0;      
    }
}

/* CLEAR ALL FILTERS BUTTON & SORT BY FUNCTION */

.clearFiltersAndSortByContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (--viewportSmall) {
        flex-direction: column;
        justify-content: center;
        width: 90vw;
        margin-bottom: 20px;
    }
}

.filterClearButton {
    display: flex;
    @apply --secondaryBlackbeanButtonStyles;
    @apply --titleStyle7;
    font-size: 13px;
    margin-right: 15px;
    padding: 6px 20px;
    height: 30px;

    @media (--viewportSmall) {
        margin: 5px 0 20px;
    }
}

.filterClearButtonIcon {
    background-position: center center;
    background-image: url('../../assets/x-000000.svg');
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    font-size: 0;
    margin-right: 5px;
    cursor: pointer;
}

.filterClearButtonIconDisabled {
    background-position: center center;
    background-image: url('../../assets/x-a3a3a3.svg');
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    font-size: 0;
    margin-right: 5px;
    cursor: default;
}

.sortbyButtonContainer {
    display: flex;
}

.sortByName {
    @apply --titleStyle7;
    font-size: 13px;
    max-width: 130px;
    cursor: default;
    text-align: right;

    @media (--viewportSmall) {

    }
}

.filterMenuLabel {
    @apply --titleStyle7;
    font-size: 13px;
    display: flex;
    align-items: center;

    @media (--viewportSmall) {
        justify-content: center;
    }
}

.filterMenuLabel:active,
.filterMenuLabel:focus {
    border: none;
}

.sortByFilterOptions {
    width: 65px;
    text-align: right;
}

.filterArrow {
    display: inline-block;
    width: 14px;
    height: 10px;
    background-image: url('../../assets/chevron-down-000000.svg');
    background-size: 14px 14px;
    background-position: center center;
    cursor: pointer;
    margin-left: 5px;
}

.filterMenuContent {
    width: 120px;
    margin-top: 10px;
    margin-right: 0;
    padding: 20px 25px;
    background-color: var(--white);
    border-radius: 5px;

    @media (--viewportSmall) {
        text-align: right;
    }
}

.menuItem {
    @apply --titleStyle7;
    font-size: 13px;
    cursor: pointer;
    text-align: left;
    position: relative;

    @media (--viewportSmall) {
        text-align: right;
        outline: none;
    }
}

.menuItem:not(:last-child) {
    margin-bottom: 21px;
}

.menuItem:hover {
    color: var(--darkGrey);
}

.productGrid {
    flex: 1;
}


/* SHOW ALL PRODUCTS SECTION */

.showAllProduct {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    margin: 30px 0 0;
    background-color: var(--beigePlus30);
    border-radius: 5px;

    @media (--viewportSmall) {
        padding: 10px;
        flex-wrap: wrap;
        padding: 30px 15px;
        height: auto;
        margin-bottom: 20px;
    }
}

.showAllProductTextContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 50px;

    @media (--viewportSmall) {
        align-items: center;
        padding: 15px;
        flex-grow: 1;
        flex-basis: 100%;
    }
}

.showAllTitle {
    @apply --titleStyle2;
    font-size: 38px;
    margin: 0 0 10px;

    @media (--viewportSmall) {
        text-align: center;
        font-size: 24px;     
    }
}

.showAllSubtitle {
    @apply --bodyStyle2;
    margin: 0 0 30px;

    @media (--viewportSmall) {
        text-align: center;
    }
}

.showAllButtonContainer {
    display: flex;
}

.showAllButton {
    margin: 0;
    @apply --secondaryBlackbeanButtonStyles;
}

.showAllProductImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 50px;

    @media (--viewportSmall) {
        padding: 15px;
        /*order: -1;*/
        flex-basis: 100%;
        flex-grow: 1;
        flex-wrap: wrap;
    }
}

.brandIcon {
    margin-top: 5px;
    height: 250px;

    @media (--viewportSmall) {
        margin-top: 0;
        height: 50vw;
    }
}

/* PRODUCT PAGER */

.productPager {
    margin: 30px 0 50px;
    padding-top: 30px;
    border-bottom: 1px solid var(--black);

    @media (--viewportSmall) {
        margin: 0 auto 10px;
        padding: 0;
    }
}

.categoryBottomDescriptionSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.bottomCategoryTitle {
    @apply --titleStyle3;
    margin-bottom: 0;
    margin-top: 30px;
}