@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.checkboxBorder {
  @apply --darkBorder;
  background-color: var(--white);
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
}

.tickMark {
  height: 18px;
  width: 18px;
  margin-bottom: 3px;
}
.hasError .checkboxBorder {
  @apply --errorBorder;
}
.checkboxBorder img {
  visibility: hidden;
}
.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
.input:checked + label .checkboxBorder img {
  visibility: visible;
}
.label {
  display: flex;
  align-items: center;
  padding: 0;
}
.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
