@import '../../styles/propertySets.css';

.pageRoot {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  border-top: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    width: 100vw;
    border-top: none;
  }
}

.root {
  background: url('../../assets/vegan-cheese-sausage-baked-goods.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  text-align: center;

  @media (--viewportSmall) {
    width: 100vw;
    background: none;
    height: 580px;
  }
}

.submitEmailContent,
.genericErrorContent,
.emailSubmittedContent {
  padding: 40px;
  width: 620px;
  height: 450px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFormBackground);
  border: 1px solid var(--black);
  margin: 40px auto;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 0 20px;
    box-shadow: none;
    background-color: var(--beigeMinus70);
    border: none;
  }
}

.modalTitle {
  @apply --titleStyle2;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.modalMessage {
  @apply --bodyStyle2;
  text-align: center;
  margin-top: 0;
  margin-bottom: 50px;
}

.form {
  margin: 60px auto;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.bottomWrapper {
  text-align: left;
}

.email {
  font-weight: var(--fontWeightRegular);
}

.helperLink {
  @apply --helperLink;
  text-align: center;
  border: none;
  color: var(--darkGrey);
  margin-bottom: 5px;
}

.helperLink:hover {
  cursor: pointer;
  color: var(--grey);
}

.helperText {
  @apply --helperText;
  text-align: center;
  color: var(--darkGrey);
  margin-bottom: 5px;
}