@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

/* Below items applicable only for open mobile topbar */

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  width: 100%;
  height: 100vh;
  overflow: visible;
  min-height: 100vh;
}

.actionLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.loginLink, 
.signupLink,
.registerToShopLink, 
.transitionItem {
  @apply --titleStyle7;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.shortLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.transitionItem:first-child {
  border-top: 1px solid var(--black);
  padding-top: 50px;
}

.transitionItem:last-child {
  margin-bottom: 50px;
}

.adminSectionsLink {
  @apply --titleStyle7;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.adminSectionsLink:nth-of-type(2) {
  border-bottom: 1px solid var(--black);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.profileSettingsLink {
  @apply --titleStyle7;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

/* Shopping Cart  pop-up*/

.shoppingButton {
  display: none;
  
  @media (--viewportSmall) {
    display: unset;
  }
}

.shoppingCartMainMenuLink {
  @apply --shoppingCartButton;
  display: inline-block;
  margin-top: 20px;
}

.shoppingCartIcon {
}

.shoppingCartNotification {
  font-size: 10px;
  color: var(--black);
  text-align: center;
  background-color: var(--lime);
  border: 1px solid var(--black);
  border-radius: 25px;
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px;
}

.addedProductNotification {
  display: none;
}

/* Language section */

.languageButtonContainer {
  border-top: 1px solid var(--black);
  padding-top: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.languageButtonContainer:hover {
  cursor: pointer;
}

.buttonEng, .buttonDe {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 10px;
  width: 40px;
  border-radius: 100%;
  padding: 10px;
}

/*
.languageButtonContainer button {
  @apply --titleStyle8;
  color: var(--darkGrey);
  border: none;
}

.languageButtonContainer button:hover {
  cursor: pointer;
  color: var(--grey);
}*/

/* ----- Do not delete section below as it may be useful for authorized users in the future --------- */
.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--white);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  flex-shrink: 0;
  margin: 96px 0 0 0;
}

.greeting {
  @apply --titleStyle3;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --primaryBlackbeanButtonStyles;
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.inbox {
  @apply --secondaryBlackbeanButtonStyles;
  position: relative;
  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  @apply --secondaryBlackbeanButtonStyles;
  margin-top: 0;
  margin-bottom: 11px;
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;
}

.notificationBadge:hover {
  text-decoration: none;
}

.createNewListingLink {
  @apply --primaryBlackbeanButtonStyles;
}

.currentPage {
  color: var(--black);
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}