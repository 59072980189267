@import '../../styles/propertySets.css';

.pageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emptyDataContainer {
  @apply --darkBorder;
  width: 1030px;
  height: 410px;
  text-align: center;

  @media (--viewportSmall) {
    width: 90vw;
    border: none;
  }
}

.pageHeader {
}