.input {
  border-bottom-color: #ffaa00;
}

.inputSuccess {
  border-bottom-color: #2ecc71;
}

.inputError {
  border-bottom-color: #F9652A;
}
