:root {
  /**
 * This file contains only Custom Sets of Properties (@apply-rule).
 * This was originally part of cssnext package and was an interesting
 * concept, because property sets would not suffer from specificity
 * problems that arise from creating a single CSS file on build step.
 *
 * However, W3C has abandoned this concept before it became an
 * official standard. It would be a good idea to reduce the usage.
 * http://tabatkins.github.io/specs/css-apply-rule/
 *
 * Property sets defined in this file are imported and injected to
 * correct places during PostCSS build step.
 *
 * Note: if you add plain CSS styles to this file,
 * you'll end up seeing it repeated in development mode on every
 * component that @imports this file.
 */

  /* ================ BUTTONS ================ */

  /* Primary Button */

  --primaryBlackbeanButtonStyles: { 
    box-sizing: border-box;
    height: 40px;
    border: 1px solid var(--black);
    border-radius: 26px;
    width: auto;
    background-color: var(--beigePlus90);
    text-align: center;
    padding: 10px 30px;
    color: var(--white);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.4;
    cursor: pointer;

    &:hover,&:focus {
      background-color: var(--black);
      color: var(--white);
      text-decoration: none;
      box-shadow: var(--boxShadowButtonBold);
    }

    &:disabled {
      background-color: var(--lightGrey);
      border: 1px solid var(--darkGrey);
      color: var(--grey);
      cursor: default;
      box-shadow: none;
    }

    @media (--viewportSmall) {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 1.5;
    }
  }

  /* Secondary Button */
  --secondaryBlackbeanButtonStyles: {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid var(--black);
    border-radius: 26px;
    width: auto;
    background-color: var(--white);
    text-align: center;
    padding: 10px 30px;
    color: var(--black);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.4;
    cursor: pointer;

    &:hover,&:focus {
      background-color: var(--white);
      border: 1px solid var(--black);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }

    &:disabled {
      border: 1px solid var(--lightGrey);
      color: var(--grey);
      cursor: default;
      box-shadow: none;
    }

    @media (--viewportSmall) {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 1.5;
    }
  }

  /* Call-to-action Button - Green */

  --callToActionButtonStyles: {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid var(--black);
    border-radius: 26px;
    width: auto;
    background-color: var(--greenAccent);
    text-align: center;
    padding: 10px 30px;
    color: var(--black);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.4;
    cursor: pointer;
        
    &:hover,&:focus {
      background-color: var(--greenAccentLight);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  
    &:disabled {
      background-color: var(--lightGrey);
      color: var(--grey);
      cursor: default;
      box-shadow: none;
    }

    @media (--viewportSmall) {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 1.5;
    }
  }


  --callToActionButtonStylesLime: {
    box-sizing: border-box;
    height: 40px;
    border: 1px solid var(--black);
    border-radius: 26px;
    width: auto;
    background-color: var(--lime);
    text-align: center;
    padding: 10px 30px;
    color: var(--black);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.4;
    cursor: pointer;
        
    &:hover,&:focus {
      background-color: var(--lightLime);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  
    &:disabled {
      background-color: var(--lightGrey);
      color: var(--grey);
      cursor: default;
      box-shadow: none;
    }

    @media (--viewportSmall) {
      font-size: 14px;
      padding: 10px 20px;
      line-height: 1.5;
    }
  }

  /* Attention button */

  --attentionOrangeButtonStyles: { 
    box-sizing: border-box;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 26px;
    width: auto;
    background-color: var(--orange);
    text-align: center;
    padding: 10px 30px;
    color: var(--white);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.4;
    cursor: pointer;
  
    &:hover,&:focus {
      background-color: var(--orange);
      color: var(--white);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }

    &:disabled {
      background-color: var(--lightGrey);
      color: var(--grey);
      cursor: default;
    }
  }  

  /* ================ Menu buttons for registered user topbar ================ */

  --messagesButton {
    box-sizing: border-box;
    background-color: var(--white);
    height: 40px;
    width: 40px;
    border: 1px solid var(--black);
    border-radius: 100%;
    cursor: pointer;
    background-image: url('../../assets/envelope-000000.svg');
    background-size: 20px 20px;
    background-position: center center;

    &:hover {
      background-color: var(--white);
      border: 1px solid var(--black);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  }

  --ordersButton {
    box-sizing: border-box;
    background-color: var(--white);
    height: 40px;
    width: auto;
    border: 1px solid var(--black);
    border-radius: 26px;
    cursor: pointer;
    text-align: center;
    padding: 10px 15px;
    color: var(--black);
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: 0.8px;
    cursor: pointer;

    &:hover {
      background-color: var(--white);
      border: 1px solid var(--black);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  }

  --favouritesButton {
    box-sizing: border-box;
    background-color: var(--white);
    height: 40px;
    width: 40px;
    border: 1px solid var(--black);
    border-radius: 100%;
    cursor: pointer;
    background-image: url('../../assets/heart-000000.svg');
    background-size: 20px 20px;
    background-position: center center;

    &:hover {
      background-color: var(--white);
      border: 1px solid var(--black);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  }

  --shoppingCartButton {
    box-sizing: border-box;
    background-color: var(--white);
    height: 40px;
    width: 40px;
    border: 1px solid var(--black);
    border-radius: 100%;
    cursor: pointer;
    background-image: url('../../assets/cart3-000000.svg');
    background-size: 20px 20px;
    background-position: center center;

    &:hover {
      background-color: var(--white);
      border: 1px solid var(--black);
      color: var(--black);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
  }


  /* ================ TITLE & BODY STYLES ================ */

  /* Title styles */

  --titleStyle1: {
    font-family: var(--fontVollkorn);
    font-weight: var(--fontWeightRegular);
    color: var(--beigePlus90);
    font-size: 48px;
    line-height: 1.25;
    letter-spacing: 0;
      
    @media (--viewportSmall) {
      font-size: 32px;
    }
  }

  --titleStyle2: {
    font-family: var(--fontVollkorn);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 24px;
    }
  }

  --titleStyle3: {
    font-family: var(--fontVollkorn);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 20px;
    }
  }

  --titleStyle4: {
    font-family: var(--fontVollkorn);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 16px;
    }
  }

  --titleStyle5: {
    font-family: var(--fontMontserrat);
    font-weight: var(--fontWeightSemiBold);
    color: var(--black);
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 16px;
    }
  }

  --titleStyle6: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightSemiBold);
    color: var(--black);
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 13px;
    }
  }

  --titleStyle7: {
    font-family: var(--fontMontserrat);
    font-weight: var(--fontWeightSemiBold);
    color: var(--black);
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 13px;
    }
  }

  --titleStyle8: {
    font-family: var(--fontMontserrat);
    font-weight: var(--fontWeightMedium);
    color: var(--beigePlus90);
    font-size: 13px;
    line-height: 1.25;
    word-spacing: 2px;
    letter-spacing: 0;
    text-transform: none;
  }

  --titleStyleTopbarMessage: {
    background-color: var(--beige);
    /*background-image: linear-gradient(155deg, var(--superlightLime) 0%, var(--lime) 100%);*/
    font-family: var(--fontMontserrat);
    font-weight: var(--fontWeightMedium);
    color: var(--beigePlus90);
    font-size: 12px;
    line-height: 1.25;
    word-spacing: 2px;
    letter-spacing: 0;
    text-transform: none;
    text-align: center;
    padding: 7px;
    margin: 0;

    @media (--viewportSmall) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;  
      font-family: var(--fontMontserrat);
      font-weight: var(--fontWeightSemiBold);
      color: var(--black);
      text-transform: uppercase;
      font-size: 10px;
      padding: 10px;
    }
  }

  /* Body styles */

  --bodyStyle1: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 18px;
    }
  }

  --bodyStyle2: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 16px;
    }
  }

  --bodyStyle3: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 16px;
    }
  }

  --bodyStyle4: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 15px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 14px;
    }
  }

  --bodyStyle5: {
    font-family: var(--fontHind);
    font-weight: var(--fontWeightRegular);
    color: var(--black);
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0;
  
    @media (--viewportSmall) {
      font-size: 13px;
    }
  }

  /* ======== INPUTS ======== */
  --input: {
    @apply --bodyStyle3;
    @apply --darkBorder;
    font-weight: var(--fontWeightRegular);
    border-radius: 3px;
    background-color: var(--white);
    padding-left: 15px;
    height: 40px;

    &:read-only {
      color: var(--grey);
      background-color: var(--lightGrey);
    }
  }

  --inputReadOnly: {
    @apply --bodyStyle3;
    @apply --darkBorder;
    font-weight: var(--fontWeightRegular);
    padding-left: 15px;
    color: var(--grey);
    background-color: var(--lightGrey);
    height: 40px;
  }

  --inputSuccess: {
    @apply --bodyStyle3;
    @apply --darkBorder;
    font-weight: var(--fontWeightRegular);
    background-color: var(--white);
    padding-left: 15px;
    height: 40px;
  }

  --inputError: {
    @apply --bodyStyle3;
    @apply --errorBorder;
    font-weight: var(--fontWeightRegular);
    background-color: var(--white);
    padding-left: 15px;
    height: 40px;
  }

  --marketplaceInputStyles: {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
  }

  /* ================ DIVIDERS ================ */

  --beigeDivider: {
    border: 1px solid var(--beige);
  }

  --darkThinDivider: {
    border: 1px solid var(--black);
  }

  --blackThinDivider: {
    border: 1px solid var(--black);
  }

  --blackFatBorder: {
    border: 1px solid var(--black);
  }

  /* ================ BORDERS ================ */

  --beigeBorder: {
    border: 1px solid var(--beige);
    border-radius: 5px;
  }
  
  --darkBorder: {
    border: 1px solid var(--black);
    border-radius: 5px;
  }

  --errorBorder: {
    border: 1px solid var(--red);
    border-radius: 5px;
  }

  --greyBorder: {
    border: 1px solid var(--grey);
    border-radius: 5px;
  }

  /* ================ LINKS & SELECT STYLES ================ */

  --coloredSelectedLink: {
    display: inline;
    border: none;
    color: #000000;
    text-decoration: none;
    background-image: url('../../assets/colored-underline.png');
    background-position: center bottom 2px;
    background-size: 100% 7px;

    &:hover {
      color: #000000;
      cursor: pointer;
    }
  }
  
  --marketplaceLinkStyles: {
    display: inline;
    border: none;
    color: var(--black);
    text-decoration: none;
  
    &:hover {
      color: var(--darkGrey);
      cursor: pointer;
    }
  }

  --marketplaceLightLinkStyles: {
    display: inline;
    border: none;
    color: var(--white);
    text-decoration: none;
  
    &:hover {
      color: var(--lightGrey);
      cursor: pointer;
    }
  }

  --marketplaceSelectStyles: {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
    background-size: 15px 15px;
    background-position: center right 15px;
  }
  
  .bi-chevron-down{
    fill: var(--black);
  }

  /* ========== HELPER TEXTS & LINKS ============== */

  --helperText: {
    @apply --bodyStyle3;
    font-weight: var(--fontWeightRegular);
    color: var(--grey);
    margin: 0;
  
    @media (--viewportSmall) {
      margin: 0;
    }
  }

  --helperLink: {
    @apply --bodyStyle3;
    font-weight: var(--fontWeightRegular);
    color: var(--grey);
    text-decoration: underline;

    @media (--viewportSmall) {
      margin: 0;
    }
  }

  /* ================ GENERAL ERROR TEXT =========== */

  --generalErrorText {
    @apply --bodyStyle3;
    font-weight: var(--fontWeightRegular);
    color: var(--red);
  }


  /* ================ MODAL WINDOWS ================ */

  --modalTransparentBackground: {
    background-color: #0000006a;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  --modalWhiteBackground {
    position: fixed;
    background-color: var(--white);
    border: 1px solid var(--black);
    width: 870px;
    margin: 20px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 8px 2px rgba(34,33,33,0.15);
    max-height: 90vh;
    overflow: auto;

    @media (--viewportSmall) {
      width: 90vw;
      margin: 20px 10px;
      padding: 40px 20px;
    }
  }

  --modalWhiteBackgroundSmall {
    position: fixed;
    background-color: var(--white);
    border: 1px solid var(--black);
    width: 610px;
    margin: 20px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 8px 2px rgba(34,33,33,0.15);
    max-height: 90vh;
    overflow: auto;

    @media (--viewportSmall) {
      width: 90vw;
      margin: 20px 10px;
      padding: 40px 20px;
    }
  }

  --modalShadow: {
    box-shadow: 0 0 8px 2px rgba(34,33,33,0.15);
  }

  --closeButton {
    box-sizing: border-box;
    background-color: var(--beige);
    height: 30px;
    width: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 25px;
    border: 1px solid var(--black);
    cursor: pointer;
    background-image: url('../../assets/x-000000.svg');
    background-size: 25px 25px;
    background-position: center center;
  }

  --modalTitle {
    @apply --titleStyle3;
    margin: 10px auto 20px;
    text-align: center;
    }

  --modalSubtitle {
    @apply --bodyStyle3;
    font-weight: var(--fontWeightRegular);
    text-align: center;
    margin: 0 auto 40px;
  }
}