@import '../../styles/propertySets.css';

.newLine {
  width: 100%;
}

.productItem {
  position: relative;
  box-sizing: border-box;
  width: 345px;
  margin: 0 10px 30px;
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    width: 300px;
  }
}

.isFirstProduct {
}

.newButton {
  background-color: var(--beigeMinus70);
  text-align: center;
  height: 567px;
  border-radius: 5px;

  @media (--viewportSmall) {
    display: none;
  }
}

.isOutOfStock .outOfStockMask {
  width: 343px;
  height: 428px;
  display: block;
  margin: 1px;

  @media (--viewportSmall) {
    width: 298px;
    height: 298px;
  }
}

.addProductButton {
  margin-top: 145px;
  @apply --bodyStyle4;
  color: var(--darkGrey);
  cursor: pointer;
}

.addProductButton:hover {
  color: var(--grey);
}

.addProductButtonIcon {
  background-position: center center;
  background-image: url('../../assets/plus-000000.svg');
  background-size: 30px 30px;
  background-color: var(--white);
  border: 1px solid var(--black);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
  margin: 8px auto;
}

.addProductButtonLabel {
}

.productState {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  border: 1px solid var(--black);
  color: var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  position: absolute;
  left: 10px;
  top: 10px;
  height: 30px;
}

.hideState {
  display: none;
}

.draft {
  background-color: var(--draft);
}

.pending {
  background-color: var(--pending);
}

.outOfStock {
  background-color: var(--outOfStock);
}

.backSoon {
  background-color: var(--lightGrey);
}

.published {
  background-color: var(--approved);
}

.editButtonCss {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 100%;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 1px;
}

.editProductButtonIcon {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: var(--white);
  border: 1px solid var(--black);
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 16px 16px;
  font-size: 0;
}

.favouriteButtonCss {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 100%;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.favouriteProductButtonIcon {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: var(--white);
  background-position: center center;
  background-size: 16px 16px;
  font-size: 0;
}

.favouriteProductDisabledButtonIcon {
  background-image: url('../../assets/heart-000000.svg');
  border: 1px solid var(--black);
}

.favouriteProductEnabledButtonIcon {
  background-image: url('../../assets/heart-fill-000000.svg');
  border: 1px solid var(--black);
}

.outOfStockMask {
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--white);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
}

.productImage {
  border: 1px solid var(--black);
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  width: 345px;
  height: 430px;
  background-color: var(--white);
  background-size: cover;
  background-position: center center;

    @media (--viewportSmall) {
      width: 300px;
      height: 300px;
    }
}

.emptyImage {
  height: 100%;
  background-color: var(--white);
  background-position: center center;
  background-image: url('../../assets/image-ebe8e7.svg');
  background-size: 70px 70px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.emptyImage.hasProductMainPhoto {
  display: none;
}

.productItemInfo {
  background-color: var(--beigeMinus70);
  border: 1px solid var(--black);
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  margin: 0;
  padding: 15px;
  flex-grow: 1;
}

.productItem:hover .productItemInfo {
  cursor: pointer;
}

.productName {
  @apply --titleStyle4;
  font-size: 20px;
  color: var(--beigePlus90);
  font-style: italic;
  margin: 0;
  height: 50px;

  @media (--viewportSmall) {
    font-size: 18px;
  }
}

.productPrice {
  @apply --titleStyle7;
  font-size: 11px;
  margin-top: 13px;
  margin-bottom: 0;
}

.productPrice a:hover {
  color: var(--black);
}

.registrationButton {
  background-image: url('../../assets/lock-000000.svg');
  background-color: var(--green);
  @apply --titleStyle7;
  font-size: 11px;
  background-position: left 7px center;
  background-size: 11px 11px;
  padding: 6px 10px;
  border-radius: 25px;
  padding-left: 22px;
  margin-right: 7px;
}

.msrpLabel {
  @apply --titleStyle7;
  font-size: 11px;
  color: var(--grey);
}

.brandName {
  @apply --titleStyle8;
  margin-top: 13px;
  margin-bottom: 0;
}