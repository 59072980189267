@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.registrationConfirmModalContainer {
  @apply --modalTransparentBackground;

  @media (--viewportSmall) {
    background-color: var(--beigeMinus70);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.registrationConfirmModal {
  width: 860px;
  height: 550px;
  margin: 200px auto;
  display: inline-block;
  @apply --modalShadow;
  position: relative;
  border-radius: 10px;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100vw;
    height: 100%;
    box-shadow: none;
  }
}

.closeButton {
  @apply --closeButton;
}

.modalContent {
  display: flex;
}

/* Supplier Pop-up */

.supplierLeftBlock {
  width: 430px;
  text-align: left;
  background-color: var(--beigeMinus70);
  height: 550px;
  border: 1px solid var(--black);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

    @media (--viewportSmall) {
      display: none;
    }
}

.supplierLeftBlock ul {
  @apply --bodyStyle3;
  width: 320px;
  margin: 30px auto 0;
}

.supplierLeftBlock ul li {
  margin-bottom: 17px;
  display: flex;
}

.brandIcon {
  width: 300px;
  display: block;
  margin: 20px auto 0;
}

/* Buyer Pop-up */

.buyerLeftBlock {
  width: 430px;
  background-color: var(--beigeMinus50);
  height: 550px;
  border: 1px solid var(--black);
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @media (--viewportSmall) {
    display: none;
  }
}

.buyerLeftBlock ul {
  width: 320px;
  margin: 30px auto 10px;
}

.buyerLeftBlock ul li {
  margin-bottom: 17px;
  display: flex;
}

.modalText {
  @apply --bodyStyle3;
}

.checkIcon {
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0;
}

.logoImage {
  width: 140px;
  display: block;
  margin: 50px auto 20px;
}

.shopIcon {
  width: 320px;
  display: block;
  margin: 30px auto 10px;
}

.rightBlock {
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: column;
  height: 550px;
  width: 430px;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;

  @media (--viewportSmall) {
    background-color: var(--beigeMinus70);
    flex-wrap: wrap;
    align-items: center;
    border: none;
  }
}

.registrationHeader {
  text-align: center;
  margin-top: 50px;
}

.rightBlockTitle {
  @apply --titleStyle2;

  @media (--viewportSmall) {
    text-align: center;
  }

}
.rightBlockParagraph {
  @apply --bodyStyle3;
  margin: 15px 30px 0;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    margin: 15px 20px 0;
    text-align: center;
  }
}

.terms {
  @apply --bodyStyle5;
  font-size: 13px;
  color: var(--darkGrey);
  text-align: center;
  width: 360px;
  margin: 50px auto;

  @media (--viewportSmall) {
    width: 60%;
  }
}