@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.footer {
  background-color: var(--beigePlus90);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px;
  border-top: 1px solid var(--black);
  
  @media (--viewportSmall) {
    padding: 30px 15px;
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
  }
}

.footerContent {
 display: flex;
 justify-content: space-between;
 width: 100%;

  @media (--viewportSmall) {
    margin: 20px 0 10px;
    flex-wrap: wrap;
  }
}

.logoColumn {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    align-items: center;
   }
}

.logoRow img {
  width: 170px;
}

.subTitle1 {
  width: 350px;
  @apply --titleStyle3;
  font-size: 22px;
  color: var(--white);
  margin: 20px 0 10px;

  @media (--viewportSmall) {
    width: 92vw;
    text-align: center;
   }
}

.trustpilotContainer {
  display: none;

  @media (--viewportSmall) {
    display: inline;
    padding: 0;
    width: 90px;
    width: 100%;
    margin-bottom: 0;
  }
}

.associationLogo {
  cursor: pointer;
  width: 150px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  margin: 45px 10px 10px 0;
  border-radius: 5px;

  @media (--viewportSmall) {
    margin: 25px 10px 10px;
  }
}

.associationLogo img {
  width: 120px;
  padding-left: 5px;
}

.linksColumn {
  max-width: 200px;

  @media (--viewportSmall) {
    flex-basis: 50%;
    text-align: center;
    max-width: 42vw;
   }
}

.linkColumnTitle {
  @apply --titleStyle3;
  font-weight: var(--fontWeightMedium);
  color: var(--white);
  margin-top: 30px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
   }
}

.linkItem {
  margin-bottom: 8px;
  margin-top: 0;
}

.linkItem a,
.namedLinkItem {
  @apply --bodyStyle3;
  color: var(--white);
}

.linkItem a:hover,
.namedLinkItem:hover {
  cursor: pointer;
  color: var(--beigePlus40);
}

.bottomSector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 90vw;
   }
}

.copyright {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @apply --bodyStyle3;
  color: var(--white);

  @media (--viewportSmall) {
    justify-content: center;
    flex-wrap: wrap;
   }
}

.verticalBar {
  margin: 0 15px 0;

  @media (--viewportSmall) {
    margin: 0 10px 0;
   }
}

.bottomLink {
  margin: 0;
}

.bottomLink a {
  @apply --bodyStyle3;
  color: var(--white);
}

.bottomLink a:hover {
  cursor: pointer;
  color: var(--beigePlus40);
}


.paymentLogos {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (--viewportSmall) {
    margin: 20px auto;
   }
}

.visaLogo {
  box-sizing: border-box;
  height: 35px;
  width: 50px;
  margin-right: 5px;
  background-color: var(--white);
  background-image: url('../../assets/visa.svg');
  background-size: 160px 100px;
  background-position: center center;
  border-radius: 3px;
}

.mastercardLogo {
  box-sizing: border-box;
  height: 35px;
  width: 50px;
  margin-right: 5px;
  background-color: var(--white);
  background-image: url('../../assets/mastercard.svg');
  background-size: 50px 30px;
  background-position: center center;
  border-radius: 3px;
}

.amexLogo {
  box-sizing: border-box;
  height: 35px;
  width: 50px;
  background-color: var(--white);
  background-image: url('../../assets/amex.svg');
  background-size: 60px 40px;
  background-position: center center;
  border-radius: 3px;
}

.sellOnVegshelfLink {
  margin-top: 25px;
  display: inline-block;
  @apply --callToActionButtonStylesLime;
  background-color: var(--lime);
  width: 280px;

  @media (--viewportSmall) {
    width: 250px;
  }
}

.registerToShopLink {
  display: inline-block;
  margin-top: 15px;
  @apply --callToActionButtonStylesLime;
  background-color: var(--lime);
  width: 280px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
    width: 250px;
  }
}