@import '../../styles/propertySets.css';

.root {
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  margin-top: 24px;

  @media (--viewportSmall) {
    margin-top: 32px;
  }
}

.bottomWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.formRow {
  text-align: center;
}

.formField {
  width: 301px;
  display: inline-block;
  text-align: left;
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
}

.bottomWrapper {
  text-align: center;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
}

.requiredMarker {
  color: var(--red);
}


.agreeRow label {
  display: inline;
  font-family: Lato;
  font-size: 9pt;
}

.loginLinkContainer {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-align: center;
}

.loginLinkContainer a {
  color: var(--darkGrey);
  text-decoration: underline;
}

.errorMessage {
  @apply --bodyStyle3;
  color: var(--red);
  display: block;
}

.errorMessage a {
  color: var(--red);
  text-decoration: underline;
}