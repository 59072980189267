@import '../../styles/propertySets.css';

.pageRoot {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  display: flex;
  height: 750px;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  
  @media (--viewportSmall) {
    width: 100vw;
    height: auto;
    margin-top: 0;
  }
}

.root {
  background: url('../../assets/vegan-cheese-sausage-baked-goods.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 100vw;
    background: none;
    height: 580px;
  }
}

.content {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 620px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFormBackground);
  border: 1px solid var(--black);
  margin: 30px auto 40px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 40px auto 20px;
    box-shadow: none;
    border: none;
    background-color: var(--beigeMinus70);
    padding: 0;
  }
}

/* ================ Form ================ */

.form {
  margin-top: 24px;
}

.bottomWrapper {
  text-align: left;
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportSmall) {
    margin-top: 96px;
  }
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;
}

.termsHeading {
  @apply --bodyStyle3;
  margin: 0 0 19px 0;
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --closeButton;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --closeButton;
}

.closeIcon {
  @apply --closeButton;
}

/* ================ Typography ================ */

.modalTitle {
  @apply --bodyStyle3;
}

.modalMessage {
  @apply --bodyStyle3;
}

.email {
  font-weight: var(--fontWeightBold);
}

.modalHelperLink {
  @apply --bodyStyle3;
}

.modalHelperText {
  @apply --bodyStyle3;
}

.modalIcon {
  @apply --bodyStyle3;
  margin-top: 47px;
}

/* ================ Errors ================ */

.error {
  @apply --bodyStyle3;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  @apply --bodyStyle3;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: #000000;
}

.confirmInfoText {
  @apply --bodyStyle3;
}

/* Log in page */

.authPageTitle {
  @apply --titleStyle2;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.authPageSubTitle {
  @apply --bodyStyle2;
  text-align: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.registerationText {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  margin: 50px auto;
  text-align: center;

  @media (--viewportSmall) {
    width: 80vw;
    margin: 50px auto 0;
  }
}

.registerationText a {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-decoration: underline;
  margin: auto 2px;
}