@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Product listing statuses & Favourites icon */

.fovouriteButtonCss {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.hideState {
}

.productState {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  color: var(--black);
  border: 1px solid var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
  height: 30px;
  background-color: var(--lightGrey);
  
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.productStateNonSupplier {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  color: var(--darkGrey);
  background-color: var(--lightGrey);
  border: 1px solid var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
  height: 30px;
  animation: appear 150ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes appear {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.draft {
  background-color: var(--draft);
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.pending {
  background-color: var(--pending);
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.outOfStock {
  background-color: var(--outOfStock);
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.backSoon {
  background-color: var(--lightGrey);
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.published {
  background-color: var(--approved);
  animation: fadeIn 250ms;
  animation-fill-mode: forwards;
  visibility: hidden;
}

/* Main page container */

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 30px 50px 50px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

/* Shopping Cart mobile pop-up */

.shoppingButton {
  display: none;
  
  @media (--viewportSmall) {
    display: unset;
  }
}

.menuLabel{
  display: none;
}

.bkPopupHandler {
  width: 100%;
  height: 2000px;
  z-index: 20;
  left: 0;
  top: 0;
  position: absolute;
}

.addedProductNotification {
  @apply --modalWhiteBackgroundSmall;
  top: 50vw;
  margin: 5vw;
  width: 80vw;
  padding: 15px 10px;
}

.addedProduct > h1 {
  @apply --titleStyle7;
  font-size: 14px;
  text-align: center;
  margin: 5px;
}

.checkIcon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.addedProductDetails {
  display: flex;
  margin: 15px;
}

.addedProductPhoto {
  background-size: cover;
  background-position: center center;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  @apply --darkBorder;
}

.productDetails {
  width: 145px;
}

.productTitle {
  @apply --titleStyle7;
  font-size: 13px;
  margin: 0;
}

.productSubTitle {
  @apply --bodyStyle5;
  margin: 5px 0 0 0;
}

.shoppingCartButtons {
  display: flex;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 5px;
}

.goToShoppingCart {
  @apply --primaryBlackbeanButtonStyles;
  margin: 10px auto;
}

/* Section - Product image & key product details */

.mainProductInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
  flex-wrap: wrap;
  width: 90vw;
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  
  @media (--viewportSmall) {
    flex-direction: column;
    width: 90vw;
  }
}

.productImages {
  width: 600px;
  margin-right: 50px;

  @media (--viewportSmall) {
    width: 100%;
    margin: 0 auto;
  }
}

.mainImageContainer {
  width: 600px;
  height: 600px;
  background-color: var(--white);
  background-position: center center;
  background-size: cover;
  position: relative;
  border: 1px solid var(--black);
  border-radius: 5px;

  @media (--viewportSmall) {
    width: 320px;
    height: 320px;
    max-width: 95vw;
  }
}

.zoomMode {
  background-size: 200% 200%;
  background-position: var(--mouse-zoom-X) var(--mouse-zoom-Y);
  cursor: zoom-in;
}

.isOutOfStock {
  position: relative;
}

.isOutOfStock .outOfStockMask {
  display: block;
}

.fovouriteProductButtonIcon {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: var(--white);
  border: 1px solid var(--black);
  padding: 11px 3px; 
  text-align: center;
}

.heartIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.editButton {
  @apply --secondaryBlackbeanButtonStyles;
  position: absolute;
  right: 15px;
  top: 15px;

  @media (--viewportSmall) {
    font-family: var(--fontMontserrat);
    font-weight: var(--fontWeightMedium);
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--black);
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
    padding: 9px 15px;
    border-radius: 30px;
    height: 30px;
  }
}

.editButton img {
  margin-right: 10px;
  width: 17px;
  height: 17px;

  @media (--viewportSmall) {
    margin-right: 7px;
    width: 12px;
    height: 12px;
  }
}

.otherImageContainer {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.imagePreview {
  height: 180px;
  width: 180px;
  background-color: var(--white);
  background-position: center center;
  background-size: cover;
  margin-bottom: 15px;
  margin-right: 15px;
  cursor: pointer;

  @media (--viewportSmall) {
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.imagePreviewSelect {
  @apply --darkBorder;
}

.outOfStockMask {
  border-radius: 5px;
  display: none;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
}

/* Product info section */

.productInfo {
  width: 600px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.brandInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.brandLogo {
  height: 60px;
  width: 60px;
  background-image: var(--white);
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    margin: 10px 10px 10px 0;
  }
}

.companyLogoInitials {
  font-family: var(--fontVollkorn);
  font-weight: var(--fontWeightSemiBold);
  font-size: 28px;
  padding-top: 2px;
  padding-left: 1px;
  color: var(--black);
}

.brandTitle {
  @apply --titleStyle7;
  font-size: 14px;
}

.brandSubTitle {
  margin-top: 5px;
  @apply --titleStyle8;
  color: var(--darkGrey);
  word-spacing: 0;
}

.productName {
  @apply --titleStyle2;
  color: var(--beigePlus90);
  font-style: italic;
  margin-top: 35px;
  margin-bottom: 5px;

  @media (--viewportSmall) {
    font-size: 24px;
    margin-top: 30px;
  }
}

.productPriceContainer {
  display: flex;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.wholesalePrice {
  @apply --titleStyle7;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportSmall) {
    margin-bottom: 5px;
  }
}

.productMSRP {
  @apply --titleStyle7;
  font-size: 12px;
  color: var(--grey);
  margin-top: 0;
  margin-bottom: 0;
}

.visitorPriceContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;

  @media (--viewportSmall) {
    flex-direction: column;
    height: 55px;
    justify-content: space-between;
  }
}

.registrationButton {
  background-image: url('../../assets/lock-000000.svg');
  background-color: var(--green);
  @apply --titleStyle7;
  font-size: 12px;
  background-position: left 20px center;
  background-size: 14px 14px;
  padding: 10px 22px;
  border-radius: 25px;
  padding-left: 40px;
  margin-right: 10px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
  }
}

.productSoldBy {
  @apply --titleStyle8;
  font-style: italic;
  color: var(--beigePlus90);
  margin-top: 25px;
  margin-bottom: 50px;
  word-spacing: 0;

  @media (--viewportSmall) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.chooseQuantityLabel {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightMedium);
  text-align: left;
  margin-bottom: 10px;
}

.addToCartButton {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid var(--black);
  border-radius: 26px;
  width: 100%;
  background-color: var(--beigePlus90);
  text-align: center;
  padding: 10px 30px;
  color: var(--white);
  font-family: var(--fontHind);
  font-weight: var(--fontWeightSemiBold);
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.4;
  cursor: pointer;
  margin-top: 10px;
}

.addToCartButton:hover {
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
}

.addToCartButton:active {
  opacity: 0.9;
  cursor: default;
}

.addToCartButton:disabled {
  opacity: 0.4;
  cursor: default;
}

.addToCardLock {
  opacity: 1 !important;
  background-color: var(--green);
  color: var(--black);
}

.signUpButton {
  @apply --callToActionButtonStyles;

  @media (--viewportSmall) {
    margin: 0 5px;
    align-self: flex-start;
  }
}

.productShipped {
  @apply --titleStyle8;
  color: var(--darkGrey);
  word-spacing: 0;
  margin-top: 25px;
  margin-bottom: 50px;
}

.productFieldRow {
  margin-top: 0;
  margin-bottom: 18px;

  @media (--viewportSmall) {
    max-width: 95vw;
  }
}

.fieldLabel {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightSemiBold);
  display: inline-block;
  width: 150px;
  margin-right: 25px;
}

.fieldValue {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightRegular);
  display: inline-block;
  vertical-align: top;
  width: 420px;

  @media (--viewportSmall) {
    max-width: 90vw;
  }
}

.propertiesWrapper {
  background-color: var(--beigeMinus70);
  border-top: 1px solid var(--black);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px;

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }

}

.propertiesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 90vw;
  }
}

.propertiesTitle {
  @apply --titleStyle2;
  text-align: center;
  margin: 0 0 10px;
}

.properties {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}

.property {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightSemiBold);
  color: var(--black);
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
  height: 50px;
  background-color: var(--beige);
  border: 1px solid var(--black);
  margin: 0 10px 10px 0;

  @media (--viewportSmall) {
    height: 40px;
    font-size: 11px;
    padding: 8px 10px 8px 8px;
  }
}

.propertyIcon {
  margin: 0 2px 0 0;
  width: 45px;

  @media (--viewportSmall) {
    width: 35px;
  }
}

/* Product description section */

.productDescriptionsSection {
  background-color: var(--white);
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  min-height: 350px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px;

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.productDescriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
  flex-wrap: wrap;
  width: 90vw;
  }
}

.productDescriptionContainer {
  width: 50%;
  margin-right: 50px;
  
  @media (--viewportSmall) {
    margin: 0 0 40px;
    width: 100%;
  }
}

.productDescriptionTitle {
  @apply --titleStyle2;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
}

.productDescriptionContent {
  @apply --bodyStyle3;
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.productSamples {
  padding-top: 40px;
}

.productSamplesLabel {
  @apply --titleStyle2;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
}

.productSamplesContent {
  @apply --bodyStyle3;
  text-align: left;
}

.productSamplesContent img {
  margin-right: 8px;
  height: 15px;
  width: 15px;
}

.companyInfoContainer {
  width: 50%;
  margin-left: 31px;

  @media (--viewportSmall) {
    width: 100%;
    margin: 0;
  }
}

.aboutCompany {
  height: 100%;
}

.aboutLabel {
  @apply --titleStyle2;
  text-align: left;
  margin-bottom: 10px;
}

.aboutCompanyContent {
  @apply --bodyStyle3;
  text-align: left;
  margin-top: 0;
  margin-bottom: 30px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.companyButtons {
  /*height: 52px;*/
  display: flex;
  align-items: flex-end;

  @media (--viewportSmall) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.visitCompanyProfile {
  @apply --secondaryBlackbeanButtonStyles;
}

.contactBrandButton {
  @apply --primaryBlackbeanButtonStyles;
  margin-left: 15px;
}

/* Other Supplier Product section */

.otherSupplierProductsContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.otherSupplierProducts {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
  width: 90vw;
  }
}

.otherSupplierProducts h1 {
  @apply --titleStyle2;
  text-align: left;
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
  }
}

.otherSupplierProducts span {

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.manageProducts {
  @apply --secondaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    margin: 0 auto;
  }
}

.manageProducts img {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.otherProductsContainer {
  margin: 20px auto 0;

  @media (--viewportSmall) {
    margin: 0 auto;
  }
}

/* Not clear what the last classes do */

.showAllProduct {
  text-align: center;
  margin: 5pt;
  margin-top: 35px;
}

.showAllButton {
  @apply --secondaryBlackbeanButtonStyles;
}

.addProductButtonLabel {
  color: #A3A3A3;
  font-family: Lato;
  font-size: 10pt;
}