@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formFields {
}

.formRow {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.formField {
  width: 360px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField select {
  width: 100%;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-align: left;
  margin-top: 10px;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.fieldInputContainer textarea {
  height: 120px;
  padding: 10px 15px;
} 

.requiredMarker {
  color: var(--red);
}

.labelDescriptionMaxSize {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin-left: 5px;
}

.labelImageMaxSize {
  @apply --bodyStyle3;
  color: var(--darkGrey);
}

.errorMessage {
  margin-left: 10px;
  color: var(--red);
  font-weight: var(--fontWeightRegular);
}

.fieldInputContainer {
  position: relative;
}

.field {
}

.typeInputValue input,
.typeInputValue input:focus,
.typeInputValue input:hover {
  padding-left: 15px;
  width: 175px;

  @media (--viewportSmall) {
    width: 50px;
  }
}

.unitTypeSelect {  
}

.typeInput select,
.typeInput select:focus,
.typeInput select:hover {
  width: 175px;
  margin-left: 10px;
}

.customUnitInput {
  width: 175px;
  margin-left: 10px;
}

.fieldInputWithSelect {
  display: flex;
  justify-content: space-between;
}

.propertiesContainer {
  display: block;
  text-align: left;
}

.propertyLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
}

.propertyItem {
  width: 33%;
  display: inline-block;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.propertyItem span {
  display: inline-block;
}

.propertyItem label {
  display: inline-block;
  @apply --bodyStyle4;
}

.formFieldFullRow {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.formFieldFullRow label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-align: left;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.textPlaceHolder {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  position: absolute;
  top: 11px;
  right: 20px;
}

.agreeRow {
  /*margin-top: 15px;*/

  @media (--viewportSmall) {
    text-align: left;
  }
}

.agreeRow label {
  display: inline;
  @apply --bodyStyle3;
}

.productImages {
}

.imagesLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-align: left;

  @media (--viewportSmall) {
    margin-top: 40px;
  }
}
  
.imageContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.imageItem {
  @apply --darkBorder;
  width: 200px;
  height: 200px;
  background-color: var(--white);
  background-position: center center;
  background-size: cover;
  display: inline-block;
  position: relative;
  margin: 0 15px 15px;

  @media (--viewportSmall) {
    margin: 0 0 15px;
  }
}

.invalidImage {
  border: 1px solid var(--red);
}

.changeImage {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 55px auto;
}

.changeImage:hover {
  color: var(--grey);
}

.addImageButtonIcon {
  background-position: center center;
  background-image: url('../../assets/plus-000000.svg');
  background-size: 30px 30px;
  background-color: var(--beigeMinus50);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
  margin: 8px auto;
}

.deleteImage {
  @apply --closeButton;
  right: 10px;
  top: 10px;
}

.bottomWrapper {
  display: flex;
  margin: 30px auto;
  
  @media (--viewportSmall) {
    flex-direction: column;
    margin: 30px auto 10px;
  }
}

.cancelButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin-right: 20px;

  @media (--viewportSmall) {
    margin: 10px;
  }
}

.spinner {
  width: 22px;
  height: 22px;
  stroke: var(--black);
  stroke-width: 3px;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    margin: 10px;
    order: -1;
  }
}