@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.bottomWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.formRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.formField {
  width: 360px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-align: left;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
}

.requiredMarker {
  color: var(--red);
}

.agreeRow {
  margin-top: 15px;

  @media (--viewportSmall) {
    margin-top: 30px;
    text-align: left;
  }
}

.agreeRow label {
  display: inline;
  @apply --bodyStyle3;
}

.agreeRowLinks {
  @apply --bodyStyle3;
  text-decoration: underline;
}

.fieldPhoneInput {
  position: relative;
  display: flex;
}

.phoneCode select, 
.vatCode select {
  width: 130px;
}

.phoneCodeInput input {
  padding-left: 45px;
  padding-top: 6px;
}

.emptyPhoneCode input {
  padding-left: 15px;
}

.fieldWithCountry {
  flex: 1;
  margin-left: 10px;
  position: relative;
}

.country select,
.annualSales select,
.productCategory select,
.numberOfStockists select,
.avgLeadTime select {
  width: 360px;
  display: flex;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.hearAboutVegshelf select {
  width: 100%;
  display: flex;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.inputCodePlaceholder {
  position: absolute;
  left: 15px;
  top: 11px;
  @apply --bodyStyle3;
}

.placeholderContainer {
  position: relative;
}

.instagramInput input {
  padding-left: 26px /*!important*/;
  padding-top: 6px;
}

.websiteInput input {
  padding-left: 67px /*!important*/;
  padding-top: 6px;
}

.instagramPlaceholder {
  @apply --bodyStyle3;
  position: absolute;
  top: 11px;
  left: 10px;
  width: 20px;
}

.httpPlaceHolder {
  @apply --bodyStyle3;
  position: absolute;
  left: 15px;
  top: 11px;
  width: 50px;
}

select>option:disabled {
  color: var(--black);
}

.errorMessage {
  margin-left: 10px;
  color: var(--red);
  font-weight: var(--fontWeightRegular);
}