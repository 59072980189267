@import '../../styles/propertySets.css';

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: space-between;
}

.emptyDataContainer {
  @apply --darkBorder;
  width: 1030px;
  height: 410px;
  text-align: center;

  @media (--viewportSmall) {
    width: 90vw;
    border: none;
  }
}

.pageHeader {
}

.pageContent {
  flex: 1;
}

.pagination {
  margin-top: 30px;
}