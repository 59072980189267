@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    /*position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;*/
  }
}

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 50px 50px 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 30px 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.pageContainerShort {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 50px 50px 90px;

  @media (--viewportSmall) {
    padding: 30px 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.pageContainerSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  min-height: 500px;

  @media (--viewportSmall) {
    width: 90vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.pageContent {
}

/* Shopping cart progress bar */

.shoppingCartStepLine {
  width: 500px;
  height: 1px;
  margin: 0 auto;
  background-color: var(--black);
  position: relative;
  top: 50px;

  @media (--viewportSmall) {
    width: 67vw;
  }
}

.shoppingCartSteps {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.stepIcon {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  margin: 5px auto;
  position: relative;
  border: none;
  border-radius: 100%;
  border: 1px solid var(--black);
  background-size: 25px 25px;
  background-position: center center;
}

.stepItem {
  display: flex;
  flex-direction: column;
  width: 250px;
  
  @media (--viewportSmall) {
    width: 33vw;
  }
}

.stepItem h3 {
  @apply --titleStyle7;
  font-size: 13px;
  text-align: center;
  margin: 0;
}

/* STEP 1 icons - Cart */

.productsStepDone {
  background-image: url('../../assets/check-000000.svg');
  background-color: var(--green);
  cursor: pointer;
}

.productsStepCurrent {
  background-image: url('../../assets/box-seam-ffffff.svg');
  background-color: var(--black);
}

.productsStepEmpty {
}

/* STEP 2 icons - Delivery */

.deliveryStepDone {
  background-image: url('../../assets/check-000000.svg');
  background-color: var(--green);
  cursor: pointer;
}

.deliveryStepCurrent {
  background-image: url('../../assets/geo-alt-ffffff.svg');
  background-color: var(--black);
}

.deliveryStepEmpty {
  background-image: url('../../assets/geo-alt-a3a3a3.svg');
  background-color: var(--lightGrey);
}

/* STEP 3 icons - Payment */

.confirmStepDone {
  background-image: url('../../assets/check-000000.svg');
  background-color: var(--green);
  cursor: pointer;
}

.confirmStepCurrent {
  background-image: url('../../assets/credit-card-ffffff.svg');
  background-color: var(--black);
}

.confirmStepEmpty {
  background-image: url('../../assets/credit-card-a3a3a3.svg');
  background-color: var(--lightGrey);
}

/* Thank you page */

.orderCreated {
  padding: 30px;
}

.orderCreated h1 {
  @apply --titleStyle2;
  text-align: center;
  margin: 0;
}

.orderCreated h2 {
  @apply --bodyStyle2;
  text-align: center;
  margin: 0;
}

.orderCreated h3 {
  @apply --bodyStyle3;
  text-align: center;
  margin: 5px;
}

.orderCreated h4 {
  @apply --bodyStyle3;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
}

.orderCreated h4 a {
  @apply --bodyStyle3;
  text-decoration: underline;
}

.orderDetails {
  @apply --darkBorder;
  background-color: var(--white);
  width: fit-content;
  padding: 30px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
}

.orderDetailsTotalPrice {
  @apply --titleStyle7;
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.orderDetailsItem {
  margin: 0;
}

.orderDetailsItemBrandName {
  @apply --titleStyle7;
  font-size: 14px;
  margin: 30px 0 5px;
}

.orderDetailsItemDeliveryTime {
  @apply --bodyStyle4;
  margin: 5px 0;
}

.orderDetailsItemId {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  margin-top: 0;
  margin-bottom: 5px;
}

.createOrderButton, .goToNextButton, .orderCreated .goToButton {
  @apply --primaryBlackbeanButtonStyles;
  width: 100%;
  margin-top: 20px;
  border: none;
  margin-bottom: 40px;
}

.createOrderButton {

  @media (--viewportSmall) {
    display: none;
  }
}

.mobileCreateOrderButton {
  display: none;

  @media (--viewportSmall) {
    display: block;
    @apply --primaryBlackbeanButtonStyles;
    width: 90%;
    border: none;
    margin: 30px auto 10px;
  }
}

.goToNextButton {
  margin: 5pt auto;
  display: block;
  width: 290pt;

  @media (--viewportSmall) {
    width: 90vw; 
  }
}

.orderCreated .goToButton {
  width: 200pt;
  display: inline-block;
}


.shoppingCartContent {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.brands {
  flex: 1;
  padding-right: 50px;

  @media (--viewportSmall) {
    padding-right: 0;
    width: 90vw;
  }
}

.cartPanel {

  @media (--viewportSmall) {
    width: 90vw;
    margin: 0 0 30px;
  }
}

.brandItem {
  margin-bottom: 30px;
}

.brandItem:last-child {
  margin-bottom: 0;
}

.brandItemHeader {
  background-color: var(--beigeMinus50);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.brandHeaderMainColumn {
  flex: 1;
  margin-right: 20px;
}

.brandTitle {
  @apply --titleStyle7;
  text-align: left;
  margin: 0;
}

/* Case when brand total is under 100 EUR minimum */

.invalidPriceStyle {
  color: var(--orange);
}

.shopBrandButton {
  @apply --secondaryBlackbeanButtonStyles;
  display: inline-block;

  @media (--viewportSmall) {
    margin-top: 10px;
  }
}

.invalidItemsHeader {
  @apply --titleStyle7;
  font-weight: var(--fontWeightSemiBold);
  text-align: left;
  color: var(--orange);
}

/* End of under 100 eur case */
/* Continued: Other shopping cart elements */

.productContainer {
  padding: 20px 0;
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.productItemsContainer {
  border-right: 1px solid var(--grey);
  flex: 2;
  justify-content: center;
  align-items: center;

  @media (--viewportSmall) {
    border: none;
  }
}

.productItem {
  display: flex;
  border-bottom: 1px solid var(--grey);
  padding: 20px 20px 20px 0;
}

.shippingFeeContainer {
  flex: 0.9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;

  @media (--viewportSmall) {
    padding: 0;
  }
}

.brandShippingFee {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 10px;
  max-width: 250px;

  @media (--viewportSmall) {
    margin-top: 20px;
    max-width: 100%;
  }
}

.brandDeliveryTime {
  @apply --bodyStyle4;
  max-width: 250px;

  @media (--viewportSmall) {
    margin-top: 10px;
    max-width: 100%;
  }
}

.productItem:last-child {
  border-bottom: none;
}

.productLogo {
  @apply --darkBorder;
  width: 145px;
  height: 145px;
  background-position: center center;
  background-size: cover;
  margin-right: 20px;
  
  @media (--viewportSmall) {
    width: 18vw;
    height: 18vw;
    margin-right: 15px;
  }
}

.productDetails {
  flex: 1;
}

.productNameRow {
  display: flex;
  justify-content: space-between;
}

.productName {
  @apply --titleStyle7;
  font-size: 14px;
  margin: 20px 20px 5px 0;

  @media (--viewportSmall) {
    margin: 0 10px 5px 0;
  }
}

.deleteProductButton {
  background-color: var(--beigeMinus50);
  width: 30px;
  height: 30px;
  border: 1px solid var(--black);
  border-radius: 100%;
  cursor: pointer;
  background-image: url('../../assets/x-000000.svg');
  background-size: 25px 25px;
  background-position: center center;
}

.productKeyRow {
  @apply --bodyStyle4;
  margin-right: 50px;
  margin-bottom: 20px;
  margin-top: 0;

  @media (--viewportSmall) {
    margin-right: 30px;
  }
}

.productPriceRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productPrice {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightSemiBold);
}

/* Order summary section */

.cartInfo {
  @apply --darkBorder;
  padding: 30px;
  background-color: var(--white);
  min-width: 350px;
  border-radius: 3px;
  margin-top: 20px;

  @media (--viewportSmall) {
    min-width: unset;
    width: 100%;
    padding: 30px 20px;
  }
}

.cartInfo h1 {
  @apply --titleStyle7;
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey);
}

.cartProperty, .cartPropertyTotal {
  display: flex;
  justify-content: space-between;
}

.PropertyLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 10px;
}

.PropertyValue {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 10px;
}

.brandPriceBlock {
  margin: 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey);
}

.cartPropertyTotal {
  margin: 10px 0 0;
  padding-top: 20px;
  border-top: 1px solid var(--grey);
}

.cartPropertyTotal .PropertyLabel,
.cartPropertyTotal .PropertyValue {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
}
 

/*
.goToNextButton:hover, .createOrderButton:hover, .orderCreated .goToButton:hover {
  text-decoration: none;
}


.goToNextButton:active, .createOrderButton:active, .orderCreated .goToButton:active {
  opacity: 0.9;
  cursor: default;
}

.goToNextButton:disabled, .createOrderButton:disabled, .orderCreated .goToButton:disabled {
  opacity: 0.4;
  cursor: default;
}
*/

/* Delivery details step */

.deliveryDetailForm {
  flex: 1;
}

.formTitle {
  @apply --titleStyle3;
  font-size: 28px;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
  }
}

.formDelivery {
  margin-bottom: 40px;
}

.inputLabelMargin {
  margin: 25px 0 10px;
}

.twoRowFields {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
   flex-direction: column;
   justify-content: center;
  }
}

.twoRowFields .fieldInput {
  flex: 1;
}

.twoRowFields .fieldInput:last-child {
  margin-left: 30px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

/* Payment & confirmation step section */

.previewItemData {
  display: flex;
  flex: 1;
}

.previewItemFirstColumn {
  flex: 1;
}

.previewItemTitle {
  @apply --titleStyle7;
  margin: 0 0 10px;
}

.previewItemValue {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  word-wrap: break-word;
  max-width: 850px;
}

.previewItemSecondColumn {
  width: 30px;
}

.previewNavigateToTab {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--beigeMinus50);
  border: 1px solid var(--black);
  font-size: 0;
  cursor: pointer;
  background-position: center center;
  background-image: url('../../assets/pencil-000000.svg');
  background-size: 18px 18px;
}

.previewItemSeparator {
  border-top: 1px solid var(--grey);
  margin-top: 30px;
  margin-bottom: 10px;
}

.confirmRow {
  display: flex;
  margin-top: 15px;
}

.confirmRow input {
  border: 1px solid var(--black);
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.confirmRow label {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightRegular);
  margin-top: 2px;
}