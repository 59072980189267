@import '../../styles/propertySets.css';

.ordersComponent {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    display: inline;
  }
}

.orders {
  flex: 1;
}

.emptyDataContainer {
  @apply --darkBorder;
  width: 1030px;
  height: 410px;
  text-align: center;

  @media (--viewportSmall) {
    width: 90vw;
    border: none;
  }
}