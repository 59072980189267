@import '../../styles/propertySets.css';

.vegshelfInformationContainer {
  
  @media (--viewportSmall) {
    width: 100vw;
    flex-wrap: wrap;
  }
}

.vegshelfInformationBox1 {
  width: 980px;
  padding: 50px;
  @apply --darkBorder;
  margin-bottom: 90px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 20px;
    margin: 10px auto 20px;
  }
}

.pageTitle {
  @apply --titleStyle2;
  margin-top: 0;
  margin-bottom: 30px;
}

.mainTitle {
  @apply --titleStyle7;
  margin-top: 0;
  margin-bottom: 15px;
}

.bodyText {
  @apply --bodyStyle3;
  margin-bottom: 40px;
}

.bodyText a {
  @apply --bodyStyle3;
  color: var(--bluePlus35);
}

.bodyText a:hover {
  text-decoration: underline;
  color: var(--bluePlus35);
}