@import '../../styles/propertySets.css';

/* 404 page */

.pageRoot {

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.root {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  
  @media (--viewportSmall) {
    width: 100vw;
    margin-top: 0;
  }
}

.content {
  background-image: url('../../assets/error-page-pattern.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    background-image: none;
    background-position: center left;
    width: 100vw;
    height: 550px;
  }
}

.heading {
  max-width: 500px;
  @apply --titleStyle1;
  text-align: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.description {
  @apply --bodyStyle1;
  text-align: center;
  margin: 15px auto 0;
  max-width: 800px;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.toHomeButton {
  display: inline-block;
  @apply --primaryBlackbeanButtonStyles;
  margin-top: 50px;
}