@import '../../styles/propertySets.css';

.formTwoColumnsPart {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-self: center;
  }
}

.formFirstColumn {
  margin-right: 50px;
}

.formFirstColumn, 
.formSecondColumn {
  width: 100%;
}

.formField {
}

.formField input:read-only,
.formField input:read-only:hover,
.formField input:read-only:focus {
  @apply --inputReadOnly;
  cursor: default;
  pointer-events: none;
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 8px;
}

.formButtonsPart {
  margin-top: 30px;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    display: block;
    margin: 0 auto;
  }
}