@import '../../styles/propertySets.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --primaryBlackbeanButtonStyles;
  @apply --bodyStyle3;

  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: #ffffff;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuLabelSelected {
  @apply --primaryBlackbeanButtonStyles;
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid #F9F1A1;

  &:hover,
  &:focus {
    border: 1px solid #eee581;
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 4px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: #F9F1A1;
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: #F9F1A1;
}

.menuItem {
  @apply --bodyStyle3;
  color: #000000;

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  white-space: nowrap;

  cursor: pointer;

  &:focus,
  &:hover {
    color: #000000;
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: #b2b2b2;

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: #000000;
    transition: width var(--transitionStyleButton);
  }
}
