@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.pageContainer { 
  background-color: var(--beigeMinus50);
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  margin-top: 160px;
  
  @media (--viewportSmall) {
    align-items: center;
    flex-direction: column;
    width: 100vw;
    margin-top: 0;
  }
}

.pageWrapper {
  display: flex;
  width: 1280px;

  @media (--viewportSmall) {
    width: 95vw;
  }
}

.backToMarketplaceSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 120px;
  margin-top: 40px;

  @media (--viewportSmall) {
    position: absolute;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.arrow {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;

  @media (--viewportSmall) {
    margin-top: 3px;
  }
}

.backToMarketplaceText {
  @apply --titleStyle7;
  font-size: 14px;
  margin-left: 10px;
  
  @media (--viewportSmall) {
    display: none;
  }
}

.backToMarketplaceText:hover {
  color: var(--darkGrey);
}

.formTitle {
  @apply --titleStyle2;
  margin: 0 0 30px;
  text-align: center;
}

.formPanel {
  @apply --darkBorder;
  margin: 40px auto 90px;
  padding: 40px 50px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFilterButton);
  width: 850px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 0 20px 5px;
    margin-bottom: 50px;
    box-shadow: none;
    border: none;
    background-color: var(--beigeMinus50);
  }
}

.placeHolder {
  width: 120px;

  @media (--viewportSmall) {
    display: none;
  }
}

.loader {
  @apply --bodyStyle3;
}

/*
.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4353ff, 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.5), -1.8em -1.8em 0 0em rgba(180, 180, 180, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.7), 1.8em -1.8em 0 0em #4353ff, 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.5), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.7), 2.5em 0em 0 0em #4353ff, 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.5), 2.5em 0em 0 0em rgba(72, 75, 255, 0.7), 1.75em 1.75em 0 0em #4353ff, 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.5), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.7), 0em 2.5em 0 0em #4353ff, -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.5), 0em 2.5em 0 0em rgba(72, 75, 255, 0.7), -1.8em 1.8em 0 0em #4353ff, -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.5), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.7), -2.6em 0em 0 0em #4353ff, -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.5), -2.6em 0em 0 0em rgba(72, 75, 255, 0.7), -1.8em -1.8em 0 0em #4353ff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4353ff, 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.5), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.7), 1.8em -1.8em 0 0em #4353ff, 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.5), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.7), 2.5em 0em 0 0em #4353ff, 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.5), 2.5em 0em 0 0em rgba(72, 75, 255, 0.7), 1.75em 1.75em 0 0em #4353ff, 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.5), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.7), 0em 2.5em 0 0em #4353ff, -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.2), -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.5), 0em 2.5em 0 0em rgba(72, 75, 255, 0.7), -1.8em 1.8em 0 0em #4353ff, -2.6em 0em 0 0em rgba(72, 75, 255, 0.2), -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.5), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.7), -2.6em 0em 0 0em #4353ff, -1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(72, 75, 255, 0.2), 1.8em -1.8em 0 0em rgba(72, 75, 255, 0.2), 2.5em 0em 0 0em rgba(72, 75, 255, 0.2), 1.75em 1.75em 0 0em rgba(72, 75, 255, 0.2), 0em 2.5em 0 0em rgba(72, 75, 255, 0.2), -1.8em 1.8em 0 0em rgba(72, 75, 255, 0.5), -2.6em 0em 0 0em rgba(72, 75, 255, 0.7), -1.8em -1.8em 0 0em #4353ff;
  }
}
*/