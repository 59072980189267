@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --primaryBlackbeanButtonStyles;
  @apply --bodyStyle3;

  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: #ffffff;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --primaryBlackbeanButtonStyles;
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid #F9F1A1;

  &:hover,
  &:focus {
    border: 1px solid #eee581;
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  @apply --bodyStyle3;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  @apply --bodyStyle3;
  margin: 0;
  padding: 16px 0 30px 20px;
  border: none;
}
