@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Hero section */

.pageHeaderContainer {
  background-color: var(--beige);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: space-between;
  padding: 0 50px 50px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);
  
  @media (--viewportSmall) {
    padding: 0 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.logoIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  
  @media (--viewportSmall) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 15px;
  }
}

.logo {
  height: 40px;
  margin-top: 22px;
  width: auto;
  max-width: 160px;
  object-fit: contain;
  object-position: left center;
}

.pageHeaderSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: 20px auto 10px;
    
  @media (--viewportSmall) {
    flex-wrap: wrap;
    max-width: 90vw;
    margin: 20px auto 30px;
  }
}

.sectionBothMainTitles {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0 0;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.sectionMainTitle {
  display: flex;
  @apply --titleStyle1;
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
}

.sectionSubTitle {
  @apply --bodyStyle1;
  text-align: center;
  margin: 20px auto 0;
  max-width: 700px;
}

.applyToSellButtonContainer {
  text-align: center;
  margin-top: 35px;

  @media (--viewportSmall) {
    margin-top: 25px;
  }
}

.applyToSellButton {
  display: inline-block;
  @apply --callToActionButtonStyles;
}

.loginText {
  @apply --bodyStyle3;
  color: var(--beigePlus90);
  text-align: center;
  margin-top: 25px;
}

.loginLink {
  @apply --bodyStyle3;
  color: var(--beigePlus90);
  margin-left: 5px;
  text-decoration: underline;
}

/* Image section */

.heroImageSection {
  background-color: var(--beigeMinus70);

  @media (--viewportSmall) {
    width: 100vw;
  }
}

.descImages {
  display: flex;
}

.rootImage1 {
  width: 20%;
  background-image: url('../../assets/local-coffee-shop.png');
  height: 350px;
  background-size: cover;
  background-position: center bottom;
  border-right: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

.rootImage2 {
  width: 20%;
  background-image: url('../../assets/vegan-food-store-owner.png');
  height: 350px;
  background-size: cover;
  background-position: center center;

  @media (--viewportSmall) {
    width: 50vw;
    max-height: 250px;
  }
}

.rootImage3 {
  width: 20%;
  background-image: url('../../assets/plant-based-retailer.png');
  height: 350px;
  background-size: cover;
  background-position: center center;
  border-left: 1px solid var(--black);

  @media (--viewportSmall) {
    width: 50vw;
    max-height: 250px;
  }
}

.rootImage4 {
  width: 20%;
  background-image: url('../../assets/happy-organic-store-owners.png');
  height: 350px;
  background-size: cover;
  background-position: center center;
  border-left: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

.rootImage5 {
  width: 20%;
  background-image: url('../../assets/food-concept-store.png');
  height: 350px;
  background-size: cover;
  background-position: center center;
  border-left: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

/* SUPPLIER USP SECTION */

.uspContainer {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  height: 210px;
  padding: 50px 50px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
   }
}


.usps {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1280px;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
  }
}

.singleUsp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 290px;
  margin: 0 20px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    margin: 20px 50px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--black);
  }
}

.singleUsp:last-child {
  @media (--viewportSmall) {
    border-bottom: hidden;
  }
}

.stepIcon1, .stepIcon2, .stepIcon3, .stepIcon4 {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;

  @media (--viewportSmall) {
    height: 40px;
    width: 40px;
  }
}

.stepIcon1 {
  background-image: url('../../assets/016-quality.svg');
  background-size: 50px 50px;

  @media (--viewportSmall) {
    background-size: 40px 40px;
  }
}

.stepIcon2 {
  background-image: url('../../assets/012-website.svg');
  background-size: 45px 45px;

  @media (--viewportSmall) {
    background-size: 35px 35px;
  }
}

.stepIcon3 {
  background-image: url('../../assets/011-box.svg');
  background-size: 48px 48px;

  @media (--viewportSmall) {
    background-size: 38px 38px;
  }
}

.stepIcon4 {
  background-image: url('../../assets/007-euros.svg');
  background-size: 50px 50px;

  @media (--viewportSmall) {
    background-size: 40px 40px;
  }
}

.stepTitle {
  @apply --bodyStyle1;
  font-weight: var(--fontWeightMedium);
  margin: 0 auto 0;
  text-align: center;
  max-width: 230px;

  @media (--viewportSmall) {
    font-size: 20px;
    max-width: 250px;
  }
}

/* 3 USPs explained section */

.keyUSPContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 40px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.keyUSPSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 90%;
  }
}

.infoSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}

.infoSectionDataLeft {
  display: flex;
  flex-direction: column;
  max-width: 640px;
  justify-content: center;
  text-align: left;
  margin-right: 80px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.infoSectionDataRight {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  justify-content: center;
  text-align: right;
  margin-left: 80px;

  @media (--viewportSmall) {
    order: 1;
    margin: 0;
  }
}

.infoSectionTitle {
  @apply --titleStyle1;
  margin-top: 0;
  margin-bottom: 20px;
}

.infoSectionText {
  @apply --bodyStyle1;
  margin-top: 0;
  margin-bottom: 0;
}

.infoSectionImage1 {
  width: 550px;
  height: 350px;
  background-image: url('../../assets/food-brand-wholesale-shop-vegshelf-b2b-marketplace.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportSmall) {
    width: 95%;
    height: calc(100vh/3);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.infoSectionImage2 {
  width: 550px;
  height: 350px;
  background-image: url('../../assets/sell-to-verified-wholesale-buyers-vegshelf-b2b-marketplace.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportSmall) {
    order: 2;
    width: 95%;
    height: calc(100vh/3);
    margin: 5px auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.infoSectionImage3 {
  width: 550px;
  height: 350px;
  background-image: url('../../assets/b2b-payments-vegshelf-wholesale-marketplace.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportSmall) {
    width: 95%;
    height: calc(100vh/3);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.registerSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;

  @media (--viewportSmall) {
    margin-top: 0;
  }
}

.registerSectionLink {
  @apply --primaryBlackbeanButtonStyles;
}

/* Supplier Reference Sectiom */

.categorySection {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 70px;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--black);
  
  @media (--viewportSmall) {
    padding: 30px 10px 50px;
    width: 100vw;
  }
}

.categorySection h2 {
  @apply --titleStyle1;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 1200px;

  @media (--viewportSmall) {
    margin: 20px 5px 0;
  }
}

.categoryParent {
  position: relative;
  max-width: 1100px;

  @media (--viewportSmall) {
    width: 95vw;
    overflow: hidden;
  }
}

.categoryContainer {
  max-width: 1100px;
  overflow: hidden;
}

.leftButton {
  cursor: pointer;
  position: absolute;
  top: 48%;
  left: 0;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

.leftButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;

  @media (--viewportSmall) {
    display: none;
  }
}

.rightButton { 
  cursor: pointer;
  position: absolute;
  top: 48%;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  right: 0;

  @media (--viewportSmall) {
    display: none;
  }
}

.rightButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;

  @media (--viewportSmall) {
    display: none;
  }
}

.buttonContainerBrands {
  display: none;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    padding: 30px 0 0;
    justify-content: center;
  }
}

.leftButtonMobile {

  @media (--viewportSmall) {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: var(--beigePlus40);
    border-radius: 25px;
    border: 1px solid var(--black);
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 30px;
  }
}

.leftButtonMobile img {

  @media (--viewportSmall) {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.rightButtonMobile { 

  @media (--viewportSmall) {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: var(--beigePlus40);
    border-radius: 25px;
    border: 1px solid var(--black);
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.rightButtonMobile img {

  @media (--viewportSmall) {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.commentContainer {
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.currentCategoryDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.categoryInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 475px;
  background-color: var(--beigeMinus70);
  border: 1px solid var(--black);
  border-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 20px;
  cursor: default;

  @media (--viewportSmall) {
    padding: 20px;
    border: none;
  }
}

.commentCompanyLogo {
  max-height: 100px;
  max-width: 300px;
  margin: 0 auto;

  @media (--viewportSmall) {
    margin: 0 auto 10px;
    max-height: 50px;
    max-width: 200px;
  }
}

.categoryComment {
  @apply --bodyStyle1;
  text-align: center;
  max-width: 450px;
  margin: 0 20px 20px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.commentAuthorSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  max-width: 300px;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authorInfo {
  @apply --titleStyle7;
  color: var(--beigePlus80);
  text-align: center;
  margin: 0;

  @media (--viewportSmall) {
    display: none;
  }
}

.referenceImage {
  max-height: 375px;
  max-width: 475px;
  cursor: default;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--black);
  border-left: none;

  @media (--viewportSmall) {
    border-radius: 50px;
    border-left: 1px solid var(--black);
    max-width: 80vw;
  }
}

/* Section - Invite your existing stockists */

.inviteSectionContainer {
  border-bottom: 1px solid var(--black);
  background-color: var(--beigePlus30);
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.inviteSectionContainerOne {
  background-image: url('../../assets/jay-and-joy-cremerie-paris.jpg');
  background-size: cover;
  width: 50%;
  height: 550px;
  border-right: 1px solid var(--black);
  background-position: center center;

  @media (--viewportSmall) {
    width: 100%;
    border-bottom: 1px solid var(--black);
    border-right: none;
    max-height: 300px;
  }
}

.inviteSectionContainerTwo {

  @media (--viewportSmall) {
    margin: 0;
  }
}

.inviteSectionText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;

  @media (--viewportSmall) {
    flex-basis: 100%;
    padding: 0 15px 0px;
  }
}

.inviteSectionDataTitle {
  @apply --titleStyle1;
  color: var(--beigePlus90);
  margin: 0 auto 10px;
  text-align: left;
  max-width: 600px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 30px auto 10px;
    text-align: center;
  }
}

.inviteSectionDataSubtitle {
  @apply --bodyStyle1;
  color: var(--beigePlus90);
  text-align: left;
  margin: 10px 0 50px;
  max-width: 550px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 10px auto 30px;
    text-align: center;
  }
}

.inviteSectionButtons {
  display: flex;
  margin: 0;

  @media (--viewportSmall) {
    text-align: center;
    margin: 10px auto 50px;
  }
}

.inviteSectionLink {
  @apply --primaryBlackbeanButtonStyles;
}


/* Section - Feature overview */

.orderDescriptionSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--beigeMinus70);
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 40px 10px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.orderDescriptionSection {
  width: 1280px;
  padding: 70px 0;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 95%;
    padding: 0;
    margin: 10px 0 20px;
  }
}

.orderDescriptionSection h2 {
  @apply --titleStyle1;
  text-align: center;
  margin: 0 auto 15px;
}

.orderDescriptionSection p {
  @apply --bodyStyle1;
  text-align: center;
  margin: 0 auto 20px;
}

.supplierUSPContainer {
  overflow: hidden;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  justify-content: center;
}

.swiperLeft {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 30px;
}

.swiperLeft img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.swiperRight { 
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
}

.swiperRight img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.screenItem {
  width: 615px;
  padding: 20px;
  background-color: var(--beige);
  border-radius: 10px;
  display: inline-block;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 15x;
    width: 90vw;
  }
}

.screenItem div {
  @apply --titleStyle7;
  text-transform: uppercase;
  margin: 10px auto;
  text-align: center;

  @media (--viewportSmall) {
    width: 75vw;
  }
}

.screenItem p {
  @apply --titleStyle7;
  font-weight: var(--fontWeightMedium);
  text-transform: none;
  font-size: 14px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    width: 75vw;
    font-size: 14px;
  }
}

.screenItem img {
  max-width: 500px;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: var(--boxShadowButton);
  margin-bottom: 15px;
  user-select: none;

  @media (--viewportSmall) {
    width: 75vw;
  }
}

/*FAQ Section */

.FAQSection {
  background: var(--beigeMinus70);
  background: linear-gradient(180deg, var(--beigeMinus70) 0%, var(--beigeMinus70) 35%, var(--beige) 100%);
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 60px 50px;
  border-bottom: 1px solid var(--black);
    
  @media (--viewportSmall) {
    padding: 30px 15px;
    width: 100vw;
  }
}

.sectionFAQTitle {
  @apply --titleStyle1;
  margin: 0 auto 20px;
  text-align: center;

  @media (--viewportSmall) {
    margin: 10px 0 20px;
  }
}

.questionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  justify-content: center;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.questionItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    width: 90vw;
    justify-content: flex-start;
  }
}

.questionItem:last-child {
  border-bottom: none;
  margin: 0 0 10px;
}

.question {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  @apply --titleStyle4;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  position: relative;
  width: 100%;
  margin: 0;

  @media (--viewportSmall) {
    justify-content: flex-start;
    padding-right: 40px;
  }
}

.question::after {
  position: absolute;
  width: 19px;
  height: 12px;
  top: 20px;
  right: 16px;
  content: '.';
  font-size: 0px;
  background-image: url('../../assets/chevron-down-000000.svg');
  background-size: 20px 20px;
  background-position: center center;
}

.answer {
  padding-top: 0;
  padding-bottom: 20px;
  display: none;
  max-width: 850px;
  @apply --bodyStyle3;
  margin: 0;
  
  @media (--viewportSmall) {
    padding-right: 40px;
  }
}

.answer a {
  @apply --bodyStyle3;
  color: var(--bluePlus35);
}

.answer a:hover {
  text-decoration: underline;
  color: var(--bluePlus35);
}

.selectedQuestion .answer {
  display: block;  
}

.selectedQuestion .question::after {
  display: block;
  transform: rotate(180deg);
}

/* Section - Ready to grow your food brand’s wholesale? */

.growWholessaleBusinessContainer {
  background-color: var(--beigePlus90);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 60px 50px;
  border-bottom: 1px solid var(--black);
  
  @media (--viewportSmall) {
    padding: 30px 15px 40px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.growWholesaleBusinessSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
    
  @media (--viewportSmall) {
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 10px 0 20px;
  }
}

.sectionTitle {
  @apply --titleStyle1;
  color: var(--beige);
  text-align: center;
  margin-bottom: 20px;
  margin: 0;
  
  @media (--viewportSmall) {
    margin: 20px 0;
  }
}

.inlineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
  }
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.blockNumber {
  @apply --titleStyle7;
  font-weight: 200;
  font-size: 75px;
  color: var(--beige);
  margin-top: 10px;

  @media (--viewportSmall) {
    font-size: 80px;
    width: 18%;
  }
}

.blockText {
  @apply --bodyStyle1;
  color: var(--beige);
  max-width: 305px;
  text-align: left;
  margin-left: 10px;

  @media (--viewportSmall) {
    width: 82%;
  }
}

.buttonSection {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.signUpButton {
  @apply --callToActionButtonStyles;
  margin-bottom: 10px;
}

/* Section - Vegshelf Supplier Blog */

.resourceContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 60px 50px;

  @media (--viewportSmall) {
    margin: 20px 0 10px;
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.resourcesSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    overflow: hidden;
    width: 90vw;
  }
}

.resourceInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (--viewportSmall) {
    align-items: center;
  }
}

.resourceInfoBlogTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  text-align: left;
  margin: 10px 0 20px;

  @media (--viewportSmall) {
    margin: 0 0 20px;
    text-align: center;
  }
}

.firstTextPartContainer {
  display: flex;
}

.resourceInfoBlogSubTitle {
  @apply --titleStyle1;
  margin: 0;
  max-width: 520px;

  @media (--viewportSmall) {
    text-align: center;
  }
}

.resourceInfoBlogText {
  @apply --bodyStyle1;
  margin-top: 20px;
  max-width: 500px;

  @media (--viewportSmall) {
    text-align: center;
  }
}

.resourceButtons {
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.resourcesReadMoreButton {
  @apply --primaryBlackbeanButtonStyles;
}

.resourceItems {
  width: calc(100% - 650px);
  max-width: 650px;
  overflow: hidden;
  position: relative;
  margin-left: 100px;

  @media (--viewportSmall) {
    overflow: visible;
    width: 90vw;
    margin: 0;
  }
}

.swiperButtonContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  justify-content: center;
}

.resourceLeftButton {
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;
}

.resourceLeftButton img {
  height: 18px;
  width: 18px;
}

.resourceItem {
  display: inline-block;
  width: 300px;
  user-select: none;

  @media (--viewportSmall) {
    width: 95vw;
    max-width: 400px;
  }
}

.resourceImage {
  width: 94%;
  margin: 4px;
  border: 1px solid var(--black);
  border-radius: 5px;

  @media (--viewportSmall) {
    width: 92%;
  }
}

.resourceRightButton {
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;

  @media (--viewportSmall) {
    right: 21px;
  }
}

.resourceRightButton img {
  height: 18px;
  width: 18px;
}

/* Social Media, Newsletter section */

.mediaSectionContainer {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px 60px;
  overflow: hidden;
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    width: 100vw;
    justify-content: center;
  }
}

.mediaSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
  }
}

.firstColumnMediaData {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.mediaSectionTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  text-align: center;
  margin: 0 auto 20px;

  @media (--viewportSmall) {
    margin-top: 10px;
  }
}

.mediaDescription {
  @apply --titleStyle2;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 20px;

  @media (--viewportSmall) {
    max-width: 80vw;
  }
}

.mediaButtons {
  align-self: center;
 
  @media (--viewportSmall) {
    margin-bottom: 60px;
  }
}

.mediaInstagram, .mediaLinkedIn,
.mediaTwitter, .mediaFacebook, 
.mediaTiktok {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  background-color: var(--beigePlus90);
  background-size: 24px 24px;
  margin-right: 20px;
  margin-left: 20px;
  background-position: center center;
  border-radius: 40px;
  display: inline-block;

  @media (--viewportSmall) {
    margin-right: 7px;
    margin-left: 7px;
  }
}

.mediaInstagram {
  background-image: url('../../assets/instagram-ffffff.svg');
}

.mediaLinkedIn {
  background-image: url('../../assets/linkedin-ffffff.svg');
}

.mediaTwitter {
  background-image: url('../../assets/twitter-ffffff.svg');
}

.mediaFacebook {
  background-image: url('../../assets/facebook-ffffff.svg');
}

.mediaTiktok {
  background-image: url('../../assets/tiktok-ffffff.svg');
}

.secondColumnMediaData {
  width: 50%;
  text-align: center;

  @media (--viewportSmall) {
    width: 100%;
    margin: 0 auto 30px;
  }
}

.mediaDescriptionRight {
  @apply --titleStyle2;
  text-align: center;
  max-width: 500px;
  margin: 20px auto;
}