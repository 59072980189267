@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    width: 90vw;
   }
}

.password {
  margin-top: 24px;

  @media (--viewportSmall) {
    margin-top: 32px;
  }
}

.bottomWrapper {
  text-align: center;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin: -5px auto 0;
  text-align: center;
}

.recoveryLink {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-decoration: underline;
}

.recoveryLinkInfo {
}

.loginButton {
  @apply --primaryBlackbeanButtonStyles;
  margin-top: 30px;
}

.formFields {

  @media (--viewportSmall) {
    width: 90vw;
   }
}

.formRow {
  text-align: center;
  margin-bottom: 15px;

  @media (--viewportSmall) {
    width: 90vw;
   }
}

.formField {
  width: 300px;
  display: inline-block;
  text-align: left;

  @media (--viewportSmall) {
    width: 80vw;
   }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  display: block;
  position: relative;
}

.requiredMarker {
  color: var(--red);
}

.errorMessage {
  @apply --bodyStyle3;
  display: inline-block;
  color: var(--red);
  position: absolute;
  width: 400px;
  padding-left: 5px;
}

.errorTextBlock {
  @apply --bodyStyle3;
  color: var(--red);
  width: 490px;
  height: 50px;
  margin: 10px auto;

  @media (--viewportSmall) {
    height: 30px;
    width: 80vw;
   }
}