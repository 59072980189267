@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Main page section */

.ordersPageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 50px 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.ordersSection {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  min-height: 510px;

  @media (--viewportSmall) {
    width: 90vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Tab section */

.tabs {
  @apply --titleStyle5;
  width: 180px;
  margin: 50px 0;

  @media (--viewportSmall) {
    margin: 15px 0 0 0;
    width: 100%;
    text-align: center;
  }
}

.tabs .tab {
  margin-bottom: 25px;

  @media (--viewportSmall) {
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
  }
}

/* Continued: Main page section */

.pageContent {
  /*@apply --darkBorder;*/
  margin: 50px 0 50px 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1000px;

  @media (--viewportSmall) {
    margin: 0;
    width: 100%;
    /*border: none;*/
  }
}