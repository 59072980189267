@import '../../styles/propertySets.css';

.desktopInputRoot {
  display: flex;
  margin-top: 0;
  margin-left: 5px;
}

.desktopIcon {
  border: none;
  padding-top: 3px;
  display: flex;
  width: 24px;
  align-self: stretch;
}

.desktopInput {
  flex-grow: 1;
  @apply --bodyStyle3;
  border: none;
  padding: 11px 25px 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: left;

    &:hover,
      &:focus {
      outline: none;
    }

    &:hover::placeholder,
      &:focus::placeholder {
    }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: var(--grey);
    transition: var(--transitionStyleButton);
  }
}