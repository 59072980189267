.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
/*
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   }*/
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
