@import '../../styles/propertySets.css';

.vegshelfInformationContainer {
  @media (--viewportSmall) {
    width: 100vw;
    flex-wrap: wrap;
  }
}

.vegshelfInformationBox1 {
  width: 980px;
  padding: 50px;
  @apply --darkBorder;
  margin-bottom: 90px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 20px;
    margin: 10px auto 20px;
  }
}

.mainTitle {
  @apply --titleStyle2;
  margin-top: 0;
  margin-bottom: 20px;
}

.contactOptions {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
  }
}

.singleContactSection {
  display: flex;

  @media (--viewportSmall) {
  }
}

.whatsappIcon, .emailIcon, .phoneIcon {
  background-position: center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  font-size: 0;
  margin: 5px 15px 0 0;

  @media (--viewportSmall) {
    width: 15%;
  }
}

.whatsappIcon {
  background-image: url('../../assets/whatsapp_000000.svg');
}

.emailIcon {
  background-image: url('../../assets/envelope-at_000000.svg');
}

.phoneIcon {
  background-image: url('../../assets/telephone_000000.svg');
}

.whatsappQRCode {
  background-image: url('../../assets/whatsappQRCode.png');
  background-position: center center;
  background-size: 80px 80px;
  width: 80px;
  height: 80px;
  font-size: 0;
  margin: 0 15px 5px 0;

}

.contactDetails {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    width: 85%;
  }
}

.contactTitle {
  @apply --titleStyle4;
  font-weight: var(--fontWeightMedium);
  margin: 0;
}

.contactText {
  @apply --bodyStyle3;
  margin: 0 0 30px;
}

.contactText a:hover {
  text-decoration: underline;
  color: var(--darkGrey);
}