@import '../../styles/propertySets.css';

.container {
  margin: 25px 0;
}

.name {
  @apply --titleStyle7;
  margin: 0;
}

.description {
  @apply --bodyStyle3;
  margin: 20px 0 8px;
}

.disabledDescription {
  color: var(--grey);
}

.error {
  color: var(--red);
}

.radioButtonsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.radioButton {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid var(--black);
  background-color: var(--white);
  cursor: pointer;
}

.radioLabel {
  @apply --bodyStyle3;
  margin: 0 30px 0 0;
  padding: 0;
}

.shippingFeeInputContainer {
  height: 40px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.shippingFeeInput {
  @apply --input;
  padding-right: 15px;
}

.shippingFeeInputDisabled {
  @apply --inputReadOnly;
}

.disabledDescription .error {
  color: var(--grey);
}

.shippingFeeInputError {
  @apply --inputError;
}

.exclVatText {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  color: var(--darkGrey);
  margin: 0 0 0 10px;
  width: 200px;
  text-align: left;
}