@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;
}

.tab:first-child {
  margin-left: 0;
}

.link {
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  width: 240px;
  @apply --marketplaceLinkStyles;
}

.selectedLink {
  @apply --coloredSelectedLink; 
  font-weight: var(--fontWeightSemiBold);
  width: 240px;
  pointer-events: none;
  user-select: none;
}
/*
.disabled {
  user-select: none;
  pointer-events: none;
  color: #b2b2b2;
  text-decoration: none;
}
*/