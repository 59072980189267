@import '../../styles/propertySets.css';

.singleProductCartModalContainer {
  @apply --modalTransparentBackground;
}

.singleProductCartModal {
  @apply --modalWhiteBackgroundSmall;
}

.closeButton {
  @apply --closeButton;
}

.modalContent {
}

.singleProductCartModalTitle {
  @apply --modalTitle;
}

.singleProductCartModalSubTitle1 {
  @apply --modalSubtitle;
}

.underline {
  text-decoration: underline;
  display: inline-block;
}

.singleProductCartModalSubTitle2 {
  @apply --modalSubtitle;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (--viewportSmall) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.goBackButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 10px;

  @media (--viewportSmall) {
    margin: 0;
  }
}

.saveInFavouritesButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0 10px;
  display: flex;
  flex-direction: row;

  @media (--viewportSmall) {
    order: -1;
    margin: 0 0 15px;
  }
}

.heartIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-image: url('../../assets/heart-ffffff.svg');
  background-position: right center;
}
