@import '../../styles/propertySets.css';

/* Styles used in ModalMissingInformation.js file */

.root {
}

/* Styles used in StripeAccountReminder.js file */

.modalTitle {
  @apply --modalTitle;
}

.modalMessage {
  @apply --modalSubtitle;
}

.bottomWrapper {
  text-align: left;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportSmall) {
    margin-top: 96px;
  }
}

.reminderModalLinkButton {
  @apply --primaryBlackbeanButtonStyles;
}

/* Styles used in EmailReminder.js file */

.email {
  font-weight: var(--fontWeightBold);
}

.helperLink {
  @apply --helperLink;
}

.error {
  @apply --generalErrorText;
  margin-top: 24px;
}

.modalIcon {
  height: 48px;
  margin-bottom: 12px;

  @media (--viewportSmall) {
    height: 64px;
    margin-bottom: 23px;
  }
}

.helperText {
  @apply --helperText;
}