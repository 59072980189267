@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.error {
  @apply --bodyStyle3;
  color: var(--red);
  margin: 8px 0 10px;
}

.input {
  width: 100%;
}

input.input {
  @apply --input;
}

input.input:read-only {
  @apply --inputReadOnly;
}

input.inputSuccess {
  @apply --inputSuccess;
}

input.inputError {
  @apply --inputError;
}

.requiredMarker {
  color: var(--red);
}

.errorMessage {
  @apply --bodyStyle3;
  margin-bottom: 8px;
  color: var(--red);
  margin-left: 10px;
}

.formField {
  width: 400px;
}

.formField label,
.formFullRowField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 8px;
}

.formField label.errorMessage {
  font-weight: var(--fontWeightRegular);
}


/* Unclear where below elements are used */

.longForm {
  width: 100%;
  margin-bottom: 24px;
}

  @media (--viewportSmall) {
  .longForm {
    width: calc(50% - 9px);
  }
}

.inputSuccess {
  border-bottom-color: var(--green);
}

.inputError {
  border-bottom-color: var(--red);
}

.formFields {
  margin-top: 20px;
}

.formRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

}

.formFullRowField {
  width: 100%;
}

.unsupportedCountryError {
  color: var(--red);
}