.root {
  width: 100%;
  height: 100%;
  background-color: #e7e7e7;
}

.mapRoot {
  width: 100%;
  height: 100%;
}
