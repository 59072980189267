.fieldRoot {
}

.inputRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.selectWrapper {
  flex-basis: calc(33% - 12px);
}

.select {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ffaa00;

  &:hover,
  &:focus {
    border-bottom-color: #000000;
    outline: none;
  }
}

.notSet {
  color: #b2b2b2;
}

.selectSuccess {
  color: #000000;
  border-bottom-color: #2ecc71;
}

.selectError {
  border-bottom-color: #F9652A;
}
