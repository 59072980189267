@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.feedbackContainer {
  background-image: url('../../assets/striped-umbrella-pattern-desktop.png');
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px 80px;
  position: relative;
  height: 400px;
  overflow: hidden;
  filter: drop-shadow(0 1px 0 var(--black));
  
  @media (--viewportSmall) {
    width: 100vw;
    background-image: url('../../assets/store-umbrella-style-pattern-mobile.png');
    height: 530px;
    display: inline-block;
    padding: 40px 15px;
  }
}

.leftButton {
  cursor: pointer;
  position: absolute;
  top: calc(50% - 40px);
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  left: 20vw;

  @media (--viewportSmall) {
    display: none;
  }
}

.leftButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;

  @media (--viewportSmall) {
    display: none;
  }
}

.rightButton { 
  cursor: pointer;
  position: absolute;
  top: calc(50% - 40px);
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  right: 20vw;

  @media (--viewportSmall) {
    display: none;
  }
}

.rightButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;

  @media (--viewportSmall) {
    display: none;
  }
}

.buttonContainer {
  display: none;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    padding: 30px 0 0;
    justify-content: center;
  }
}

.leftButtonMobile {

  @media (--viewportSmall) {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: var(--beigePlus40);
    border-radius: 25px;
    border: 1px solid var(--black);
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 30px;
  }
}

.leftButtonMobile img {

  @media (--viewportSmall) {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.rightButtonMobile { 

  @media (--viewportSmall) {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: var(--beigePlus40);
    border-radius: 25px;
    border: 1px solid var(--black);
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.rightButtonMobile img {

  @media (--viewportSmall) {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.commentContainer {
  text-align: center;
  display: inline-block;
  user-select: none;
  margin-bottom: 0;
  margin-top: 0;


  @media (--viewportSmall) {
    vertical-align: middle;
    margin: 0;
  }
}

.companyLogo {
  max-height: 50px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
  }
}

.comment {
  @apply --titleStyle4;
  color: var(--beigePlus90);
  font-style: italic;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (--viewportSmall) {
    @apply --bodyStyle3;
    margin: 0 15px;
    max-width: 95vw;
  }
}

.authorBox {
  display: flex;
  justify-content: center;

  @media (--viewportSmall) {
    display: inline-block;

  }
}

.avatar {
  max-height: 65px;
  max-width: 65px;
  border-radius: 100%;
  height: auto;
  width: auto;
  margin: 10px 15px 0 0;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    margin: 10px 0 0 0;
  }
}

.authorInfo {
  padding-top: 20px;

  @media (--viewportSmall) {
    padding-top: 10px;
  }
}

.authorName {
  @apply --bodyStyle3;
  margin: 0;
  text-align: left;
  color: var(--beigePlus90);

  @media (--viewportSmall) {
    @apply --bodyStyle4;
    width: 90vw;
    text-align: center;
  }
}