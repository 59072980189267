@import '../../styles/propertySets.css';

.root {
}

.select {
  @apply --bodyStyle3;
  @apply --darkBorder;
  font-weight: var(--fontWeightRegular);
  color: var(--darkGrey);
  border-radius: 3px;
  background-color: var(--white);
  padding-left: 15px;
  height: 40px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectSuccess {
  @apply --inputSuccess;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectError {
  @apply --inputError;
  color: var(--darkGrey);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.validatorMessage {
  display: none;
}