@import '../../styles/propertySets.css';

.paginationContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
  }
}

.paginationContainer :nth-child(1) {
  
  @media (--viewportSmall) {
    order: 2;
    margin-bottom: 10px;
  }
}

.paginationContainer :nth-child(2) {
  
  @media (--viewportSmall) {
    order: 1;
    margin-bottom: 10px;
  }
}

.paginationContainer :nth-child(3) {
  
  @media (--viewportSmall) {
    order: 3;
    margin-bottom: 10px;
  }
}

.pageNavButton {
  @apply --secondaryBlackbeanButtonStyles;
  @apply --titleStyle7;
  font-size: 13px;
  padding: 6px 20px;
  height: 30px;

  @media (--viewportSmall) {
    width: 120px;
  }
}

.disabledButton {
  border: 1px solid var(--lightGrey);
  color: var(--darkGrey);
  cursor: default;
}

.toPageLink {
  @apply --titleStyle7;
  font-size: 13px;
  padding: 4px 8px;
}

.toPageLink:hover {
  color: var(--darkGrey);
}

.currentPage {
  @apply --titleStyle7;
  font-weight: var(--fontWeightBold);
  font-size: 13px;
  padding: 3px 8px;
  background-color: var(--beige);
  border-radius: 100%;
}

.pages {
}

.prev, 
.pageNumbers, 
.next {
  flex: 1;
}

.prev {
  display: flex;
}

.pageNumbers {
  display: flex;
  justify-content: center;
}

.next {
  display: flex;
  justify-content: flex-end;
}