@import '../../styles/propertySets.css';

.root {
}

.helperLink {
  color: var(--darkGrey);
  text-decoration: underline;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.helperLink:hover {
  color: var(--grey);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

/* Modal window */

.modalContainer {
  @apply --modalWhiteBackgroundSmall;
  position: relative;

  @media (--viewportSmall) {
    width: 100vw;
    padding: 0 15px;
  }
}

.crossButton {
  @apply --closeButton;
}

.modalTitle {
  @apply --modalTitle;
  margin-bottom: 15px;

  @media (--viewportSmall) {
    margin-top: 20px;
    @apply --bodyStyle3;
    font-weight: var(--fontWeightBold);
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 80px;

  @media (--viewportSmall) {
    margin: 0;
    align-items: center;
  }
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
}

.confirmButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 15px 0;
}

.backButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 15px 20px 0 0;

  @media (--viewportSmall) {
    margin: 15px 0 0 0;
  }
}

/* Password input component */

.inputWrapper {
  @apply --darkBorder;
  display: flex;
  align-items: center;
  background-color: var(--white);
  width: 360px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.inputWrapperError {
  border: 1px solid var(--red);
}

.inputWithoutBorder {
  width: 360px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.inputWithoutBorder input:hover, 
.inputWithoutBorder input:focus {
  border: none;
}

.inputWithoutBorder input {
  border: none;
}

.inputTypePassword {
  width: 20px;
  height: 15px;
  background: url("/src/assets/eye-666561.svg") center no-repeat;
  cursor: pointer;
  margin-right: 15px;
}

.inputTypeText {
  width: 20px;
  height: 15px;
  background: url("/src/assets/eye-slash-666561.svg") center no-repeat;
  cursor: pointer;
  margin-right: 15px;
}

.inputHelpText {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin: 10px 0;
}

.resetPasswordInfo {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin: 10px 0 30px;
}