@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  z-index: var(--zIndexTopbar);
  position: relative;
}

.linkFormat {
  padding: 0 5px;
}

.desktopTopbar {
    display: block;

    @media (--viewportSmall) {
      display: none;
  }
}

.desktopTopbarFixed {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

/* Error that's displayed at the top right corner 
(e.g. when there's no network */

.genericError {
  position: fixed;
  top: 100px;
  right: -355px;
  opacity: 0;
  max-width: 340px;
  margin: 0 20px;
  transition: all ease-out 0.2s;
  transition-delay: 1s;
  background-color: var(--lightGrey);
  border-radius: 4px;
  z-index: var(--zIndexGenericError);
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 3px;
  background-color: var(--lightGrey);
  border: 1px solid var(--grey);
}

.genericErrorText {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  color: var(--black);
}

/* Topbar message section */

.messageDesktopTopbar {
  @apply --titleStyleTopbarMessage;
  border-bottom: 1px solid var(--black);
}

.messageDesktopTopbar a:hover {
  color: var(--beigePlus80);
}

/* Shopping cart topbar */

.secureDesktopTopbarContainer {
  background-color: var(--white);
  height: 120px;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    justify-content: space-around;
    padding: 15px;
  }
}

.backToMarketplaceSection {
  display: flex;
  align-items: center;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
}

.backToMarketplaceText {
  @apply --titleStyle7;
  font-size: 14px;
  margin-left: 10px;
  
  @media (--viewportSmall) {
    display: none;
  }
}

.backToMarketplaceText:hover {
  color: var(--darkGrey);
}

.logo {
  height: 40px;
  width: auto;
  max-width: 160px;
  object-fit: contain;
  object-position: center center;

  @media (--viewportSmall) {
    margin-left: 20px;
  }
}

.securePaymentText {
  @apply --titleStyle7;
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  text-align: right;

  @media (--viewportSmall) {
    @apply --titleStyle6;
    font-weight: var(--fontWeightBold);
    text-transform: uppercase;
    width: 50px;
  }
}

/* Not to delete the section below as it may be used for topbar notifications in future*/
/*
.notificationDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 17px;
  right: 17px;
  background-color: #F9652A;
  border: 2px solid #ffffff;

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
*/

/* ----------- MOBILE TOPBAR ----------- */

.mobileContainer {
  display: none;
  
  @media (--viewportSmall) {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    background-color: var(--white);
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
  }
}

.mobileAboutUsPage {
  display: none;
  
  @media (--viewportSmall) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 22px;
    background-color: var(--beige);
    border-top: 1px solid var(--black);
  }
}

.mobileApplyToSellPage {
  display: none;
  
  @media (--viewportSmall) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 22px;
    background-color: var(--beige);
    border-top: 1px solid var(--black);
  }
}

.mobileMenu {
  cursor: pointer;
  position: absolute;
  width: 70px;
  border-width: 0;
  padding: 20px 15px;
  top: 45px;
}

.mobileMenuBuyer {
  cursor: pointer;
  position: absolute;
  width: 70px;
  border-width: 0;
  padding: 20px 15px;
  top: 53px;
}

.mobileMenuSupplier {
  cursor: pointer;
  position: absolute;
  width: 70px;
  border-width: 0;
  padding: 0 15px;
  top: 65px;
}

.rootMenuIcon {
  height: 40px;
  width: 40px;
}

.messageMobileTopbar {
  display: none;

  @media (--viewportSmall) {
    @apply --titleStyleTopbarMessage;
  }
}

.mobileLogoAndSearchContainer {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileLogo {
  height: 40px;
  margin-bottom: 15px;
}

.searchContainerMobile {
  width: 78vw;
  min-width: 280px;
}

.searchFormMobile {
  height: 40px;
  background-color: var(--white);
  @apply --darkBorder;
}