@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  width: 880px;

  @media (--viewportSmall) {
    width: 90vw;
    align-items: left;
  }
}

.card {
  border: 1px solid var(--black);
  border-radius: 3px;
  background-color: var(--white);
  padding: 8px 15px;
  height: 40px;
}

.cardSuccess {
  border: 1px solid var(--black);
  border-radius: 3px;
}

.cardError {
  border: 1px solid var(--red);
  border-radius: 3px;
}

.errorMessage {
  color: var(--red);
}

.submitContainer {
}

.missingStripeKey {
  color: var(--red);
}

.cardHoldersNameField {
}

.formField {
  display: block;
  text-align: left;
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 10px;
  
}

.inputLabelMargin {
  @apply --bodyStyle3;
  margin-bottom: 10px;
}

.smallCardFields {
  text-align: left;
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.expiryField {
  width: 250px;
  margin-right: 30px;

  @media (--viewportSmall) {
    width: 100%;
    margin-right: 0;
  }
}

.cvcField {
  width: 250px;
  margin-right: 30px;

  @media (--viewportSmall) {
    width: 100%;
    margin-right: 0;
  }
}

.postalCodeField {
  width: 250px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.previewItemSeparator {
  border-top: 1px solid var(--grey);
  margin-top: 40px;
  margin-bottom: 10px;
}

.previewItemData {
  display: flex;
}

.previewItemFirstColumn {
  flex: 1;
}

.previewItemTitle {
  @apply --titleStyle7;
  margin: 0 0 10px;
}

.previewItemValue {
  @apply --bodyStyle3;
  word-wrap: break-word;
  max-width: 850px;
}

.confirmButtonContainer {
  text-align: center;
}

.termsError {
  width: 100%;
  @apply --bodyStyle3;
  color: var(--red);
  text-align: left;
  margin: 30px 0 0;
}

.confirmButton {
  margin: 40px 0;
  @apply --primaryBlackbeanButtonStyles;
}

.fieldRequired {
  color: var(--red);
}

.confirmRow {
  display: flex;
  margin-top: 15px;
}

.confirmRow input {
  border: 1px solid var(--black);
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.confirmRow label {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightRegular);
  margin-top: 2px;
  text-align: left;
}