@import '../../styles/propertySets.css';

.sectionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.formField {
  width: 400px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.formField select {
  width: 100%;
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 8px;
}

.requiredMarker {
  color: var(--red);
}

.formField label.errorMessage {
  font-weight: var(--fontWeightRegular);
}

.errorMessage {
  @apply --bodyStyle3;
  margin-bottom: 8px;
  color: var(--red);
  margin-left: 10px;
}

.selectCountry {
}

.bankDetailsStripeField {
}

.confirmRow {
  margin-top: 20px;
}

.confirmRow label {
  display: inline;
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  vertical-align: middle;
}

.confirmRow .errorMessage {
  margin: 0;
}

.savedInformation {
  @apply --bodyStyle3;
  margin-bottom: 20px;
  margin-top: 20px;
}

.accountInformationTitle {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 8px;
}

.savedCountry {
  @apply --inputReadOnly;
  width: 400px;
  padding-top: 9px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.unsetBtnStyles button {
  width: 400px;
  text-align: left;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.savedBankAccount {
  @apply --inputSuccess;
}

.savedBankAccount.inputSuccess {
  @apply --inputSuccess;
}

.error {
  @apply --bodyStyle3;
  color: var(--red);
  margin: 8px 0 20px;
}

.root {
  position: relative;
}

.disabled {
}

.termsLink {
  @apply --bodyStyle5;
  color: var(--darkGrey);
}

.termsLink:hover {
  color: var(--grey);
  cursor: pointer;
}

.stripeConnectedRow {
  display: flex;
  align-items: center;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.stripeConnectedRow p {
  text-align: left;
  margin-left: 10px;
  width: 220px;

  @media (--viewportSmall) {
    text-align: center;
    margin: 20px 0 0;
  }
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
}

.termsText {
  @apply --bodyStyle5;
  color: var(--darkGrey);
  margin: 0;
}

.missingStripeKey {
  color: var(--red);
}