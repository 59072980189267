@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.withPadding {
  padding: calc(2 * 6px);

  @media (--viewportMedium) {
    padding: calc(2 * 8px);
  }
}

.withMargin,
.defaultWrapperStyles {
  margin: calc(2 * 6px) 0;

  @media (--viewportMedium) {
    margin: calc(2 * 8px) 0;
  }
}

.navBar {
  flex-basis: 300px;
  flex-shrink: 0;
  composes: withPadding;
  box-shadow: var(--boxShadow);
}

.main {
  flex-grow: 1;
  composes: withPadding;

  @media (--viewportMedium) {
    padding: 48px 16px 0 16px;
  }
}

.examplesList {
  margin: 24px 0;
}

.example {
  padding: 0;
}

.link {
  color: #F9F1A1;
}

.groups {
  margin: 0 0 12px 0;
}

.group {
  text-transform: capitalize;
}

.selectedGroup {
  font-weight: bold;
  color: #eee581;
}

/* Typography.example */
.typographyContent {
}

.baselines {
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10M0 06h10M0 12h10" stroke="%23ccc" shape-rendering="crispEdges" /><path d="M0 18h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /></svg>');
  background-repeat: repeat;

  @media (--viewportMedium) {
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /><path d="M0 08h10M0 16h10M0" stroke="%23ccc" shape-rendering="crispEdges" /></svg>');
    background-repeat: repeat;
  }
}

.spacing2x {
  margin: calc(2 * 6px) 0;

  @media (--viewportMedium) {
    margin: calc(2 * 8px) 0;
  }
}

.fontsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-top: 19px;
  }
}

.fontCard {
  flex-basis: 300px;
  display: flex;
  flex-direction: column;

  margin-right: calc(2 * 6px);
  margin-bottom: calc(2 * 6px + 2px);

  @media (--viewportMedium) {
    margin-right: calc(2 * 8px);
    margin-bottom: calc(2 * 8px + 2px);
  }
}

.element {
  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
}

.description {
  flex-grow: 1;
  width: 100%;
  background-color: #f1f1f1;
  padding: calc(2 * 6px - 3px) calc(2 * 6px);

  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
  border-top-color: transparent;

  @media (--viewportMedium) {
    padding: calc(2 * 8px - 3px) calc(2 * 8px);
  }
}

.heroTitle {
  @apply --bodyStyle3;
}

.tinyFont {
  @apply --bodyStyle3;
}

/* Colors.example */

.colorsContent,
.colorsContainer {
  display: flex;
  flex-direction: column;
}

.colorsGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(4 * 6px);
}

.colorCard {
  flex-basis: 275px;
  height: 275px;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(4 * 6px);
  margin-right: calc(2 * 6px);
  overflow: hidden;

  border: 1px solid #e7e7e7;
}

.color {
  width: 100%;
  height: 66.6%;
}

.colorDescription {
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  padding: calc(2 * 6px);

  @media (--viewportMedium) {
    padding: calc(2 * 8px - 2px) calc(2 * 8px);
  }
}

.marketplaceColorBackground {
  composes: color;
  background-color: #F9F1A1;
}

.marketplaceColorLightBackground {
  composes: color;
  background-color: #F9F1A1;
}

.marketplaceColorDarkBackground {
  composes: color;
  background-color: #eee581;
}

.successColorBackground {
  composes: color;
  background-color: #2ecc71;
}

.failColorBackground {
  composes: color;
  background-color: #F9652A;
}

.attentionColorBackground {
  composes: color;
  background-color: #ffaa00;
}

.matterColorBackground {
  composes: color;
  background-color: #000000;
}

.matterColorAntiBackground {
  composes: color;
  background-color: #b2b2b2;
}

.matterColorDarkBackground {
  composes: color;
  background-color: #000000;
}

.matterColorNegativeBackground {
  composes: color;
  background-color: #e7e7e7;
}

.matterColorLightBackground {
  composes: color;
  background-color: #e7e7e7;
}
