@import '../../styles/propertySets.css';

.emptyStateContainer {
}

/* Main page section */

.content {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableRow.headerRow {
  background-color: var(--beigeMinus50);
  border-bottom: none;
}

.tableRow {
  width: 100%;
  padding: 30px 10px;
  display: flex;
}

/* NEED TO TEST */

.tableRow:last-child {
  border-bottom: none;
}

/* NEED TO TEST */

.tableRow td {
  flex: 1;
  @apply --bodyStyle3;
}

.tableRow td:nth-child(3) {
  flex: 2;
}

.headerRow td {
  @apply --titleStyle7;
  font-size: 14px;
}

.viewPrintLink {
  @apply --bodyStyle3;
  color: var(--bluePlus35);
  text-decoration: underline;
  cursor: pointer;
}

.pagination {
  margin-top: 30px;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.content th:nth-child(2),
.content td:nth-child(2),
.content th:nth-child(4),
.content td:nth-child(4),
.content th:nth-child(5),
.content th:nth-child(6) {

  @media (--viewportSmall) {
    display: none;
  }
}