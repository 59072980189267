@import '../../styles/propertySets.css';

.avatarBase {
  font-family: var(--fontVollkorn);
  border: 1px solid var(--black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    -210deg,
    var(--blue) 0%,
    var(--blueMinus60) 100%
  );
  color: var(--black);
}

.avatarBase:hover {
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
}

/* Small Avatar */

.root {
  composes: avatarBase;
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;
  border: 1px solid var(--black);
}

.initials {
  padding-top: 2px;
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--black);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--white);
}

.avatarImage:hover {
  box-shadow: var(--boxShadowButton);
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;
  width: 60px;
  height: 60px;
}

.mediumAvatar .initials {
  font-size: 28px;
  font-weight: var(--fontWeightBold);
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;
  width: 96px;
  height: 96px;
}

.largeAvatar .initials {
  font-size: 34px;
  font-weight: var(--fontWeightBold);
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}