@import '../../styles/propertySets.css';

.noSearchResultContainer {
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    margin: 60px auto;
    text-align: center;
  }
}

.buyersFavoriteOffset {
  margin: 20px auto;
}

.searchOffset {
  margin: 40px auto;
}

.listingsOffset {
}

.messagesOffset {
  @media (--viewportSmall) {
    margin: 90px auto;
  }
}

.orderAndRequestOffset {
  margin: 110px auto;

  @media (--viewportSmall) {
    margin: 60px auto;
  }
}

.cartOffset {
}

.invoicing {
}

.emptyStateIcon {
  height: 35px;
  width: 35px;
  margin-bottom: 15px;
}

.emojiNeutralIcon {
  background: url("../../assets/emoji-neutral-ebe8e7.svg") center no-repeat;
  background-size: contain;
}

.searchIcon {
  background: url("../../assets/search-ebe8e7.svg") center no-repeat;
  background-size: contain;
}

.messagesIcon {
  background: url("../../assets/envelope-ebe8e7.svg") center no-repeat;
  background-size: cover;
  width: 40px;
}

.orderAndRequestIcon {
  background: url("../../assets/box-seam-ebe8e7.svg") center no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.cartIcon {
  background: url("../../assets/cart3-ebe8e7.svg") center no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.invoicingIcon {
  background: url("../../assets/file-earmark-text-ebe8e7.svg") center no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.browseProductsLink {
  @apply --primaryBlackbeanButtonStyles;
}

.noSearchResultTitle {
  @apply --bodyStyle1;
  color: var(--darkGrey);
  margin: 0 auto 10px;
}

.noSearchResultText {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin: 0 auto 25px
}