@import '../../styles/propertySets.css';

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  width: auto;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}