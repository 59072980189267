@import '../../styles/propertySets.css';

.buttonLink {
  @apply --primaryBlackbeanButtonStyles;
}

.buttonLinkPrimary {
  @apply --primaryBlackbeanButtonStyles;
}

.buttonLinkSecondary {
  @apply --primaryBlackbeanButtonStyles;
}

.customButton {
  @apply --primaryBlackbeanButtonStyles;
  padding: 0;
  border-radius: 8px;
  height: 100px;
}

.customIcon {
  width: 24px;
  height: 24px;
  stroke: #fff;
  margin-right: 10px;
}
