@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
      width: 100vw;
      max-width: 767px;
      overflow-x: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
}

.loaderContainer {
  height: 500px;
  margin-top: 160px;

  @media (--viewportSmall) {
    margin-top: 0;
  }
}

.pageContainer {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

/* Page name section */

.pageHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding: 30px 50px;
  
  @media (--viewportSmall) {
    width: 100vw;
    padding: 15px 20px;
    height: 140px;
  }
}

.pageTitle {
  @apply --titleStyle1;
  font-size: 44px;
  color: var(--beigePlus80);
  text-align: center;

  @media (--viewportSmall) {
    font-size: 32px;
  }
}

/* Category sections */

.pageContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  max-width: 1280px;

  @media (--viewportSmall) {
    margin: 0;
    padding: 0;
  }
}

.sectionContainer {
  padding: 0;

  @media (--viewportSmall) {
    width: 100vw;
    margin: 0 auto;
  }
}

.sectionItem {
  display: flex;
  margin-bottom: 80px;

  @media (--viewportSmall) {
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

.sectionLogoBlock {
  display: flex;
  width: 100%;
  border-radius: 5px;
  padding: 30px 0 50px 30px;

  @media (--viewportSmall) {
    flex-direction: column;
    padding: 20px 20px 30px;
    border-radius: 0;
  }
}

.sectionNameAndImageContainer {
  display: flex;
  flex-direction: column;
}

.sectionName {
  @apply --titleStyle2;
  font-size: 28px;
  font-style: italic; 

  @media (--viewportSmall) {
    @apply --titleStyle7;
    font-size: 13px;
  }
}

.sectionLogo {
  height: 407px;
  width: 500px;
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  @apply --darkBorder;
  margin-top: 45px;

  @media (--viewportSmall) {
    height: 230px;
    width: 90vw;
    margin-top: 20px;
    border: none;
  }
}

.sectionBrandAndProductsBlock {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  width: calc(100% - 500px);

  @media (--viewportSmall) {
    padding: 0;
    width: 100%;
  }
}

.sectionBrandBlock {
  display: flex;
  margin: 0 0 20px 13px;

  @media (--viewportSmall) {
    margin: 20px 0;
  }
}

.noUnderline {
  text-decoration: none;
}

.noUnderline:hover {
  text-decoration: none;
}

.brandLogo {
  height: 60px;
  width: 60px;
  background-image: linear-gradient( -180deg, var(--lime) 0%, var(--blue) 100% );
  background-position: center center;
  background-color: var(--white);
  background-size: cover;
  border-radius: 100%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);
  font-family: var(--fontVollkorn);
  font-weight: var(--fontWeightSemiBold);
  font-size: 28px;
  padding-top: 2px;
  padding-left: 1px;
  color: var(--black);
}

.brandInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brandName {
  @apply --titleStyle7;
  font-size: 14px;
  margin: 0;
}

.brandAddress {
  @apply --bodyStyle4;
  margin: 3px 0 0 0;
}

.productsBlock {
  position: relative;
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
  }
}

.hiddenArrowBtn {
  display: none;
}

.resourceLeftButton {
  height: 40px;
  width: 40px;
  background-color: var(--white);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: calc(50% - 23px);

  @media (--viewportSmall) {
    left: 5px;
  }
}

.resourceLeftButton img {
  height: 18px;
  width: 18px;
}

.resourceItem {
  cursor: pointer;
  display: inline-block;
  user-select: none;

  @media (--viewportSmall) {
  }
}

.resourceRightButton {
  height: 40px;
  width: 40px;
  background-color: var(--white);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  right: 20px;
  top: calc(50% - 23px);
  position: absolute;

  @media (--viewportSmall) {
    right: 45px;
  }
}

.resourceRightButton img {
  height: 18px;
  width: 18px;
}

.sectionButtons {
  display: flex;
  justify-content: center;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
}

.buttonStyle {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0 15px;

  @media (--viewportSmall) {
    margin: 10px;
  }
}