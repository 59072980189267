@import '../../styles/propertySets.css';

.notificationsContainer {
  @apply --darkBorder;
  padding: 50px;
  min-height: 420px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
  }
}

.notificationsTitle {
  @apply --titleStyle3;
  margin: 0 0 20px;
}

.notificationsSubtitle {
  margin-top: 20px;
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
}

/* Table */
.notificationTable {
  width: 100%;
}

.notificationTable th {
  @apply --titleStyle7;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  background-color: var(--beigeMinus50);
  width: 200px;

  @media (--viewportSmall) {
    font-size: 13px;

  }
}

.notificationTable th:first-child {
  margin-bottom: 70px;
}

.notificationTable th:nth-child(2),
.notificationTable td:nth-child(2),
.notificationTable th:nth-child(3),
.notificationTable td:nth-child(3) {
  width: 400px;
}

.notificationTable label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  text-align: left;
  padding: 10px 0;
  overflow-wrap: break-word;
}

.checkboxBorder {
  @apply --darkBorder;
  background-color: var(--lightGrey);
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
  margin: 0 auto;
}

.tickMark {
  height: 18px;
  width: 18px;
  margin-bottom: 3px;
}

.notificationTable input {
  height: 18px;
  width: 18px;
  margin: 0 auto;
}

.pageButtons {
  margin-top: 30px;
}

.buttonSave {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 26px;
  width: auto;
  background-color: var(--lightGrey);
  text-align: center;
  padding: 10px 30px;
  color: var(--darkGrey);
  font-family: var(--fontHind);
  font-weight: var(--fontWeightSemiBold);
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.8px;
  line-height: 1.4;
  cursor: default;

  @media (--viewportSmall) {
    display: block;
    margin: 0 auto;
  }
}

.buttonSave:hover,
.buttonSave:focus {
  box-shadow: var(--boxShadowButton);
}