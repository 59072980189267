@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  border: 0.5pt solid #E9EAEC;
  background-color: #FFFFFF;
  color: #000000;
  font-family: Lato, serif;
  font-size: 9pt;
  padding-left: 11pt;
  padding-top: 3pt;
  padding-bottom: 3pt;
}

.cardSuccess {
  border-color: #E9EAEC;
}

.cardError {
  border-color: #F9652A;
}

.error {
  color: #F9652A;
}

.errorMessage {
  margin-top: 24px;
  color: #F9652A;
}

.paymentHeading {
  margin: 0 0 14px 0;
  color: #b2b2b2;

  padding-top: 8px;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.billingHeading {
  margin: 0 0 14px 0;
  color: #000000;
  font-family: Raleway, serif;
  font-size: 14pt;
  font-weight: 500;
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-bottom: 2px;
  }
  @media (--viewportLarge) {
    margin-bottom: 4px;
  }
}

.messageHeading {
  color: #b2b2b2;
  margin: 40px 0 14px 0;

  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    margin: 41px 0 26px 0;
  }
  @media (--viewportLarge) {
    margin: 40px 0 26px 0;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: #b2b2b2;
}

.message {
  border-bottom-color: #b2b2b2;
}

.submitContainer {
  margin-top: 48px;
  text-align: center;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.infoText {
  @apply --bodyStyle3;
  color: #b2b2b2;
  padding: 0;
  margin: 14px 0 0 0;
}

.submitButton{
  color: #FFFFFF;
  font-family: Lato, serif;
  font-size: 12pt;
  padding: 3pt 20pt;
  border: none;
  background-color: #3F5CDB;
  border-radius: 20px;
  display: inline-block;
  width: auto;
  height: auto;
  min-height: auto;
  line-height: 17pt;
  &:hover,&:focus {
    text-decoration: underline;
    color: #FFFFFF;
  }
}
.cancelButton{
  color: #000000;
  font-family: Lato, serif;
  font-size: 12pt;
  padding: 3pt 20pt;
  border: 2px solid #3F5CDB;
  background-color: #ffffff;
  border-radius: 20px;
  display: inline-block;
  width: auto;
  height: auto;
  min-height: auto;
  line-height: 17pt;
  cursor: pointer;
  margin-right: 12pt;
  &:hover,&:focus {
    text-decoration: underline;
    color: #000000;
  }
}

.missingStripeKey {
  color: #F9652A;
}

.paymentAddressField {
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.formField{
  margin-top: 10pt;
  display: block;
  text-align: left;
}
.formField label{
  color: #000000;
  font-family: Lato, serif;
  font-size: 9pt;
  letter-spacing: 0;
}

.requiredMarker{
  color: #FF0000;
}

.inputLabelMargin {
  margin: 0;
}

.smallCardFields {
  margin-top: 10pt;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.expiryField {
  width: 211px;
}

.cvcField {
  width: 101px;
  margin: 0 30px;
}

.postalCodeField {
  width: 211px;
}
