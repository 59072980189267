@import '../../styles/propertySets.css';

.billingDetailsContainer {
  @apply --darkBorder;
  padding: 50px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
  }
}

.billingDetailsTitle {
  @apply --titleStyle3;
  margin: 0;
}

.billingDetailsSubtitle {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin: 5px 0 30px;
}

/* Form styles */

.billingForm input {
  @apply --input;
}

.billingForm input:read-only,
.billingForm input:read-only:hover,
.billingForm input:read-only:focus {
  @apply --inputReadOnly;
  cursor: default;
  pointer-events: none;
}

.formField {
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin: 20px 0 8px;
}

.maxFormWidth {
}

.textInput input,
.textInput input:hover,
.textInput input:focus {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  padding: 10px 15px;
}

.textInput select,
.textInput select:hover,
.textInput select:focus {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  padding: 10px 15px;
}

.invalidInputValue input,
.invalidInputValue input:hover,
.invalidInputValue input:focus {
  border-color: var(--red);
}

.formTwoColumnsPart {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-self: center;
  }
}

.formFirstColumn {
  margin-right: 50px;
}

.formFirstColumn, 
.formSecondColumn {
  width: 100%;
}

.fieldInputContainer {
  display: flex;
}

.vatContainer {
  display: flex;
  flex-direction: column;
}

.bottomWrapper {
  margin-top: 30px;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;

  @media (--viewportSmall) {
    display: block;
    margin: 0 auto;
  }
}