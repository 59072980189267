@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.profileCompanyContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

/* Cover section */

.coverContainer { 
  width: 100%;
  padding: 30px 0 0 0;
  width: 1280px;

  @media (--viewportSmall) {
    padding: 0;
  }
}

.coverPhotoContainer {
  position: relative;
  height: 270px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;

  @media (--viewportSmall) {
    height: 190px;
  }
}

/* Company key data section */

.profileCompanySection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
  flex-wrap: wrap;
  width: 90vw;
  }
}

.companyInfoPanel {
  display: flex;
  padding: 0 50px 30px;
  min-height: 350px;

  @media (--viewportSmall) {
    flex-direction: column;
    width: 100%;
    padding: 0 0 10px;
  }
}

.companyInfoFirstColumn {
  position: relative;
  width: 50%;
  margin: 0;
  
  @media (--viewportSmall) {
    width: 100%;
  }
}

.companyLogo {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  border: 1px solid var(--black);
  border-radius: 100%;
  left: 0;
  top: -110px;

  @media (--viewportSmall) {
    position: static;
    margin: -110px auto 0;
  }
}

.companyLogoInitials {
  @apply --titleStyle1;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  height: 100%;
  background-color: var(--beige);
  border-radius: 100%;
}

.companyName {
  @apply --titleStyle3;
  margin: 25px 25px 5px 220px;
  word-wrap: normal;

  @media (--viewportSmall) {
    text-align: center;
    margin: 15px auto 5px;
  }
}

.companyLocation {
  @apply --bodyStyle2;
  margin: 0 25px 50px 220px;

  @media (--viewportSmall) {
    text-align: center;
    margin: 0 auto 30px;
  }
}

.companyDetails {
  justify-content: space-between;
  display: flex;
}

.companyPropertyRow {
  margin-top: 0;
  margin-bottom: 18px;
}

.companyFieldLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightSemiBold);
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.companyFieldValue {
  @apply --bodyStyle3;
  display: inline-block;
  vertical-align: top;
}

.companyFieldValueWebsite {
  @apply --bodyStyle3;
  display: inline-block;
  vertical-align: top;
}

.companyFieldValueWebsite:hover {
  color: var(--darkGrey);
}

.companyInfoSecondColumn {
  width: 50%;
  margin: 0;

  @media (--viewportSmall) {
    width: 100%;
    margin-top: 32px;
  }
}

.editCompanyInfo {
  margin: 25px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (--viewportSmall) {
    margin: 0;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.contactBrandButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 0;
}

.companyDescriptionLabel {
  @apply --titleStyle7;
  margin-top: 74px;
  margin-bottom: 5px;

  @media (--viewportSmall) {
    margin-top: 50px;
  }
}

.companyDescriptionValue {
  @apply --bodyStyle3;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.emptyDescription {
  @apply --bodyStyle3;
  color: var(--darkGrey);
}

.companyImportantsLabel {
  @apply --titleStyle7;
  margin-top: 30px;
  margin-bottom: 5px;
}

.companyImportants {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;

  @media (--viewportSmall) {
    justify-content: center;
  }
}

.companyImportantItem {
  font-family: var(--fontMontserrat);
  font-weight: var(--fontWeightMedium);
  background-color: var(--beigeMinus50);
  border: 1px solid var(--black);
  color: var(--black);
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  padding: 9px 10px;
  border-radius: 5px;
  height: 30px;
  margin: 0 10px 10px 0;

  @media (--viewportSmall) {
    margin: 0 5px 10px 5px;
  }
}

/* Image section */

.imagesContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px;

  @media (--viewportSmall) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 20px;
    padding: 0 50px;
  }
}

.imageItem {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 5px;
}

.mainShopImage {
  width: calc(50% - 15px);
  height: 383px;

  @media (--viewportSmall) {
    width: 100%;
    height: 350px;
  }
}

.secondShopImage {
  width: calc(25% - 15px);
  height: 383px;

  @media (--viewportSmall) {
    width: 100%;
    height: 350px;
    margin-top: 30px;
  }
}