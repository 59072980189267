@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.supplierForm {
    @apply --bodyStyle3;
}

.supplierForm button {
    @apply --primaryBlackbeanButtonStyles;
}

.supplierForm input {
    border: 1px solid var(--black);
    border-radius: 3px;
    margin: 20px auto 30px;
    padding-left: 15px;
    height: 40px;
    max-width: 500px;

    @media (--viewportSmall) {
        width: 80vw;
        margin: 20px auto 30px;
    }
}

.supplierForm p {
    @apply --bodyStyle3;
    color: var(--black) !important;
}