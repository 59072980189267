@import '../../styles/propertySets.css';

.root {
  min-width: 1200px;
  background-color: #F4EEEA;
}

.formContainer { 
  background-size: cover;
  background-position: left top;
}