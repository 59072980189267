@import '../../styles/propertySets.css';

.pageContent {
    background-color: #ffffff;
}
.topProducts{
    height: 262pt;
    background-color: #FAF7F5;
    display: flex;
    overflow: hidden;
}
.topProductInfo{
    width: 216pt;
    padding-left:50pt;
    padding-right:50pt;
    padding-top: 30pt;
}
.topProductContainer{
    display: flex;
    overflow-x: auto;
    flex: 1;
}
.topProductItem{
    margin-left: 16pt;
    margin-right: 16pt;
    margin-top: 20pt;
}
.topProductImage{
    height: 175pt;
    width: 175pt;
    background-size: cover;
    background-position: center center;
}
.topProductTitle{
    font-family: Lato;
    font-size: 10pt;
    color: #000000;
    text-align: center;
    line-height: 15pt;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 175pt;
}
.topProductTitle a{
    color: #000000;
}
.topProductDesc{
    color: #A3A3A3;
    font-family: Lato;
    font-size: 9pt;
    text-align: center;
    line-height: 15pt;
    overflow: hidden;
    overflow-wrap: break-word;
    hyphens: auto;
    width: 175pt;
    overflow: hidden;
    height: 46pt;
}
.newThisWeekLabel{
    font-family: "Nothing You Could Do";
    font-size: 24pt;
    color: #000000;
}
.newThisWeekAllCategoriesLabel{
    margin-top: 45pt;
    font-family: "Nothing You Could Do";
    font-size: 26pt;
    color: #000000;
}
.inCategoryLabel{
    font-family: Raleway;
    font-size: 24pt;
    color: #000000;
    font-weight: 600;
}
.productGrid{
    flex: 1;
}
.gridContainer{
    display: flex;
    padding-left: 50pt;
    padding-right: 60pt;
    margin-bottom: 25pt;
}
.productFilter{
    width: 235pt;
}
.productsHeader{
    text-align: center;
}
.containerTitle{
    color: #000000;
    font-family: Raleway;
    font-size: 18pt;
    text-align: center;
}
.containerSubTitle{
    color: #000000;
    font-family: Lato;
    font-size: 13pt;
    text-align: center;
}
.filterClearButton{
    box-sizing: border-box;
    padding: 3pt 18pt;
    border: 1px solid #E9EAEC;
    background-color: #3F5CDB;
    color: #ffffff;
    border-radius: 21pt;
}
.filterClearButton:disabled{
    box-sizing: border-box;
    padding: 3pt 18pt;
    border: 1px solid #E9EAEC;
    background-color: #A3A3A3;
    border-radius: 21pt;
    color: #ffffff;
}
.dataContent{
    width:100%;
}
.filterFieldItem{
    color: #000000;
    font-family: Lato;
    font-size: 10pt;
}
.filterName{
    color: #000000;
    font-family: Raleway;
    font-size: 11pt;
    font-weight: 500;
    margin-bottom: 3pt;
}
.filterOption{
    display: flex;
    margin-bottom: 5pt;
}
.filterOption label{
   font-weight: normal;
   padding: 0pt;
}
.filterOption input{
   width: auto;
   margin-right: 11pt;
}
.filterOption input:checked~Label{
   font-weight: bold;
}
.seeMoreButton{
    color: #A3A3A3;
    font-family: Lato;
    font-size: 9pt;
    padding: 0pt;
    margin: 0pt;
    display: block;
    margin-left: 0pt;
    margin-top: -7pt;
}
.seeMoreButton span{
    margin-left:14pt
}
.productPager{
    padding-top: 30pt;
    border-top: 2pt solid #EBE8E7;
    margin-bottom: 45pt;
    margin-left: 284pt;
    margin-right: 108pt;
}
.arrow {
    border: solid#A3A3A3;
    border-width: 0 1pt 1pt 0;
    display: inline-block;
    padding: 3px;
    margin-left: 2pt;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
