@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  margin-top: 160px;
  height: 560px;
  overflow: hidden;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.sectionHeroContainerOne {
  height: 560px;
  background: var(--beige);
  width: 70%;
  
  @media (--viewportSmall) {
    display: none;
  }
}

.sectionHeroContainerTwo {
  background-image: url('../../assets/checkered-pattern.png');
  background-repeat: repeat-x;
  background-repeat: repeat-y;
  width: 30%;
  
  @media (--viewportSmall) {
    display: none;
  }
}

.sectionHeroContainerThree {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    position: relative;
    background: var(--beige);
  }
}

.heroText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 80px 15px 0;

  @media (--viewportSmall) {
    flex-basis: 100%;
    margin: 10px 0;
  }
}

.heroMainTitle {
  @apply --titleStyle1;
  color: var(--beigePlus90);
  margin: 0 auto 10px;
  text-align: center;
  max-width: 670px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 30px auto 10px;
  }
}

.heroMainTitle span:nth-of-type(1) {
  background-image: url('../../assets/blue-text-highlight.png');
  background-position: right bottom;
  background-size: 100% 95%;
}

.heroSubTitle {
  @apply --bodyStyle1;
  color: var(--beigePlus90);
  text-align: center;
  margin-bottom: 70px;
  max-width: 640px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 10px auto 30px;
  }
}

.signUpButtonRegistered {
  @apply --callToActionButtonStyles;
  margin: 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (--viewportSmall) {
    direction: column;
    flex-wrap: wrap;
    width: 80vw;
    margin: 0 auto 20px;
  }
}

.signUpButton {
  @apply --callToActionButtonStyles;

  @media (--viewportSmall) {
    margin: 0 5px;
    align-self: flex-start;
  }
}

.registerButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0 20px 0 0;

  @media (--viewportSmall) {
    margin: 0 5px 10px;
    align-self: flex-end;
  }
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 560px;

  @media (--viewportSmall) {
    height: 280px;
  }
}

.imageOne {
  width: 520px;
  height: 525px;

  @media (--viewportSmall) {
    max-height: 280px;
    max-width: 280px;
    width: calc(100vw/1.4);
    height: calc(100vw/1.4);
  }
}

.imageTwo {
  width: 200px;
  height: auto;
  margin: 0 0 30px -120px;

  @media (--viewportSmall) {
    position: absolute;
    width: 150px;
    left: 150px;
    right: 0;
  }
}