@import '../../styles/propertySets.css';

.root {
}

.password {
  margin-top: 24px;

  @media (--viewportSmall) {
    margin-top: 32px;
  }
}

.submitButton {
  display: flex;
  @apply --primaryBlackbeanButtonStyles;
  margin: 30px auto;
  text-align: center;
}