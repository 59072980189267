@import '../../styles/propertySets.css';

.root {
  background-color: #ffffff;
}
.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.profileLink {
  @apply --bodyStyle3;

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 12px 16px 5px 16px;

  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}
