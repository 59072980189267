@import '../../styles/propertySets.css';

.formTwoColumnsPart {
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    justify-content: flex-start;
    margin-top: 0;
    flex-direction: column;
  }
}

.formFirstColumn, .formSecondColumn {
  width: 360px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField { 
  width: 100%;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField select { 
  width: 100%;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  margin-top: 20px;
  text-align: left;
  
  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.requiredMarker {
  color: var(--red);
}

.readonlyInput input,
.readonlyInput input:hover,
.readonlyInput input:focus,
.readonlyInput input:disabled {
  @apply --darkBorder;
  background-color: var(--lightGrey);
  color: var(--grey);
  cursor: default;
}

.labelDescriptionMaxSize {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  margin-left: 5px;
}

.errorMessage {
  margin-left: 10px;
  color: var(--red);
  font-weight: var(--fontWeightRegular);
}

.descriptionInput textarea {
  box-sizing: border-box;
  height: 150px;
  padding: 10px;
}

.formButtonsPart {
  margin: 30px auto 20px;
  text-align: center;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
}