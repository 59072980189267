@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Main page section */

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 50px 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.messagesSection {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  min-height: 510px;

  @media (--viewportSmall) {
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Tabs section */

.tabs {
  @apply --titleStyle5;
  width: 180px;
  margin: 50px 0;

  @media (--viewportSmall) {
    margin: 20px 0 0 0;
    width: 100%;
    text-align: center;
  }
}

.tabs .tab {
  margin-bottom: 25px;

  @media (--viewportSmall) {
    margin-bottom: 15px;
    width: 100%;
  }
}

/* Continued: Main page section */

.pageContent {
  @apply --darkBorder;
  margin: 50px 0 50px 50px;
  padding: 30px;
  flex: 1;
  display: flex;
  width: 1000px;

  @media (--viewportSmall) {
    margin: 0;
    width: 100%;
    padding: 0;
    border: none;
  }
}

.messagesContainer {
  display: flex;
  width: 100%;
}

.contacts {
  width: 40%;
  border-right: 1px solid var(--grey);

  @media (--viewportSmall) {
    width: 25%;
    background-color: var(--beigeMinus70);
  }
}

.contactItem {
  padding: 20px;
  display: flex;
  border-bottom: 1px solid var(--grey);
  align-items: center;

  @media (--viewportSmall) {
    padding: 10px 0;
  }
}

.contactItemActive {
  background-color: var(--beigeMinus50);

  @media (--viewportSmall) {
    background-color: var(--beige);
  }
}

.contactItem:hover {
  cursor: pointer;
}

.contactLogo {
  height: 60px;
  width: 60px;
  background-image: linear-gradient(
    -210deg,
    var(--blue) 0%,
    var(--blueMinus60) 100%
  );
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);

  @media (--viewportSmall) {
    display: none;
  }
}

.companyLogoInitials {
  @apply --titleStyle2;
  padding-top: 7px;
}

.contactInfo {
  flex: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    margin-left: 10px;
  }
}

.contactItemActive .contactInfo {
  border-bottom: none;
}

.contactTitleRow {
  display: flex;
  justify-content: space-between;
  
  @media (--viewportSmall) {
    flex-direction: column;
  }
}

.contactName {
  @apply --titleStyle7;
  margin: 0 10px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;

  @media (--viewportSmall) {
    max-width: unset;
    white-space: unset;
    text-overflow: unset;
  }
}

.contactItemActive .contactName {
  font-weight: var(--fontWeightBold);

  @media (--viewportSmall) {
    margin-bottom: 5px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.contactLastMsgDate {
  @apply --titleStyle7;
  font-weight: var(--fontWeightMedium);
  font-size: 11px;
  color: var(--darkGrey);
}

.contactItemActive .contactLastMsgDate {
  font-weight: var(--fontWeightMedium);
}

.contactShortMessage {
  @apply --bodyStyle4;
  overflow: hidden;
  word-wrap: break-word;
  flex: 1;

  @media (--viewportSmall) {
    display: none;
  }
}

.contactItemActive .contactShortMessage {
  font-weight: var(--fontWeightMedium);
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 30px 0 30px 30px;
  min-height: 100%;
  height: auto;

  @media (--viewportSmall) {
    padding: 20px 10px;
  }
}

.mainContactInfo {
}

.mainContactLogo {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    -210deg,
    var(--blue) 0%,
    var(--blueMinus60) 100%
  );
  border: 1px solid var(--black);
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    height: 50px;
    width: 50px;
  }
}

.mainContactLogo:hover {
  text-decoration: none;
}

.mainContactTitle {
  @apply --titleStyle7;
  margin: 10px auto 0;
  max-width: 400px;
  text-align: center;
  display: block;

  @media (--viewportSmall) {
    margin: 10px 5px 5px;
  }
}

.mainContactSubTitle {
  @apply --bodyStyle3;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 30px;
}

.messageItems {
  max-height: 450px;
  max-width: 580px;
  overflow: auto;
  position: relative;
}

.subjectMessageItem {
}

.groupMessageItem {
  @apply --titleStyle7;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  color: var(--darkGrey);
  text-align: center;
}

.relativeGroupContainer {
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
}

.groupDivider {
  width: 90%;
  margin: 0 auto;
  height: 1px;
  background-color: var(--grey);

  @media (--viewportSmall) {
    width: 100%;
  }
}

.groupDate {
  background-color: var(--white);
  z-index: 2;
  padding: 5px 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.textMessageItem {
  display: flex;
  margin-top: 5px;
  margin-bottom: 40px;
}

.messageAuthorLogo {
  height: 40px;
  width: 40px;
  background-image: linear-gradient(
    -210deg,
    var(--blue) 0%,
    var(--blueMinus60) 100%
  );
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);
  font-family: var(--fontVollkorn);
  padding-top: 3px;
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--black);
}

.messageItemContent {
  flex: 1;
}

.messageItemAuthor {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  text-align: left;
  margin: 0 10px 12px;

  @media (--viewportSmall) {
    margin: 0 10px 12px;
    display: flex;
    flex-direction: column;
  }
}

.messageTime {
  @apply --bodyStyle4;
  color: var(--grey);
  padding-left: 20px;

  @media (--viewportSmall) {
    padding-left: 0;
  }
}

.messageItemText {
  @apply --bodyStyle3;
  text-align: left;
  margin: 0;
  overflow-wrap: anywhere;
  padding: 0 10px;
}

.newMessageForm {
  margin-top: 30px;
}

.newMessageInput {
  border-radius: 5px;
  border: 1px solid var(--grey);
  box-sizing: border-box;
  height: 160px;
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  @apply --bodyStyle3;
}

.newMessageButtons {
  text-align: center;
  margin-top: 20px;

  @media (--viewportSmall) {
    margin-bottom: 20px;
  }
}

.sendButton {
  @apply --primaryBlackbeanButtonStyles;
  border: none;
}