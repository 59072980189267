@import '../../styles/propertySets.css';

/* Account details form */

.accountDetailsSection {
  margin-top: 0;
  display: flex;
  justify-content: space-between;

  @media (--viewportSmall) {
    justify-content: center;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
}

.inputColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftInputColumn {
  flex: 1;
  margin-right: 50px;
  width: 400px;

  @media (--viewportSmall) {
    width: 85vw;
    margin: 0;
  }
}

.inputMargin {
}

.rightInputColumn {
  flex: 1;
  width: 400px;

  @media (--viewportSmall) {
    width: 85vw;
  }
}

.fieldPhoneInput {
  width: 100%;
  position: relative;
  display: flex;

  @media (--viewportSmall) {
    width: 85vw;
  }
}

.phoneCode select,
.phoneCode select:focus,
.phoneCode select:hover {
  width: 130px;
  padding-left: 15px;
}

.fieldWithCountry {
  flex: 1;
  margin-left: 15px;
  position: relative;
}

.phoneCodeInput input,
.phoneCodeInput input:focus,
.phoneCodeInput input:hover {
  padding: 2px 0 0 45px;
}

.emptyPhoneCode input,
.emptyPhoneCode input:focus,
.emptyPhoneCode input:hover {
  padding-left: 25px;
}

.inputCodePlaceholder {
  @apply --bodyStyle3;
  position: absolute;
  left: 15px;
  top: 11px;
  width: 50px;
}

.customLabel {
  margin: 20px 0 8px;
}

.customLabelText {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
}

.mandatoryField {
  color: var(--red);
}

.customLabelText span.errorMsg {
  font-weight: var(--fontWeightRegular);
}

.errorMsg {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 8px;
  color: var(--red);
  margin-left: 10px;
}

/* E-mail verification feedback */

.infoText {
  @apply --bodyStyle3;
  display: inline-block;
  padding-left: 22px;
  background-repeat: no-repeat;
  background-position: top 2px left;
  margin: 15px 0 0;
}

.emailVerified {
  composes: infoText;
  background-image: url("/src/assets/check-circle-green.svg");
  background-position-y: center;
  color: var(--green);
}

.emailUnverified {
  composes: infoText;
  background-image: url("/src/assets/check-circle-f9652a.svg");
  background-position-y: center;
  color: var(--red);
}

.pendingEmailUnverified {
  composes: infoText;
  color: var(--red);
  width: 100%;
  background-image: url("/src/assets/check-circle-f9652a.svg");
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.checkInbox {
  color: var(--darkGrey);
}

.tooMany {
  color: var(--black);
}

.emailSent {
  color: var(--black);
}

.helperLink {
  color: var(--darkGrey);
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.helperLink:hover {
  color: var(--grey);
}

/* Modal window - Confirm Account detail changes */

.modalBackground {
  @apply --modalTransparentBackground;
}

.modalContainer {
  @apply --modalWhiteBackgroundSmall;
  position: relative;
}

.fixedPosition {
}

.crossButton {
  @apply --closeButton;
}

.confirmChangesTitle {
  @apply --modalTitle;
}

.confirmChangesInfo {
  @apply --modalSubtitle;
  margin-bottom: 15px;
}

.resetPasswordInfo {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  color: var(--darkGrey);
  margin: 10px auto 0;
  width: 360px;

  @media (--viewportSmall) {
    width: 100%;
  }
}

.genericError {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  margin: 10px auto;
}

.confirmButton {
  @apply --primaryBlackbeanButtonStyles;
  margin: 15px 0;

  @media (--viewportSmall) {
    order: -1;
    margin: 10px 0;
  }
}

.backButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 15px 20px 0 0;

  @media (--viewportSmall) {
    margin: 10px 0;
  }
}

/* ACCOUNT DETAILS: Styles for password confirmation */


.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: center;

  @media (--viewportSmall) {
    flex-direction: column;
    align-items: center;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

/* General markup */

.root {
}

.bottomWrapper {
  margin: 30px 0 0;
}

.saveChangesButton {
  @apply --primaryBlackbeanButtonStyles;
  
  @media (--viewportSmall) {
    display: block;
    margin: 0 auto 30px;
  }
}

.error {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightRegular);
  color: var(--red);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;
}