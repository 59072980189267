@import '../../styles/propertySets.css';

.root {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.flexWrapper {
}

.pageContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 0 0 90px;
  margin-top: 160px;

  @media (--viewportSmall) {
    padding: 0 0 30px;
    flex-wrap: wrap;
    width: 100vw;
    margin-top: 0;
  }
}

.pageHeader {
  padding: 50px;
}

.pageHeader h1 {
  @apply --titleStyle3;
  font-size: 32px;
  text-align: center;
  margin: 0;
}

.pageHeader h2 {
  @apply --bodyStyle1;
  font-weight: var(--fontWeightRegular);
  text-align: center;
  margin: 5px 0;
}

.pageHeader h5 {
  @apply --bodyStyle2;
  font-weight: var(--fontWeightRegular);
  color: var(--darkGrey);
  text-align: center;
  margin: 10px 0;
}

.productContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  padding: 0 50px;
  min-height: 305px;

  @media (--viewportSmall) {
  flex-wrap: wrap;
  width: 90vw;
  }
}