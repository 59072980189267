@import '../../styles/propertySets.css';

.customSelect {
    display: block;
    cursor: pointer;
    position: relative;
    min-width: 100px;
}

.selectHeader {
    @apply --bodyStyle4;
    @apply --darkBorder;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: var(--white);
    user-select: none;
}

.isDisabled {
    color: var(--grey);
    cursor: default;
    @apply --greyBorder;
}

.selectHeader img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20px;
    padding-left: 10px;

}

.customSelect .list {
    margin: 0;
    height: 150px;
    overflow: auto;
    display: none;
    background-color: var(--white);
    position: absolute;
    width: 100%;
    box-shadow: var(--boxShadowFormBackground);
    border-radius: 5px;
    border: 1px solid var(--black);
    border-top: none;
}

.customSelect .list.isOpened {
    display: block;
}

.listItem {
    @apply --bodyStyle4;
    padding: 5px 0;
    text-align: center;
    cursor: pointer;
    user-select: none;
    padding-left: 6px;
}

.listItem:hover {
    background-color: var(--beigeMinus50);
}