@import '../../styles/propertySets.css';

.calendarCell {
  @apply --bodyStyle4;
  display: inline-block;
  width: 39px;
  height: 39px;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
}

.disabledCell {
  @apply --bodyStyle4;
  color: var(--grey);
  cursor: default;
}

.selectedCell {
  @apply --bodyStyle4;
  font-weight: var(--fontWeightBold);
}

.selectedCell .cellContent {
  @apply --darkBorder;
  background-color: var(--beigeMinus50);
  padding: 10px;
}

.modalCalendarContainer {
  @apply --modalTransparentBackground;

  @media (--viewportSmall) {
    width: 100vw;
  }
}

.calendarModal {
  @apply --modalWhiteBackgroundSmall;

  @media (--viewportSmall) {
    width: 100vw;
    margin: 0;
    padding: 40px 5px 10px;
  }
}

.crossButton {
  @apply --closeButton;
}

.calendarTitle {
  @apply --modalTitle;
}

.calendarSubTitle {
  @apply --modalSubtitle;
}

.calendarContainer {
  margin: 0 auto 30px;
  width: 319px;

  @media (--viewportSmall) {
    width: 90%;
    display: flex;
    justify-content: center;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.primaryButton {
  @apply --primaryBlackbeanButtonStyles;
}

.primaryButtonRoot:disabled {
  pointer-events: none;
  cursor: none;
}

.secondaryButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin-right: 20px;
}