@import '../../styles/propertySets.css';

.root {
  @apply --bodyStyle3;
}

.bottomWrapper {
  text-align: center;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.modalTitle {
  @apply --modalTitle;
}

.modalMessage {
  @apply --modalSubtitle;
}

.error {
  @apply --bodyStyle3;
  color: var(--red);
  text-align: center;
}

.submitButton {
  display: flex;
  @apply --primaryBlackbeanButtonStyles;
  margin: 30px auto;
  text-align: center;
}
