@import '../../styles/propertySets.css';

.deliveryAddressContainer {
  @apply --darkBorder;
  padding: 50px;

  @media (--viewportSmall) {
    padding: 30px 10px;
    border: none;
  }
}

.deliveryAddressTitle {
  @apply --titleStyle3;
  margin: 0;
}