@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formFields {
}

.formRow {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.formField {
  width: 360px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formFullRowField {
  width: 100%;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.formField label,
.formFullRowField label {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  text-align: left;

  @media (--viewportSmall) {
    margin-top: 20px;
  }
}

.requiredMarker {
  color: var(--red);
}

.errorMessage {
  margin-left: 10px;
  color: var(--red);
  font-weight: var(--fontWeightRegular);
}

.placeholderContainer {
  position: relative;
}

.httpPlaceHolder {
  @apply --bodyStyle3;
  color: var(--darkGrey);
  position: absolute;
  left: 15px;
  top: 11px;
  width: 50px;
}

.fieldPhoneInput {
  position: relative;
  display: flex;
}

.phoneCode select,
.vatCode select {
  width: 130px;
}

.phoneCodeInput input {
  padding-left: 41px;
  padding-top: 6px;
}

.fieldWithCountry {
  flex: 1;
  margin-left: 10px;
  position: relative;
}

.emptyPhoneCode input {
  padding-left: 15px;
}

.inputCodePlaceholder {
  position: absolute;
  left: 15px;
  top: 11px;
  @apply --bodyStyle3;
}

.country select,
.annualSales select,
.businessType select,
.salesOutlets select {
  width: 360px;
  display: flex;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.propertiesContainer {
  display: block;
  text-align: left;
}

.propertyItem {
  width: 33%;
  display: inline-block;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.propertyItem span {
  display: inline-block;
}

.propertyItem label {
  display: inline-block;
  @apply --bodyStyle4;
}

.propertyLabel {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
}

.websiteInput input {
  padding-left: 67px /*!important*/;
  padding-top: 6px;
}

.hearAboutVegshelf select {
  width: 100%;
  display: flex;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.agreeRow {
  margin-top: 15px;

  @media (--viewportSmall) {
    margin-top: 30px;
    text-align: left;
  }
}

.agreeRow label {
  display: inline;
  @apply --bodyStyle3;
}

.agreeRowLinks {
  @apply --bodyStyle3;
  text-decoration: underline;
}

.bottomWrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}

.submitButton {
  @apply --primaryBlackbeanButtonStyles;
}

select>option:disabled {
  color: var(--black);
}