@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  background-color: var(--white);
}

.aboutUsPageDesign {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  background-color: var(--beige);
}

.applyToSellPage {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  background-color: var(--beige);
  /*background-color: var(--white);*/
}

.blogPageDesign {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  background-color: var(--beige);

}

.logo {
  height: 40px;
  margin-top: 22px;
  width: auto;
  max-width: 160px;
  object-fit: contain;
  object-position: left center;
}

.searchContainer {
  flex-grow: 1;
  max-width: 60vw;
  margin: 0 auto;
}

.searchLink {
  margin: 22px 60px;
  box-sizing: border-box;
  height: 40px;
  background-color: var(--white);
  @apply --darkBorder;
}

.topbarSearchWithLeftPadding {
}

.selectedLink {
  pointer-events: none;
  user-select: none;
}

.onlyLogoContainer {
  text-align: center;
  width: 100%;
}

/* Language section */

.languageButtonContainer {
  margin-top: 22px;
  margin-right: 0;
  margin-left: 0;
  padding: 10px 20px;
}

.languageButtonContainer:hover {
  cursor: pointer;
}

.languageButtonContainer button {
  @apply --titleStyle8;
  color: var(--darkGrey);
  border: none;
}

.languageButtonContainer button:hover {
  cursor: pointer;
  color: var(--grey);
}

/* Authentication - Visitors */

.categoriesContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0 50px;
  border-bottom: 1px solid var(--black);
  height: 50px;
}

.categoryPlaceholder {
  width: 2vw;
}

.shortLinks {
  margin: 0 auto;
  text-align: center;
}

.shortLinks li {
  display: inline-block;
  margin-top: 8px;
}

.shortLink {
  @apply --titleStyle7;
  font-size: 14px;
  margin: 0 30px;
}

.trustpilotContainer {
  margin: 0;
  padding: 0;
  width: 130px;
  margin-bottom: 10px;
}

.trustpilotContainerDE {
  margin: 0;
  padding: 0;
  width: 230px;
  margin-bottom: 10px;
}

.loginLink {
  @apply --secondaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 0;
  padding: 10px 20px;
}

.signupLink {
  @apply --secondaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 0;
  padding: 10px 20px;
}

.registerToShopLink {
  @apply --primaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-left: 0px;
  padding: 10px 20px;
}

.loginLinkDE {
  @apply --secondaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 0;
  padding: 10px 13px;
}

.signupLinkDE {
  @apply --secondaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 0;
  padding: 10px 13px;
}

.registerToShopLinkDE {
  @apply --primaryBlackbeanButtonStyles;
  margin-top: 22px;
  margin-left: 0;
  padding: 10px 13px;
}

/* Buyer topbar menu */

.buyerTopMenu {
  display: flex;
  align-items: center;
  margin: 22px 0 0;
}

.ordersMainMenuLink {
  @apply --ordersButton;
  display: inline-block;
  margin-right: 15px;

  @media (--viewportSmall) {
    margin-right: 10px;
  }
}

.messagesMainMenuLink {
  @apply --messagesButton;
  display: inline-block;
  margin-right: 15px;

  @media (--viewportSmall) {
    margin-right: 10px;
  }
}

.favouritesMainMenuLink {
  @apply --favouritesButton;
  display: inline-block;
  margin-right: 15px;

  @media (--viewportSmall) {
    margin-right: 10px;
  }
}

.avatarMenu {
  display: flex;
  justify-content: center;
}

.shoppingCartMainMenuLink {
  @apply --shoppingCartButton;
  display: inline-block;
}

.avatar {
  display: flex;
  margin-right: 15px;

  @media (--viewportSmall) {
    margin-right: 10px;
  }
}

.profileMenuLabel {
  align-items: center;
  border: none;
  padding: 0;
  margin-top: 1px
}

.profileMenuContentBuyer {
  width: 200px;
  margin: 15px 8px 0 0;
  padding-top: 20px;
  background-color: var(--white);
  border-radius: 3px;

  @media (--viewportSmall) {;
    width: 90vw;
  }
}

.profileMenuContentSupplier {
  width: 200px;
  margin: 55px 8px 0 0;
  padding-top: 20px;
  background-color: var(--white);
  border-radius: 3px;

  @media (--viewportSmall) {;
    width: 90vw;
  }
}

.profileSettingsLink,
.yourListingsLink {
  @apply --bodyStyle3;
  position: relative;
  display: block;
  margin: 0;
  padding: 8px 25px;
  text-align: left;
  /*transition: var(--transitionStyleButton);*/
}

.profileSettingsLink:hover {
  color: var(--darkGrey);
  text-decoration: none;
}

.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
}

.profileSettingsLink:hover 
.menuItemBorder {
  width: 5px;
  background-color: var(--lime);
}

.yourListingsLink:hover 
.menuItemBorder {
  width: 5px;
  background-color: var(--lime);
}

.logoutButton {
  @apply --bodyStyle3;
  position: relative;
  width: 100%;
  margin: 10px auto;
  padding: 15px 25px;
  border: none;
  color: var(--black);
  text-align: left;
  white-space: nowrap;
  /*transition: var(--transitionStyleButton);*/
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.logoutButton:hover {
  color: var(--darkGrey);
  text-decoration: none;
  cursor: pointer;
}

.logoutButton:hover 
.menuItemBorder {
  width: 5px;
  background-color: var(--lime);
}

.currentPage:hover
.menuItemBorder {
  width: 5px;
  background-color: var(--lime);
}

.selectedTransitionItem a {
  @apply --coloredSelectedLink;
  background-position: center bottom 5px;
  background-size: 100% 5px;
}

.transitionItem {
}

.shoppingCartIcon {
}

.shoppingCartNotification {
  font-size: 10px;
  color: var(--black);
  text-align: center;
  background-color: var(--green);
  border: 1px solid var(--black);
  border-radius: 25px;
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px;
}

/* Admin console */

.categorySections {
  @apply --callToActionButtonStyles;
  margin-top: 24px;
  margin-right: 20px;
}

/* Pop-up when adding products to the shopping cart */

.addedProductNotification {
  margin-top: 15px;
  width: 350px;
  @apply --darkBorder;
}

.checkIcon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.addedProduct > h1 {
  @apply --titleStyle7;
  font-size: 14px;
  text-align: center;
  margin: 20px;
}

.shoppingCartButtons {
  display: flex;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.goToShoppingCart {
  @apply --primaryBlackbeanButtonStyles;
  margin: 10px auto;
}

.addedProductDetails {
  display: flex;
  margin: 15px;
}

.productDetails {
  width: 145px;
}

.shoppingButton {
}

.addedProductPhoto {
  background-size: cover;
  background-position: center center;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  @apply --darkBorder;
}

.productTitle {
  @apply --titleStyle7;
  font-size: 13px;
  margin: 0;
}

.productSubTitle {
  @apply --bodyStyle5;
  margin: 5px 0 0 0;
}

.bkPopupHandler {
  width: 100%;
  height: 100%;
  z-index: 20;
  left: 0;
  top: 0;
  position: fixed;
}