@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  border-top: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    width: 100vw;
    border-top: none;
  }
}

.formContainer { 
  background: url('../../assets/vegan-seafood-bread-cheese.png');
  max-width: 1280px;
  width: 100%;
  text-align: center;
  
  @media (--viewportSmall) {
    width: 100vw;
    background: none;
  }
}

.formPanel {
  border-radius: 5px;
  margin: 40px auto 70px;
  padding: 40px 50px;
  background-color: var(--white);
  border: 1px solid var(--black);
  box-shadow: var(--boxShadowFilterButton);
  max-width: 850px;
  width: 100%;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 0 20px;
    box-shadow: none;
    background-color: var(--beigeMinus70);
    border: none;
  }
}

.formTitle {
  @apply --titleStyle2;
  margin-top: 0;
  text-align: center;
}

.formDescription {
  @apply --bodyStyle2;
  margin: 0 auto;
  text-align: center;
  width: 600px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.goToRegistrationSupplier {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.goToRegistrationSupplier a {
  @apply --bodyStyle4;
  color: var(--darkGrey);
  text-decoration: underline;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

/* Thank you page */

.thanksScreenBlock {

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.thanksScreenBlock h1 {
  @apply --titleStyle2;
  text-align: center;
  margin: 20px auto;

  @media (--viewportSmall) {
    margin: 10px auto;
  }
}

.thanksScreenBlock p {
  @apply --bodyStyle2;
  text-align: center;
  width: 630px;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 70vw;
  }
}

.thanksButtons {
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

.goToHomeButton {
  @apply --primaryBlackbeanButtonStyles;
}

.mediaSection {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (--viewportSmall) {
    margin: 50px auto 0;
    width: 80vw;
  }
}

.firstColumnMediaData {
  width: 620px;
  background-color: var(--white);
  text-align: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
    height: auto;
    padding-top: 0;
    background-color: var(--beigeMinus70);
  }
}

.mediaSectionTitle {
  @apply --titleStyle3;
  text-align: center;
  width: 650px;
  margin: 30px auto 0;

  @media (--viewportSmall) {
    margin: 10px auto 0;
    width: 70vw;
  }
}

.mediaButtons {
  margin-top: 20px;
  text-align: center;

  @media (--viewportSmall) {
    margin-top: 10px;
  }
}

.secondColumnMediaData {
  width: 650px;
  text-align: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.mediaInstagram, .mediaLinkedIn,
.mediaTwitter, .mediaFacebook, 
.mediaTiktok {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  background-color: var(--beigePlus90);
  background-size: 24px 24px;
  margin-right: 20px;
  margin-left: 20px;
  background-position: center center;
  border-radius: 40px;
  display: inline-block;

  @media (--viewportSmall) {
    height: 40px;
    width: 40px;
    background-size: 20px 20px;
    margin-right: 7px;
    margin-left: 7px;
  }
}

.mediaInstagram {
  background-image: url('../../assets/instagram-ffffff.svg');
}

.mediaLinkedIn {
  background-image: url('../../assets/linkedin-ffffff.svg');
}

.mediaTwitter {
  background-image: url('../../assets/twitter-ffffff.svg');
}

.mediaFacebook {
  background-image: url('../../assets/facebook-ffffff.svg');
}

.mediaTiktok {
  background-image: url('../../assets/tiktok-ffffff.svg');
}