@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.rootContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 50px 80px;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.sectionTitle {
  @apply --titleStyle1;
  text-align: center;
  margin: 20px auto 15px;
  max-width: 1000px;
}

.sectionSubTitle {
  @apply --bodyStyle1;
  text-align: center;
  margin: 0 auto 30px;
  max-width: 1100px;
}

.brandsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: space-evenly;
  }
}

.brandItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportSmall) {
    width: calc(100vw/2.4);
  }
}

.brandImage {
  max-width: 280px;
  border: 1px solid var(--black);
  border-radius: 150px;
  margin: 15px;

  @media (--viewportSmall) {
    width: calc(100vw/2.5);
    flex-basis: 100%;
  }
}

.brandTitle {
  @apply --titleStyle7;
  margin: 5px auto 0;
  text-align: center;

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.fromStyles {
  @apply --titleStyle8;
  margin-top: -20px;

  @media (--viewportSmall) {
    margin-bottom: 10px;
  }
}

.brandNameLink {
  color: var(--black);
}