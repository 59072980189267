@import '../../styles/propertySets.css';

.content {
  @apply --darkBorder;
  padding: 50px;
  margin-bottom: 30px;

  @media (--viewportSmall) {
    padding: 30px 10px 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--black);
  }
}

.title {
  @apply --titleStyle3;
  margin: 0;

  @media (--viewportSmall) {
    width: 85vw;
  }
}