@import '../../styles/propertySets.css';

.aboutVegshelfContainer {
  display: flex;
  max-width: 1280px;
  margin: 150px auto 0;
  justify-content: center;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 100vw;
    margin: 0;
  }
}

.tabContainer {
  margin: 50px 0 50px 50px;
  width: 200px;
  flex-direction: column;

  @media (--viewportSmall) {
    margin: 15px 0 0 0;
    width: 100%;
    text-align: center;
  }
}

.informationContainer {
  margin: 50px 50px 50px 0;
  width: 980px;

  @media (--viewportSmall) {
    margin: 0;
    width: 100%;
  }
}

.tabs {
  @apply --titleStyle5;
}

.tabs .NamedLink_active {
  font-weight: var(--fontWeightMedium);
  background-image: none;
}

.tabs .tab {
  margin-bottom: 25px;

  @media (--viewportSmall) {
    margin: 15px auto;
  }
}