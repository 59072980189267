@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1F170E;
}

.filtersWrapper {
}

.footer {
  display: flex;
  margin-top: 8px;
}

.resetAllButton {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: #b2b2b2;

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: #000000;
  }
}

.cancelButton {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);
  color: #b2b2b2;

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: #000000;
  }
}

.applyButton {
  @apply --bodyStyle3;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: #eee581;
  }
}
