.backgroundLight {
  stop-color: #b2b2b2;
}

.backgroundDark {
  stop-color: #000000;
}

.foregroundFill {
  fill: #ffffff;
}

.foregroundStroke {
  stroke: #ffffff;
}
