@import '../../styles/propertySets.css';

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  display: flex;
  height: 750px;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--black);
  
  @media (--viewportSmall) {
    width: 100vw;
    height: auto;
    border-top: none;
  }
}

.root {
  background: url('../../assets/vegan-cheese-sausage-baked-goods.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 100vw;
    background: none;
    height: auto;
  }
}

.content {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 620px;
  height: 450px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFormBackground);
  border: 1px solid var(--black);
  margin: 30px auto 40px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 40px auto 80px;
    box-shadow: none;
    border: none;
    background-color: var(--beigeMinus70);
    padding: 0;
  }
}

.loadingText {
  @apply --bodyStyle3;
  padding: 0 30px;
}