@import '../../styles/propertySets.css';

.vegshelfInformationContainer {
  
  @media (--viewportSmall) {
    width: 100vw;
    flex-wrap: wrap;
  }
}

.vegshelfInformationBox1 {
  width: 980px;
  padding: 50px;
  @apply --darkBorder;
  margin-bottom: 90px;

  @media (--viewportSmall) {
    width: 90vw;
    padding: 20px;
    margin: 10px auto 20px;
  }
}

.cookiePolicyButtonContainer {
  height: auto;
  position: relative;
  margin: 0 0 50px;

  @media (--viewportSmall) {
    height: 1px;
    margin: 50px 0 50px;
  }
}

.cookiePolicyButton {
  @apply --secondaryBlackbeanButtonStyles;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (--viewportSmall) {
    width: 70vw;
  }
}