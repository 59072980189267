@import '../../styles/propertySets.css';

.pageRoot {
  overflow-x: hidden;

  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.layoutWrapperMain {
  background-color: var(--beigeMinus70);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
  @media (--viewportSmall) {
    width: 100vw;
  }
}

.root {
  background: url('../../assets/vegan-cheese-sausage-baked-goods.png');
  max-width: 1280px;
  width: 100%;
  height: 660px;
  margin: 150px auto 0;

  @media (--viewportSmall) {
    width: 100vw;
    background: none;
    margin-top: 0;
    height: 500px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;
  width: 620px;
  height: 500px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: var(--boxShadowFormBackground);
  border: 1px solid var(--black);
  margin: 30px auto 40px;

  @media (--viewportSmall) {
    width: 90vw;
    margin: 40px auto 20px;
    box-shadow: none;
    border: none;
    background-color: var(--beigeMinus70);
    padding: 0;
  }
}

.invalidUrlText {
  @apply --bodyStyle3;
  padding: 0 50px;

  @media (--viewportSmall) {
    padding: 0 10px;
    width: 90vw;
  }
}

.invalidUrlText a {
  text-decoration: underline;
}



.form {
  margin: 60px auto;
  width: 400px;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.error {
  @apply --bodyStyle3;
  color: var(--red);
  text-align: center;
}

.modalTitle {
  @apply --titleStyle2;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.modalMessage {
  @apply --bodyStyle2;
  text-align: center;
  margin: 0 auto;

  @media (--viewportSmall) {
    width: 80vw;
  }
}

.loginButton {
  @apply --primaryBlackbeanButtonStyles;
  text-align: center;
  margin: 30px auto;
}