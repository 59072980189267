@import '../../styles/propertySets.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Topbar message section */

.topbarMessageContainer {
  width: 100%;
  text-align: center;
  background-color: var(--beige);
  padding: 15px;

  @media (--viewportSmall) {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.topbarMessage {
  @apply --titleStyle7;
  font-size: 12px;
  word-spacing: 2px;
  color: var(--beige);
  line-height: 0;

  @media (--viewportSmall) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: var(--beige);
    width: 90vw;
    text-align: center;
    line-height: 1.25;
    font-size: 10px;
  }
}

/* Blog hero section */

.blogHeroContainer {
  background: var(--beige);
  background: linear-gradient(180deg, var(--beige) 0%, var(--white) 40%);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;

  @media (--viewportSmall) {
    padding: 20px 15px;
    width: 100vw;
  }
}

.blogHeroSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 95%;
  }
}

.latestBlogTextandImageSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    margin-bottom: 15px;
  }
}

.latestBlogText {
  display: flex;
  flex-direction: column;
}

.latestBlogTitle {
  @apply --titleStyle1;
  margin: 0 10px 20px 0;
  max-width: 650px;

  @media (--viewportSmall) {
    max-width: 95%;
    margin: 20px 10px 15px 0;
  }
}

.latestBlogSubtitle {
  @apply --bodyStyle2;
  margin: 0 10px 10px 0;
  max-width: 600px;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

.blogDateAuthorSection {
  display: flex;
}

.blogDate {
  @apply --titleStyle7;
  font-size: 13px;
}

.blogAuthor {
  @apply --titleStyle7;
  font-size: 13px;
}

.readLatestArticleButtonSection {
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin: 20px 0 0;
  }
}

.readLatestArticleButton {
  @apply --primaryBlackbeanButtonStyles;
}

.latestThumbnailImageContainer {

  @media (--viewportSmall) {
    max-width: 95%;
    margin: 0;
    order: -1;
  }
}

.latestBlogImage {
  @apply --darkBorder;
  max-width: 550px;
  max-height: 550px;
  margin: 0;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

/* Blog grid view */

.blogGridViewContainer {
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  margin-bottom: 100px;
  overflow: hidden;

  @media (--viewportSmall) {
    padding: 30px 15px;
    margin-bottom: 0;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: center;
  }
}

.blogGridView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    justify-content: center;
    align-items: center;
  }

}

.singleBlogThumbnail {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 0;

  @media (--viewportSmall) {
    margin: 0;
    max-width: 95%;
  }
}

.singleBlogThumbnailImage {
  max-height: 350px;
  max-width: 350px;
  @apply --darkBorder;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

.singleBlogTitle {
  @apply --titleStyle3;
  margin: 20px 0 10px;

  @media (--viewportSmall) {
    max-width: 95%;
  }
}

.readSingleArticleButtonSection {
  display: flex;
  margin: 15px 0 20px;

  @media (--viewportSmall) {
    margin: 10px 0 60px;
  }
}

.readSingleArticleButton {
  @apply --primaryBlackbeanButtonStyles;
}