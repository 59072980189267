@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.productContainer {
  background: var(--beigeMinus70);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px 70px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid var(--black);
  
  @media (--viewportSmall) {
    width: 100vw;
    display: inline-block;
    padding: 40px 15px;
  }
}

.sectionTitle {
  @apply --titleStyle1;
  text-align: center;
  margin: 20px auto 15px;
  max-width: 1200px;

  @media (--viewportSmall) {
    margin: 10px auto;
  }
}

.commentContainer {
  display: inline-block;
}

.brandNodes {
  display: flex;
  justify-content: center;
}

.brandItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw/6);
  margin: 20px 20px 0;

  @media (--viewportSmall) {
    width: calc(100vw/2);
    margin: 20px 10px 0;
  }
}

.brandImage {
  max-width: calc(100vw/8.5);
  border: 1px solid var(--black);
  border-radius: 20px;

  @media (--viewportSmall) {
    max-width: calc(100vw/2.7);
  }
}

.brandTitle {
  @apply --titleStyle7;
  margin: 10px auto 0;
  text-align: center;

  @media (--viewportSmall) {
    margin-bottom: 15px;
  }
}

/*
.brandNameLink {
  color: var(--black);
}*/

.buttonContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  justify-content: center;
}

.leftButton {
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 30px;
}

.leftButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.rightButton { 
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border-radius: 25px;
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
}

.rightButton img {
  height: 18px;
  width: 18px;
  cursor: pointer;
}