@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  
  @media (--viewportSmall) {
    width: 100vw;
    max-width: 767px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* USP section */

.sections {
  margin: 0;
}

/* Discover New section */

.discoverSectionContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 50px 50px;
  margin: 10px 0 30px;

  @media (--viewportSmall) {
    padding: 30px 15px;
    height: auto; 
    flex-wrap: wrap;
    width: 100vw;
    margin: 10px 0 0;
  }
}

.discoverSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    width: 90vw;
  }
}

.discoverTextColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 600px;

  @media (--viewportSmall) {
    margin: 40px 0;
  }
}

.discoverTitleMain {
  @apply --titleStyle1;
  color: var(--beigePlus90);
  margin: 0;
}

.discoverSubtitle {
  @apply --bodyStyle1;
  margin: 30px 20px 0 0;
  max-width: 485px;

  @media (--viewportSmall) {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.discoverButton {
  margin-top: 50px;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.discoverButtonLink {
  @apply --primaryBlackbeanButtonStyles;
}

.discoverImageColumn {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportSmall) {
    flex-basis: 100%;
    order: -1;
  }
}

.discoverImageRow1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.discoverSearchInput {
  border: 1px solid var(--black);
  box-sizing: border-box;
  height: 40px;
  width: 260px;
  background-color: var(--white);
  border-radius: 5px;
  font-family: var(--fontHind);
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
  line-height: 1.25;
  letter-spacing: 0;
  color: var(--beigePlus90);
  padding: 12px 15px;
  margin: 90px 10px 10px auto;

  @media (--viewportSmall) {
    margin: 35px 5px auto auto;
    height: 30px;
    font-size: 14px;
    padding: 7px 10px;
    min-width: 200px;
    width: calc(100vw/2);
  }
}

.discoverSearchIcon {
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 0;

  @media (--viewportSmall) {
    margin-left: 10px;
    margin-top: -1px;
  }
}

.discoverSingleImageContainer {
  margin: 10px;
  height: 122px;
  width: 122px;
  
  @media (--viewportSmall) {
    margin: 4px;
    max-height: 90px;
    max-width: 90px;
    width: calc(100vw/5);
    height: calc(100vw/5);
  }
}

.discoverSingleImageContainer img {
  max-height: 122px;
  max-width: 122px;
  border: 1px solid var(--black);
  border-radius: 5px;
  height: auto;
  width: auto;

  @media (--viewportSmall) {
    max-height: 90px;
    max-width: 90px;
    width: calc(100vw/5);
    height: calc(100vw/5);
  }
}

.discoverImageRow2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.discoverSingleImageContainer:nth-of-type(3) {
  
  @media (--viewportSmall) {
    display: none;
  }
}

.discoverImageRow3 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.discoverSingleImageContainerEmpty {
  box-shadow: none;
  margin: 10px;
  height: 120px;
  width: 120px;

  @media (--viewportSmall) {
    margin: 4px;
    max-height: 90px;
    max-width: 90px;
    width: calc(100vw/5);
    height: calc(100vw/5);
  }
}

/* FAQ Section */

.FAQSection {
  background: var(--white);
  background: linear-gradient(180deg, var(--white) 0%, var(--beigeMinus70) 35%, var(--beige) 100%);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 60px 50px;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 40px 15px;
    width: 100vw;
  }
}

.sectionFAQTitle {
  @apply --titleStyle1;
  margin: 0 auto 20px;
  text-align: center;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  justify-content: center;

  @media (--viewportSmall) {
    width: 90vw;
  }
}

.questionItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--black);

  @media (--viewportSmall) {
    width: 90vw;
    justify-content: flex-start;
  }
}

.questionItem:last-child {
  border-bottom: none;
}

.question {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  @apply --titleStyle4;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  position: relative;
  width: 100%;
  margin: 0;

  @media (--viewportSmall) {
    justify-content: flex-start;
    padding-right: 40px;
  }
}

.question::after {
  position: absolute;
  width: 19px;
  height: 12px;
  top: 20px;
  right: 16px;
  content: '.';
  font-size: 0px;
  background-image: url('../../assets/chevron-down-000000.svg');
  background-size: 20px 20px;
  background-position: center center;
}

.answer {
  padding-top: 0;
  padding-bottom: 20px;
  display: none;
  max-width: 850px;
  @apply --bodyStyle3;
  margin: 0;

  @media (--viewportSmall) {
    padding-right: 40px;
  }
}

.answer a {
  @apply --bodyStyle3;
  color: var(--bluePlus35);
}

.answer a:hover {
  text-decoration: underline;
  color: var(--bluePlus35);
}

.selectedQuestion .answer {
  display: block;
}

.selectedQuestion .question::after {
  display: block;
  transform: rotate(180deg);
}

/* Grow Your Business Section*/

.sectionGrowBusiness {
  background-color: var(--beige);
}

/* Section - Vegshelf Buyer Blog */

.resourceContainer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding: 60px 50px;

  @media (--viewportSmall) {
    margin: 20px 0 10px;
    padding: 30px 15px;
    flex-wrap: wrap;
    width: 100vw;
  }
}

.resourcesSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;

  @media (--viewportSmall) {
    flex-wrap: wrap;
    overflow: hidden;
    width: 90vw;
  }
}

.resourceInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (--viewportSmall) {
    align-items: center;
  }
}

.resourceInfoBlogTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  text-align: left;
  margin: 10px 0 20px;

  @media (--viewportSmall) {
    margin: 0 0 20px;
    text-align: center;
  }
}

.firstTextPartContainer {
  display: flex;
}

.resourceInfoBlogSubTitle {
  @apply --titleStyle1;
  margin: 0;
  max-width: 520px;

  @media (--viewportSmall) {
    text-align: center;
  }
}

.resourceInfoBlogText {
  @apply --bodyStyle1;
  margin-top: 20px;
  max-width: 500px;

  @media (--viewportSmall) {
    text-align: center;
  }
}

.resourceButtons {
  margin-top: 50px;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

.resourcesReadMoreButton {
  @apply --primaryBlackbeanButtonStyles;
}

.resourceItems {
  width: calc(100% - 650px);
  max-width: 650px;
  overflow: hidden;
  position: relative;
  margin-left: 100px;

  @media (--viewportSmall) {
    overflow: visible;
    width: 90vw;
    margin: 0;
  }
}

.swiperButtonContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  justify-content: center;
}

.resourceLeftButton {
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;
}

.resourceLeftButton img {
  height: 18px;
  width: 18px;
}

.resourceItem {
  display: inline-block;
  width: 300px;
  user-select: none;

  @media (--viewportSmall) {
    width: 95vw;
    max-width: 400px;
  }
}

.resourceImage {
  width: 94%;
  margin: 4px;
  border: 1px solid var(--black);
  border-radius: 5px;

  @media (--viewportSmall) {
    width: 92%;
  }
}

.resourceRightButton {
  height: 40px;
  width: 40px;
  background-color: var(--beigePlus40);
  border: 1px solid var(--black);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 15px;

  @media (--viewportSmall) {
    right: 21px;
  }
}

.resourceRightButton img {
  height: 18px;
  width: 18px;
}

/* Social Media, Newsletter section */

.mediaSectionContainer {
  background-color: var(--beigeMinus70);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 60px;
  overflow: hidden;
  border-top: 1px solid var(--black);

  @media (--viewportSmall) {
    padding: 30px 15px;
    width: 100vw;
  }
}

.mediaSection {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 1280px;
  
  @media (--viewportSmall) {
    flex-wrap: wrap;
  }
}

.newsletterSectionTitle {
  @apply --titleStyle7;
  color: var(--beigePlus90);
  text-align: center;
  margin: 0 auto 20px;

  @media (--viewportSmall) {
    margin-top: 10px;
  }
}

.buyerNewsletterMainTitle {
  @apply --titleStyle1;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto 20px;
}

/*
.firstColumnMediaData {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (--viewportSmall) {
    width: 100%;
  }
}*/

/*.mediaDescription {
  @apply --titleStyle2;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 20px;

  @media (--viewportSmall) {
    max-width: 80vw;
  }
}

.mediaButtons {
  align-self: center;
 
  @media (--viewportSmall) {
    margin-bottom: 60px;
  }
}

.mediaInstagram, .mediaLinkedIn,
.mediaTwitter, .mediaFacebook, 
.mediaTiktok {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  background-color: var(--beigePlus90);
  background-size: 24px 24px;
  margin-right: 20px;
  margin-left: 20px;
  background-position: center center;
  border-radius: 40px;
  display: inline-block;

  @media (--viewportSmall) {
    margin-right: 7px;
    margin-left: 7px;
  }
}

.mediaInstagram {
  background-image: url('../../assets/instagram-ffffff.svg');
}

.mediaLinkedIn {
  background-image: url('../../assets/linkedin-ffffff.svg');
}

.mediaTwitter {
  background-image: url('../../assets/twitter-ffffff.svg');
}

.mediaFacebook {
  background-image: url('../../assets/facebook-ffffff.svg');
}

.mediaTiktok {
  background-image: url('../../assets/tiktok-ffffff.svg');
}

.secondColumnMediaData {
  width: 50%;
  text-align: center;

  @media (--viewportSmall) {
    width: 100%;
    margin: 0 auto 30px;
  }
}
*/